import React from 'react';
import { Form, Input, Button, notification, Modal } from 'antd';
import './styles.login.scss';
import BgImage from '../../asset/images/bg-login.png';
import { useNavigate } from 'react-router-dom';
import { MainState } from '../../MainProvider';
import { apiAuth } from '../../api/auth';
import { apiUser } from '../../api/user';
import axios from 'axios';
import { API_URL } from '../../common/enum';


const LoginForm = () => {

    const [form] = Form.useForm();
    const [formResetPassword] = Form.useForm();
    const navigate = useNavigate();
    const { isLogin, setIsLogin, isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser } = MainState();

    const onFinish = async (values: any) => {
        console.log('Received values:', values);

        const req = {
            username: values.username,
            password: values.password
        }
        console.log(req);

        await apiUser.login(req)
            .then((res) => {
                console.log(res.data.data);

                const user = res.data.data.user;

                localStorage.setItem('username', user.username);
                localStorage.setItem('userId', user.id);
                localStorage.setItem('token', res.data.data.accessToken);
                notification.success({
                    message: 'Đăng nhập thành công',
                });

                if (user.role === 'Trưởng phòng') {
                    localStorage.setItem('role', 'admin');
                    navigate('/expert-management');
                }
                else if (user.role === 'superadmin') {
                    localStorage.setItem('role', 'superadmin');
                    navigate('/user-management');
                }
                else {
                    localStorage.setItem('role', 'user');
                    navigate('/expert-management');
                };

            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'User not found') {
                    notification.error({
                        message: 'Tên đăng nhập không tồn tại',
                        description: 'Vui lòng kiểm tra lại tên đăng nhập ',
                    });
                }

                if (err?.response?.data?.message === 'Invalid password') {
                    notification.error({
                        message: 'Mật khẩu không chính xác',
                        description: 'Vui lòng kiểm tra lại mật khẩu',
                    });
                }
            });

        // setIsLogin(true);
    };

    const handleClickResetPassword = () => {
        // navigate('/reset-password');

        formResetPassword.resetFields();

        Modal.confirm({
            title: 'Đặt lại mật khẩu',
            content: (
                <div>
                    <p>Nhập tên đăng nhập và mật khẩu muốn sửa để đặt lại mật khẩu.</p>
                    <Form
                        name="resetPasswordForm"
                        onFinish={onFinishResetPassword}
                        initialValues={{
                            remember: true,
                        }}
                        form={formResetPassword}
                        layout='vertical'
                        className='form-login'
                    >
                        <Form.Item
                            label="Tên đăng nhập"
                            name="username"
                            className='form-item'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tên đăng nhập!"
                                },
                            ]}
                        >
                            <Input 
                                placeholder='Nhập tên đăng nhập'
                            />
                        </Form.Item>

                        <Form.Item
                            label="Mật khẩu muốn sửa"
                            name="password"
                            className='form-item'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập mật khẩu muốn sửa!"
                                },
                            ]}
                        >
                            <Input.Password 
                                placeholder='Nhập mật khẩu muốn sửa'
                            />
                        </Form.Item>
                    </Form>
                </div>
            ),
            onOk() {
                formResetPassword.submit();
            },
            onCancel() {
                console.log('Cancel');
            },
            okText: 'Đồng ý',
            cancelText: 'Hủy',
        });

    }

    const onFinishResetPassword = async (values: any) => {
        console.log('Received values:', values);

        const req = {
            username: values.username,
            password: values.password
        }
        console.log(req);

        await apiUser.resetPassword(req)
            .then((res) => {
                console.log(res.data.data);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'User not found') {
                    notification.error({
                        message: 'Tên đăng nhập không tồn tại',
                        description: 'Vui lòng kiểm tra lại tên đăng nhập ',
                    });
                }
            });

    }

    return (
        <div className='main-login'>
            <div className="main-login-container">
                <div className="main-login-container-left">
                    <h1>Đăng nhập</h1>
                    <Form
                        name="loginForm"
                        onFinish={onFinish}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        layout='vertical'
                        className='form-login'
                    >
                        <Form.Item
                            label="Tên đăng nhập"
                            name="username"
                            className='form-item'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tên đăng nhập!"
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            className='form-item'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập mật khẩu!"
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Đăng nhập
                            </Button>
                        </Form.Item>

                        {/* <Form.Item>
                            <Button type="link" onClick={handleClickResetPassword}>
                                Đặt lại mật khẩu
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
            </div>
        </div>

    );
};

export default LoginForm;