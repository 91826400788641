import { DeleteOutlined, EditOutlined, FormOutlined, DownloadOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Modal, notification, Row, Select, Table, TableColumnsType, Tooltip, Upload, UploadProps } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../api/request';
import { MainState } from '../../MainProvider';
import { apiCoopration } from '../../api/cooperation';
import { apiProject } from '../../api/project';
import { apiDocument } from '../../api/document';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Dragger from 'antd/es/upload/Dragger';
import { API_URL } from '../../common/enum';
// import tmpData from './data';


const Approve: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [lstRequestTmp, setLstRequestTmp] = useState<any[]>([]); // Danh sách kiến nghị tạm
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>({}); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [changeInfo, setChangeInfo] = useState<any>([]); // Thông tin thay đổi
    const [isChangeInfo, setIsChangeInfo] = useState<any>(''); // Nội dung modal xem chi tiết kiến nghị
    const [numberNewRecord, setNumberNewRecord] = useState(0); // Số lượng bản ghi mới
    const [numberUpdateRecord, setNumberUpdateRecord] = useState(0); // Số lượng bản ghi cập nhật
    const columns: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Số công văn',
            dataIndex: 'documentNumber',
            width: 150,
            fixed: 'left',
        },
        {
            title: 'Số đến',
            dataIndex: 'documentTo',
            width: 150,
        },
        {
            title: 'Ngày công văn',
            dataIndex: 'documentDate',
            width: 150,
            render: (text: any, record: any) => (
                <div>{dayjs(record.documentDate).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Đơn vị',
            dataIndex: 'organization',
            width: 150,
        },
        {
            title: 'Đầu mối',
            dataIndex: 'contact',
            width: 150,
        },
        {
            title: 'Loại công văn',
            dataIndex: 'documentType',
            width: 150,
        },
        {
            title: 'Cán bộ xử lý',
            dataIndex: 'officer',
            width: 150,
        },
        {
            title: 'Nội dung công văn',
            dataIndex: 'documentContent',
            width: 1000,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 130,
            fixed: 'right',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: 130,
            fixed: 'right',
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>

                    {lstAllFile.some((file: any) => file.documentId === record.id) && (
                        <Tooltip title='Tải xuống'>
                            <div onClick={() => handleDownloadFile(record)}>
                                <DownloadOutlined />
                            </div>
                        </Tooltip>
                    )}
                </div>
            )
        }
    ]
    const columnsAdmin: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Thông tin thay đổi',
            dataIndex: 'changeInfo',
            key: 'changeInfo',
            width: 250,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        <div>{changeInfo[index] || 'Không có thông tin thay đổi'}</div>
                    }
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Xem và phê duyệt'>
                                <div onClick={() => handleViewRequest(record, index)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(0);
    const [searchValue, setSearchValue] = useState(
        {
            documentNumber: '',
            documentTo: '',
            documentDate: '',
            organization: '',
            contact: '',
            documentType: '',
            officer: '',
            documentContent: '',

        }
    ); // Giá trị tìm kiếm
    const [fileName, setFileName] = useState(''); // Tên file
    const [isOpenModalFile, setIsOpenModalFile] = useState(false); // Mở modal file
    const [formExcel] = Form.useForm(); // Form import file
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [currentFile, setCurrentFile] = useState<any>(null);
    const [lstAllFile, setLstAllFile] = useState<any>([])
    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        getAllRequest(searchValue)
        getAllRequestTmp(searchValue)
        getAllFile()
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log('searchValue', searchValue);
        getAllRequest(searchValue)
        getAllRequestTmp(searchValue)
    }, [searchValue]);

    useEffect(() => {
        console.log('lstRequestTmp', lstRequestTmp);
        console.log('lstRequest', lstRequest);

        let lstInfo: any[] = [];
        if (lstRequestTmp.length > 0 && lstRequest.length > 0 && lstRequest.length === lstRequestTmp.length) {
            lstRequestTmp.map((item: any, index: any) => {


                let lst = lstRequestTmp.map((item: any, index: any) => {
                    let lstTmp = lstRequest.filter((itemExpert: any) => itemExpert.codeDocument === item.codeDocument);
                    return lstTmp[0];
                })

                let info = '';
                if (item.documentNumber !== lst[index].documentNumber) {
                    info += `Số công văn, `;
                }
                if (item.documentTo !== lst[index].documentTo) {
                    info += `Số đến, `;
                }
                if (item.documentDate !== lst[index].documentDate) {
                    info += `Ngày công văn, `;
                }
                if (item.organization !== lst[index].organization) {
                    info += `Đơn vị, `;
                }
                if (item.contact !== lst[index].contact) {
                    info += `Đầu mối, `;
                }
                if (item.documentType !== lst[index].documentType) {
                    info += `Loại công văn, `;
                }
                if (item.officer !== lst[index].officer) {
                    info += `Cán bộ xử lý, `;
                }
                if (item.documentContent !== lst[index].documentContent) {
                    info += `Nội dung công văn, `;
                }

                lstInfo.push(info)
            })
        }
        console.log('lstInfo', lstInfo);

        setChangeInfo(lstInfo);
    }, [lstRequestTmp, lstRequest]);

    const getAllFile = async () => {
        await apiDocument.getAllFile(localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                setLstAllFile(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getAllRequest = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiDocument.getAll(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.documents;

                    if (searchValue.documentNumber) {
                        lst = lst.filter((item: any) => item.documentNumber.toLowerCase().includes(searchValue.documentNumber.toLowerCase()));
                    }

                    if (searchValue.documentTo) {
                        lst = lst.filter((item: any) => item.documentTo.toLowerCase().includes(searchValue.documentTo.toLowerCase()));
                    }

                    if (searchValue.documentDate) {
                        lst = lst.filter((item: any) => dayjs(item.documentDate).format('DD/MM/YYYY').includes(searchValue.documentDate));
                    }

                    if (searchValue.organization) {
                        lst = lst.filter((item: any) => item.organization.toLowerCase().includes(searchValue.organization.toLowerCase()));
                    }

                    if (searchValue.contact) {
                        lst = lst.filter((item: any) => item.contact.toLowerCase().includes(searchValue.contact.toLowerCase()));
                    }

                    if (searchValue.documentType && searchValue.documentType.length > 0) {
                        lst = lst.filter((item: any) => {
                            let check = false;
                            searchValue.documentType.map((type: any) => {
                                if (item.documentType.toLowerCase().includes(type.toLowerCase())) {
                                    check = true;
                                }
                            })
                            return check;
                        });
                    }

                    if (searchValue.officer) {
                        lst = lst.filter((item: any) => item.officer.toLowerCase().includes(searchValue.officer.toLowerCase()));
                    }

                    if (searchValue.documentContent) {
                        lst = lst.filter((item: any) => item.documentContent.toLowerCase().includes(searchValue.documentContent.toLowerCase()));
                    }

                    setLstRequest(lst);

                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            await apiDocument.getAllByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.documents;

                    if (searchValue.documentNumber) {
                        lst = lst.filter((item: any) => item.documentNumber.toLowerCase().includes(searchValue.documentNumber.toLowerCase()));
                    }

                    if (searchValue.documentTo) {
                        lst = lst.filter((item: any) => item.documentTo.toLowerCase().includes(searchValue.documentTo.toLowerCase()));
                    }

                    if (searchValue.documentDate) {
                        lst = lst.filter((item: any) => dayjs(item.documentDate).format('DD/MM/YYYY').includes(searchValue.documentDate));
                    }

                    if (searchValue.organization) {
                        lst = lst.filter((item: any) => item.organization.toLowerCase().includes(searchValue.organization.toLowerCase()));
                    }

                    if (searchValue.contact) {
                        lst = lst.filter((item: any) => item.contact.toLowerCase().includes(searchValue.contact.toLowerCase()));
                    }

                    if (searchValue.documentType && searchValue.documentType.length > 0) {
                        lst = lst.filter((item: any) => {
                            let check = false;
                            searchValue.documentType.map((type: any) => {
                                if (item.documentType.toLowerCase().includes(type.toLowerCase())) {
                                    check = true;
                                }
                            })
                            return check;
                        });
                    }

                    if (searchValue.officer) {
                        lst = lst.filter((item: any) => item.officer.toLowerCase().includes(searchValue.officer.toLowerCase()));
                    }

                    if (searchValue.documentContent) {
                        lst = lst.filter((item: any) => item.documentContent.toLowerCase().includes(searchValue.documentContent.toLowerCase()));
                    }

                    setLstRequest(lst);

                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const getAllRequestTmp = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiDocument.getAllTmp(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data.data.documents);

                    let lst = res.data.data.documents;
                    // Lọc ra status chờ phê duyệt hiển thị đầu tiên và đã tạo sau
                    lst = lst.sort((a: any, b: any) => {
                        if (a.status === 'Chờ phê duyệt' && b.status === 'Đã tạo') return -1;
                        if (a.status === 'Đã tạo' && b.status === 'Chờ phê duyệt') return 1;
                        return 0;
                    })

                    setNumberNewRecord(lst.filter((item: any) => item.status === 'Đã tạo').length);
                    setNumberUpdateRecord(lst.filter((item: any) => item.status === 'Chờ phê duyệt').length);

                    if (searchValue.documentNumber) {
                        lst = lst.filter((item: any) => item.documentNumber.toLowerCase().includes(searchValue.documentNumber.toLowerCase()));
                    }

                    if (searchValue.documentTo) {
                        lst = lst.filter((item: any) => item.documentTo.toLowerCase().includes(searchValue.documentTo.toLowerCase()));
                    }

                    if (searchValue.documentDate) {
                        lst = lst.filter((item: any) => dayjs(item.documentDate).format('DD/MM/YYYY').includes(searchValue.documentDate));
                    }

                    if (searchValue.organization) {
                        lst = lst.filter((item: any) => item.organization.toLowerCase().includes(searchValue.organization.toLowerCase()));
                    }

                    if (searchValue.contact) {
                        lst = lst.filter((item: any) => item.contact.toLowerCase().includes(searchValue.contact.toLowerCase()));
                    }

                    if (searchValue.documentType && searchValue.documentType.length > 0) {
                        lst = lst.filter((item: any) => {
                            let check = false;
                            searchValue.documentType.map((type: any) => {
                                if (item.documentType.toLowerCase().includes(type.toLowerCase())) {
                                    check = true;
                                }
                            })
                            return check;
                        });
                    }

                    if (searchValue.officer) {
                        lst = lst.filter((item: any) => item.officer.toLowerCase().includes(searchValue.officer.toLowerCase()));
                    }

                    if (searchValue.documentContent) {
                        lst = lst.filter((item: any) => item.documentContent.toLowerCase().includes(searchValue.documentContent.toLowerCase()));
                    }

                    setLstRequestTmp(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            await apiDocument.getAllTmpByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.documents;

                    if (searchValue.documentNumber) {
                        lst = lst.filter((item: any) => item.documentNumber.toLowerCase().includes(searchValue.documentNumber.toLowerCase()));
                    }

                    if (searchValue.documentTo) {
                        lst = lst.filter((item: any) => item.documentTo.toLowerCase().includes(searchValue.documentTo.toLowerCase()));
                    }

                    if (searchValue.documentDate) {
                        lst = lst.filter((item: any) => dayjs(item.documentDate).format('DD/MM/YYYY').includes(searchValue.documentDate));
                    }

                    if (searchValue.organization) {
                        lst = lst.filter((item: any) => item.organization.toLowerCase().includes(searchValue.organization.toLowerCase()));
                    }

                    if (searchValue.contact) {
                        lst = lst.filter((item: any) => item.contact.toLowerCase().includes(searchValue.contact.toLowerCase()));
                    }

                    if (searchValue.documentType && searchValue.documentType.length > 0) {
                        lst = lst.filter((item: any) => {
                            let check = false;
                            searchValue.documentType.map((type: any) => {
                                if (item.documentType.toLowerCase().includes(type.toLowerCase())) {
                                    check = true;
                                }
                            })
                            return check;
                        });
                    }

                    if (searchValue.officer) {
                        lst = lst.filter((item: any) => item.officer.toLowerCase().includes(searchValue.officer.toLowerCase()));
                    }

                    if (searchValue.documentContent) {
                        lst = lst.filter((item: any) => item.documentContent.toLowerCase().includes(searchValue.documentContent.toLowerCase()));
                    }

                    setLstRequestTmp(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin tài liệu',
            content: 'Bạn có chắc chắn muốn xóa thông tin tài liệu này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiDocument.deleteDocument(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);
                        notification.success({
                            message: 'Xóa thành công',
                            description: 'Xóa thông tin tài liệu thành công!'
                        });

                        getAllRequest(searchValue)
                        getAllRequestTmp(searchValue)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = async (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);

        formEdit.setFieldsValue({
            documentNumber: record.documentNumber,
            documentTo: record.documentTo,
            documentDate: dayjs(record.documentDate),
            organization: record.organization,
            contact: record.contact,
            documentType: record.documentType,
            officer: record.officer,
            documentContent: record.documentContent,
        });

        // Kiểm tra và lấy thông tin file hiện tại
        try {
            const response = await apiDocument.getFileByDocumentId(record.id, localStorage.getItem('token'));
            if (response.data) {
                console.log(response.data.data);
                setCurrentFile(response.data.data);
            } else {
                setCurrentFile(null);
            }
        } catch (error) {
            console.error('Error fetching file:', error);
            setCurrentFile(null);
        }

    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);


        const req = {
            id: requestChoose.id,
            documentNumber: values.documentNumber,
            documentTo: values.documentTo,
            documentDate: new Date(values.documentDate),
            organization: values.organization,
            contact: values.contact,
            documentType: values.documentType,
            officer: values.officer,
            documentContent: values.documentContent,
            status: 'Chờ phê duyệt'

        }

        console.log(req);

        await apiDocument.updateDocument(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                notification.success({
                    message: 'Sửa thành công',
                    description: 'Sửa thông tin tài liệu thành công!'
                });

                getAllRequest(searchValue)
                getAllRequestTmp(searchValue)
                setIsOpenModalEdit(false);

                setTimeout(async () => {
                    if (fileToUpload) {
                        const formData = new FormData();
                        formData.append('files', fileToUpload);
                        if (currentFile) {
                            await apiDocument.updateFile(requestChoose.id, formData, localStorage.getItem('token'));
                        } else {
                            formData.append('documentId', requestChoose.id);
                            await apiDocument.uploadFile(formData, localStorage.getItem('token'));
                        }
                    }

                    getAllFile()
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} tài liệu`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " tài liệu / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {
        console.log(values);
        const req = {
            documentNumber: values.documentNumber,
            documentTo: values.documentTo,
            documentDate: new Date(values.documentDate),
            organization: values.organization,
            contact: values.contact,
            documentType: values.documentType,
            officer: values.officer,
            documentContent: values.documentContent,
            userId: localStorage.getItem('userId'),
            status: 'Đã tạo'
        }

        console.log(req);

        await apiDocument.createDocument(req, localStorage.getItem('token'))
            .then(async (res) => {
                console.log(res.data);

                notification.success({
                    message: 'Thêm mới thành công',
                    description: 'Thêm mới tài liệu thành công!'
                });

                getAllRequest(searchValue)
                getAllRequestTmp(searchValue)
                setIsOpenModalAdd(false);

                setTimeout(async () => {
                    if (fileToUpload) {
                        const formData = new FormData();
                        formData.append('documentId', res.data.data.id);
                        formData.append('files', fileToUpload);
                        try {
                            await apiDocument.uploadFile(formData, localStorage.getItem('token'));
                            console.log('Upload thành công');

                        } catch (error) {
                            console.log(error);
                        }
                    }

                    getAllFile()
                }, 3000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleViewRequest = (record: any, index: any) => {
        console.log(record);
        setIsOpenModalView(true);
        setRequestChoose(record);

        form.setFieldsValue({
            documentNumber: record.documentNumber,
            documentTo: record.documentTo,
            documentDate: dayjs(record.documentDate),
            organization: record.organization,
            contact: record.contact,
            documentType: record.documentType,
            officer: record.officer,
            documentContent: record.documentContent,

        });

        if (changeInfo[index]) {
            setIsChangeInfo(changeInfo[index]);
        }
        else {
            setIsChangeInfo('');
        }

    }

    const handleFinishViewRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            documentNumber: values.documentNumber,
            documentTo: values.documentTo,
            documentDate: new Date(values.documentDate),
            organization: values.organization,
            contact: values.contact,
            documentType: values.documentType,
            officer: values.officer,
            documentContent: values.documentContent,
            status: 'Đã tạo'
        }

        await apiDocument.changeStatus(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Phê duyệt thành công',
                    description: 'Phê duyệt thông tin tài liệu thành công!'
                });
                getAllRequestTmp(searchValue)
                getAllRequest(searchValue)
                setIsOpenModalView(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const exportToExcel = async () => {

        // Mở 1 modal trong đó cho nhận tên file, khi oke sẽ tạo file excel

        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        // const titleRow = worksheet.addRow([`${fileName} (tính đến ${formattedDate})`]);
        const titleRow = worksheet.addRow([`${fileName}`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:J1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3');
        worksheet.mergeCells('B2:B3');
        worksheet.mergeCells('C2:C3');
        worksheet.mergeCells('D2:D3');
        worksheet.mergeCells('E2:E3');
        worksheet.mergeCells('F2:F3');
        worksheet.mergeCells('G2:G3');
        worksheet.mergeCells('H2:H3');
        worksheet.mergeCells('I2:I3');
        worksheet.mergeCells('J2:J3');

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Số công văn',
            'Số đến',
            'Ngày công văn',
            'Đơn vị',
            'Đầu mối',
            'Loại công văn',
            'Cán bộ xử lý',
            'Nội dung công văn',
            'Trạng thái'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'documentNumber', width: 20 },
            { key: 'documentTo', width: 20 },
            { key: 'documentDate', width: 20 },
            { key: 'organization', width: 20 },
            { key: 'contact', width: 20 },
            { key: 'documentType', width: 20 },
            { key: 'officer', width: 20 },
            { key: 'documentContent', width: 75 },
            { key: 'status', width: 20 }
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        lstRequestTmp.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: index + 1,
                documentNumber: item.documentNumber,
                documentTo: item.documentTo,
                documentDate: dayjs(item.documentDate).format('DD/MM/YYYY'),
                organization: item.organization,
                contact: item.contact,
                documentType: item.documentType,
                officer: item.officer,
                documentContent: item.documentContent,
                status: item.status
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const localeTable = {
        emptyText: 'Không có dữ liệu tài liệu',
    }

    const handleUploadFile = (info: any) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            setFileToUpload(info.file.originFileObj);
            notification.success({
                message: 'Tệp đã được chọn',
                description: `${info.file.name} đã được chọn thành công.`
            });
        } else if (status === 'error') {
            setFileToUpload(null);
            notification.error({
                message: 'Chọn tệp thất bại',
                description: `${info.file.name} chọn thất bại.`
            });
        }
    };

    const handleDownloadFile = async (record: any) => {
        console.log(record);
        console.log(lstAllFile);

        try {
            const file = lstAllFile.find((file: any) => file.documentId === record.id);
            if (file) {
                console.log(file.filename);

                // Tạo một thẻ a ẩn và kích hoạt sự kiện click
                const link = document.createElement('a');
                link.href = `${API_URL.HOST}/document/file/download/${file.filename}`;
                // link.target = '_blank';
                link.download = file.filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                notification.success({
                    message: 'Tải xuống thành công',
                    description: `Đã bắt đầu tải xuống ${file.filename}.`
                });
            } else {
                throw new Error('Không tìm thấy file');
            }
        } catch (error) {
            console.error('Download error:', error);
            notification.error({
                message: 'Tải xuống thất bại',
                description: 'Có lỗi xảy ra khi tải xuống tệp.'
            });
        }
    };

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        action: 'dummy',
        customRequest: ({ file, onSuccess }: any) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        maxCount: 1,
        onChange: handleUploadFile,
    };

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <Breadcrumb
                items={[
                    {
                        title: 'Lĩnh vực kinh tế',
                    },

                    {
                        title: 'Thẩm định tài liệu',
                    },
                ]}

            />

            <div className='title-home'>
                <h2>Thẩm định tài liệu</h2>
            </div>

            <div className="filter-contailer">
                <div className="lst-filter">
                    <div className='lst-filter-item'>
                        <div className="filter-item">
                            <div className='label'>Số công văn: </div>
                            <Input
                                allowClear
                                placeholder='Nhập số công văn'
                                onChange={(e) => setSearchValue({ ...searchValue, documentNumber: e.target.value })}
                            />
                        </div>

                        {/* Chọn nhiều loại công văn */}
                        <div className="filter-item">
                            <div className='label'>Loại công văn: </div>
                            <Select
                                allowClear
                                mode='multiple'
                                placeholder='Chọn loại công văn'
                                style={{ width: 300 }}
                                onChange={(value) => setSearchValue({ ...searchValue, documentType: value })}
                            >
                                <Select.Option value='Công văn'>Công văn</Select.Option>
                                <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                <Select.Option value='Tham gia ý kiến'>Tham gia ý kiến</Select.Option>
                                <Select.Option value='Giới thiệu sản phẩm'>Giới thiệu sản phẩm</Select.Option>
                                <Select.Option value='Đối ngoại quốc phòng'>Đối ngoại quốc phòng</Select.Option>
                                <Select.Option value='Báo cáo kết quả đoàn QS'>Báo cáo kết quả đoàn QS</Select.Option>
                                <Select.Option value='Báo cáo kết quả dự án'>Báo cáo kết quả dự án</Select.Option>
                                <Select.Option value='Kế hoạch'>Kế hoạch</Select.Option>
                                <Select.Option value='Báo cáo công việc'>Báo cáo công việc</Select.Option>
                                <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="lst-btn-filter">
                    <Button type='primary'>Tìm kiếm</Button>
                </div>

            </div >
            <div className="table-request">
                <div className="title-table">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <h2> Danh sách tài liệu</h2>
                        {
                            localStorage.getItem('role') === 'admin' &&
                            <div className="record-info">
                                <span>{numberNewRecord}</span>  tài liệu mới - <span>{numberUpdateRecord}</span> tài liệu chờ phê duyệt
                            </div>

                        }
                    </div>
                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>
                {
                    localStorage.getItem('role') === 'admin' ? (
                        <Table columns={columnsAdmin} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} rowClassName={(record) => record.status === 'Chờ phê duyệt' ? 'pending-approval' : ''} />
                    ) : (
                        <Table columns={columns} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} />
                    )}

                <div className='button-excel'>
                    <Button disabled={(lstRequestTmp && lstRequestTmp.length > 0) ? false : true} onClick={() => setIsOpenModalFile(true)} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                </div>
            </div>

            <Modal
                title="Thêm mới tài liệu"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn'
                                name='documentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Số đến'
                                name='documentTo'
                                rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                            >
                                <Input placeholder='Nhập số đến' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn'
                                name='documentDate'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn'
                                    disabledDate={(current) => current && current.isAfter(dayjs())}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Đơn vị'
                                name='organization'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}
                            >
                                <Input placeholder='Nhập đơn vị' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Đầu mối'
                                name='contact'
                                rules={[{ required: true, message: 'Vui lòng nhập đầu mối!' }]}
                            >
                                <Input placeholder='Nhập đầu mối' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Loại công văn'
                                name='documentType'
                                rules={[{ required: true, message: 'Vui lòng nhập loại công văn!' }]}
                            >
                                <Select
                                    placeholder='Chọn loại công văn'
                                >
                                    <Select.Option value='Công văn'>Công văn</Select.Option>
                                    <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                    <Select.Option value='Tham gia ý kiến'>Tham gia ý kiến</Select.Option>
                                    <Select.Option value='Giới thiệu sản phẩm'>Giới thiệu sản phẩm</Select.Option>
                                    <Select.Option value='Đối ngoại quốc phòng'>Đối ngoại quốc phòng</Select.Option>
                                    <Select.Option value='Báo cáo kết quả đoàn QS'>Báo cáo kết quả đoàn QS</Select.Option>
                                    <Select.Option value='Báo cáo kết quả dự án'>Báo cáo kết quả dự án</Select.Option>
                                    <Select.Option value='Kế hoạch'>Kế hoạch</Select.Option>
                                    <Select.Option value='Báo cáo công việc'>Báo cáo công việc</Select.Option>
                                    <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Cán bộ xử lý'
                                name='officer'
                                rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý!' }]}
                            >
                                <Input placeholder='Nhập cán bộ xử lý' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label='Nội dung công văn'
                        name='documentContent'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn!' }]}
                    >
                        <Input.TextArea placeholder='Nhập nội dung công văn' rows={5} />
                    </Form.Item>

                    <Form.Item
                        label='Tải lên tệp'
                        name='file'
                    >
                        <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                            <p className="ant-upload-hint">
                                Hỗ trợ tải lên một tệp duy nhất. Tên tệp không nên có dấu để tránh lỗi.
                            </p>
                        </Dragger>
                    </Form.Item>
                </Form>

            </Modal>

            <Modal
                title="Sửa thông tin tài liệu"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => {
                    setIsOpenModalEdit(false)
                    setCurrentFile(null)
                }}
                okText='Sửa'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn'
                                name='documentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Số đến'
                                name='documentTo'
                                rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                            >
                                <Input placeholder='Nhập số đến' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn'
                                name='documentDate'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn'
                                    disabledDate={(current) => current && current.isAfter(dayjs())}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Đơn vị'
                                name='organization'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}
                            >
                                <Input placeholder='Nhập đơn vị' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Đầu mối'
                                name='contact'
                                rules={[{ required: true, message: 'Vui lòng nhập đầu mối!' }]}
                            >
                                <Input placeholder='Nhập đầu mối' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Loại công văn'
                                name='documentType'
                                rules={[{ required: true, message: 'Vui lòng nhập loại công văn!' }]}
                            >
                                <Select
                                    placeholder='Chọn loại công văn'
                                >
                                    <Select.Option value='Công văn'>Công văn</Select.Option>
                                    <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                    <Select.Option value='Tham gia ý kiến'>Tham gia ý kiến</Select.Option>
                                    <Select.Option value='Giới thiệu sản phẩm'>Giới thiệu sản phẩm</Select.Option>
                                    <Select.Option value='Đối ngoại quốc phòng'>Đối ngoại quốc phòng</Select.Option>
                                    <Select.Option value='Báo cáo kết quả đoàn QS'>Báo cáo kết quả đoàn QS</Select.Option>
                                    <Select.Option value='Báo cáo kết quả dự án'>Báo cáo kết quả dự án</Select.Option>
                                    <Select.Option value='Kế hoạch'>Kế hoạch</Select.Option>
                                    <Select.Option value='Báo cáo công việc'>Báo cáo công việc</Select.Option>
                                    <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Cán bộ xử lý'
                                name='officer'
                                rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý!' }]}
                            >
                                <Input placeholder='Nhập cán bộ xử lý' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label='Nội dung công văn'
                        name='documentContent'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn!' }]}
                    >
                        <Input.TextArea placeholder='Nhập nội dung công văn' rows={5} />
                    </Form.Item>

                    <Form.Item
                        label='Tải lên tệp'
                        name='file'
                    >
                        {currentFile ? (
                            <div>
                                <p>File hiện tại: {currentFile.filename}</p>
                            </div>
                        ) : null}
                        <Dragger {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
                            <p className="ant-upload-hint">
                                Hỗ trợ tải lên một tệp duy nhất. Tên tệp không nên có dấu để tránh lỗi.
                            </p>
                        </Dragger>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Xem chi tiết tài liệu"
                visible={isOpenModalView}
                onOk={form.submit}
                onCancel={() => setIsOpenModalView(false)}
                okText='Phê duyệt'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                <Form
                    form={form}
                    onFinish={handleFinishViewRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số công văn') ?
                                    <Form.Item
                                        label='Số công văn'
                                        name='documentNumber'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số công văn' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số công văn'
                                        name='documentNumber'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                                    >
                                        <Input placeholder='Nhập số công văn' />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số đến') ?
                                    <Form.Item
                                        label='Số đến'
                                        name='documentTo'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số đến' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số đến'
                                        name='documentTo'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                                    >
                                        <Input placeholder='Nhập số đến' />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Ngày công văn') ?
                                    <Form.Item
                                        label='Ngày công văn'
                                        name='documentDate'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày công văn'
                                        name='documentDate'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn!' }]}
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Đơn vị') ?
                                    <Form.Item
                                        label='Đơn vị'
                                        name='organization'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập đơn vị' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đơn vị'
                                        name='organization'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Đầu mối') ?
                                    <Form.Item
                                        label='Đầu mối'
                                        name='contact'
                                        rules={[{ required: true, message: 'Vui lòng nhập đầu mối!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập đầu mối' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đầu mối'
                                        name='contact'
                                        rules={[{ required: true, message: 'Vui lòng nhập đầu mối!' }]}
                                    >
                                        <Input placeholder='Nhập đầu mối' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Loại công văn') ?
                                    <Form.Item
                                        label='Loại công văn'
                                        name='documentType'
                                        rules={[{ required: true, message: 'Vui lòng nhập loại công văn!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Select
                                            placeholder='Chọn loại công văn'
                                        >
                                            <Select.Option value='Công văn'>Công văn</Select.Option>
                                            <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                            <Select.Option value='Tham gia ý kiến'>Tham gia ý kiến</Select.Option>
                                            <Select.Option value='Giới thiệu sản phẩm'>Giới thiệu sản phẩm</Select.Option>
                                            <Select.Option value='Đối ngoại quốc phòng'>Đối ngoại quốc phòng</Select.Option>
                                            <Select.Option value='Báo cáo kết quả đoàn QS'>Báo cáo kết quả đoàn QS</Select.Option>
                                            <Select.Option value='Báo cáo kết quả dự án'>Báo cáo kết quả dự án</Select.Option>
                                            <Select.Option value='Kế hoạch'>Kế hoạch</Select.Option>
                                            <Select.Option value='Báo cáo công việc'>Báo cáo công việc</Select.Option>
                                            <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Loại công văn'
                                        name='documentType'
                                        rules={[{ required: true, message: 'Vui lòng nhập loại công văn!' }]}
                                    >
                                        <Select
                                            placeholder='Chọn loại công văn'
                                        >
                                            <Select.Option value='Công văn'>Công văn</Select.Option>
                                            <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                            <Select.Option value='Tham gia ý kiến'>Tham gia ý kiến</Select.Option>
                                            <Select.Option value='Giới thiệu sản phẩm'>Giới thiệu sản phẩm</Select.Option>
                                            <Select.Option value='Đối ngoại quốc phòng'>Đối ngoại quốc phòng</Select.Option>
                                            <Select.Option value='Báo cáo kết quả đoàn QS'>Báo cáo kết quả đoàn QS</Select.Option>
                                            <Select.Option value='Báo cáo kết quả dự án'>Báo cáo kết quả dự án</Select.Option>
                                            <Select.Option value='Kế hoạch'>Kế hoạch</Select.Option>
                                            <Select.Option value='Báo cáo công việc'>Báo cáo công việc</Select.Option>
                                            <Select.Option value='Thẩm định nhân sự'>Thẩm định nhân sự</Select.Option>
                                        </Select>
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Cán bộ xử lý') ?
                                    <Form.Item
                                        label='Cán bộ xử lý'
                                        name='officer'
                                        rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập cán bộ xử lý' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Cán bộ xử lý'
                                        name='officer'
                                        rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý!' }]}
                                    >
                                        <Input placeholder='Nhập cán bộ xử lý' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    {
                        isChangeInfo.includes('Nội dung công văn') ?
                            <Form.Item
                                label='Nội dung công văn'
                                name='documentContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input.TextArea placeholder='Nhập nội dung công văn' rows={5} />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Nội dung công văn'
                                name='documentContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn!' }]}
                            >
                                <Input.TextArea placeholder='Nhập nội dung công văn' rows={5} />
                            </Form.Item>
                    }

                </Form>
            </Modal>

            <Modal
                title='Xác nhận xuất file'
                visible={isOpenModalFile}
                onCancel={() => setIsOpenModalFile(false)}
                onOk={formExcel.submit}
                okText='Xuất file'
                cancelText='Hủy'
            >
                <Form
                    layout='vertical'
                    form={formExcel}
                    onFinish={exportToExcel}
                >
                    <Form.Item
                        label='Tên file'
                        name='fileName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                    >
                        <Input placeholder='Nhập tên file' onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
};

export default Approve;