import { DeleteOutlined, EditOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Modal, notification, Row, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../api/request';
import { MainState } from '../../MainProvider';
import { apiCoopration } from '../../api/cooperation';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// import tmpData from './data';


const Editor: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [lstRequestTmp, setLstRequestTmp] = useState<any[]>([]); // Danh sách kiến nghị tạm
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>({}); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [changeInfo, setChangeInfo] = useState<any>([]); // Thông tin thay đổi
    const [isChangeInfo, setIsChangeInfo] = useState<any>(''); // Nội dung modal xem chi tiết kiến nghị
    const [numberNewRecord, setNumberNewRecord] = useState(0); // Số lượng bản ghi mới
    const [numberUpdateRecord, setNumberUpdateRecord] = useState(0); // Số lượng bản ghi cập nhật
    const columns: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Số đăng ký thành lập doanh nghiệp',
            dataIndex: 'registerNumber',
            width: 270,
        },
        {
            title: 'Ngày cấp giấy thành lập doanh nghiệp',
            dataIndex: 'dateRegister',
            width: 280,
            render: (text: any, record: any) => (
                <div>{moment((record.dateRegister)).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Nơi cấp giấy thành lập doanh nghiệp',
            dataIndex: 'placeRegister',
            width: 280,
        },
        {
            title: 'Số đăng ký kinh doanh',
            dataIndex: 'businessRegistrationNumber',
            width: 200,
        },
        {
            title: 'Ngày cấp giấy đăng ký kinh doanh',
            dataIndex: 'dateBusinessRegistration',
            width: 280,
            render: (text: any, record: any) => (
                <div>{moment((record.dateBusinessRegistration)).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Nơi cấp giấy đăng ký kinh doanh',
            dataIndex: 'placeBusinessRegistration',
            width: 280,
        },
        {
            title: 'Tên doanh nghiệp/ công ty',
            dataIndex: 'cooperationUnit',
            width: 200,
        },
        {
            title: 'Nội dung hợp tác',
            dataIndex: 'cooperationContent',
            width: 1000,
        },
        {
            title: 'Thời gian hợp tác',
            dataIndex: 'cooperationTime',
            width: 200,
            render: (text: any, record: any) => (
                <div>{moment(record.cooperationTimeStart).format('DD/MM/YYYY')} - {moment(record.cooperationTimeStart).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Tên chương trình/dự án',
            dataIndex: 'projectName',
            width: 200,
        },
        {
            title: 'Kết quả thẩm định/xác minh',
            dataIndex: 'verificationResult',
            width: 200,
        },
        {
            title: 'Các giấy tờ có liên quan đến công ty',
            dataIndex: 'relatedDocuments',
            width: 1000,
        },
        {
            title: 'Số công văn đề nghị/xác minh',
            dataIndex: 'requestLetter',
            width: 200,
        },
        {
            title: 'Ngày công văn đề nghị/xác minh',
            dataIndex: 'requestDate',
            width: 200,
            render: (text: any, record: any) => (
                <div>{moment(record.requestDate).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Đơn vị phối hợp/xác minh công ty/doanh nghiệp',
            dataIndex: 'verificationUnit',
            width: 250,
        },
        {
            title: 'Số công văn trả lời',
            dataIndex: 'replyLetterNumber',
            width: 200,
        },
        {
            title: 'Ngày công văn trả lời',
            dataIndex: 'replyLetter',
            width: 200,
            render: (text: any, record: any) => (
                <div>{moment(record.replyLetter).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Nội dung công văn trả lời',
            dataIndex: 'replyLetterContent',
            width: 1000,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            width: 280,
            render: (text: any, record: any) => (
                <div>{record.note || 'Không có ghi chú gì'}</div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 130,
            fixed: 'right',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: 100,
            fixed: 'right',
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    {/* <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip> */}
                </div>
            )
        }
    ]
    const columnsAdmin: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Thông tin thay đổi',
            dataIndex: 'changeInfo',
            key: 'changeInfo',
            width: 250,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        <div>{changeInfo[index] || 'Không có thông tin thay đổi'}</div>
                    }
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Xem và phê duyệt'>
                                <div onClick={() => handleViewRequest(record, index)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(0);
    const [searchValue, setSearchValue] = useState({
        registerNumber: '',
        dateRegister: '',
        placeRegister: '',
        businessRegistrationNumber: '',
        dateBusinessRegistration: '',
        placeBusinessRegistration: '',
        cooperationUnit: '',
        cooperationContent: '',
        cooperationTime: '',
        projectName: '',
        verificationResult: '',
        requestLetter: '',
        requestDate: '',
        verificationUnit: '',
        replyLetter: '',
        replyLetterNumber: '',
        replyLetterContent: '',
        note: ''
    }); // Giá trị tìm kiếm
    const [fileName, setFileName] = useState(''); // Tên file
    const [isOpenModalFile, setIsOpenModalFile] = useState(false); // Mở modal file
    const [formExcel] = Form.useForm(); // Form import file

    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        getAllRequest(searchValue);
        getAllRequestTmp(searchValue)
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log(searchValue);
        getAllRequest(searchValue);
        getAllRequestTmp(searchValue);
    }, [searchValue]);

    useEffect(() => {
        console.log('lstRequestTmp', lstRequestTmp);
        console.log('lstRequest', lstRequest);

        let lstInfo: any[] = [];
        if (lstRequestTmp.length > 0 && lstRequest.length > 0 && lstRequest.length === lstRequestTmp.length) {
            lstRequestTmp.map((item: any, index: any) => {
                let lst = lstRequestTmp.map((item: any, index: any) => {
                    let lstTmp = lstRequest.filter((itemExpert: any) => itemExpert.codeCoopration === item.codeCoopration);
                    return lstTmp[0];
                })

                let info = '';
                if (item.registerNumber !== lst[index].registerNumber) {
                    info += `Số đăng ký thành lập doanh nghiệp, `;
                }
                if (item.dateRegister !== lst[index].dateRegister) {
                    info += `Ngày cấp giấy thành lập doanh nghiệp, `;
                }
                if (item.placeRegister !== lst[index].placeRegister) {
                    info += `Nơi cấp giấy thành lập doanh nghiệp, `;
                }
                if (item.businessRegistrationNumber !== lst[index].businessRegistrationNumber) {
                    info += `Số đăng ký kinh doanh, `;
                }
                if (item.dateBusinessRegistration !== lst[index].dateBusinessRegistration) {
                    info += `Ngày cấp giấy đăng ký kinh doanh, `;
                }
                if (item.placeBusinessRegistration !== lst[index].placeBusinessRegistration) {
                    info += `Nơi cấp giấy đăng ký kinh doanh, `;
                }
                if (item.cooperationUnit !== lst[index].cooperationUnit) {
                    info += `Tên doanh nghiệp/ công ty, `;
                }
                if (item.cooperationContent !== lst[index].cooperationContent) {
                    info += `Nội dung hợp tác, `;
                }
                if (item.cooperationTime !== lst[index].cooperationTime) {
                    info += `Thời gian hợp tác, `;
                }
                if (item.projectName !== lst[index].projectName) {
                    info += `Tên chương trình/dự án, `;
                }
                if (item.verificationResult !== lst[index].verificationResult) {
                    info += `Kết quả thẩm định/xác minh, `;
                }
                if (item.relatedDocuments !== lst[index].relatedDocuments) {
                    info += `Các giấy tờ có liên quan đến công ty, `;
                }
                if (item.requestLetter !== lst[index].requestLetter) {
                    info += `Số công văn đề nghị/xác minh, `;
                }
                if (item.requestDate !== lst[index].requestDate) {
                    info += `Ngày công văn đề nghị/xác minh, `;
                }
                if (item.verificationUnit !== lst[index].verificationUnit) {
                    info += `Đơn vị phối hợp/xác minh công ty/doanh nghiệp, `;
                }
                if (item.replyLetterNumber !== lst[index].replyLetterNumber) {
                    info += `Số công văn trả lời, `;
                }
                if (item.replyLetter !== lst[index].replyLetter) {
                    info += `Ngày công văn trả lời, `;
                }
                if (item.replyLetterContent !== lst[index].replyLetterContent) {
                    info += `Nội dung công văn trả lời, `;
                }
                if (item.note !== lst[index].note) {
                    info += `Ghi chú, `;
                }

                lstInfo.push(info)
            })
        }
        console.log('lstInfo', lstInfo);

        setChangeInfo(lstInfo);
    }, [lstRequestTmp, lstRequest]);

    const getAllRequest = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiCoopration.getAll(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.cooprations;

                    if (searchValue.registerNumber) {
                        lst = lst.filter((item: any) => item.registerNumber.toLowerCase().includes(searchValue.registerNumber.toLowerCase()));
                    }

                    if (searchValue.dateRegister) {
                        lst = lst.filter((item: any) => moment(item.dateRegister).format('DD/MM/YYYY').includes(searchValue.dateRegister));
                    }

                    if (searchValue.placeRegister) {
                        lst = lst.filter((item: any) => item.placeRegister.toLowerCase().includes(searchValue.placeRegister.toLowerCase()));
                    }

                    if (searchValue.businessRegistrationNumber) {
                        lst = lst.filter((item: any) => item.businessRegistrationNumber.toLowerCase().includes(searchValue.businessRegistrationNumber.toLowerCase()));
                    }

                    if (searchValue.dateBusinessRegistration) {
                        lst = lst.filter((item: any) => moment(item.dateBusinessRegistration).format('DD/MM/YYYY').includes(searchValue.dateBusinessRegistration));
                    }

                    if (searchValue.placeBusinessRegistration) {
                        lst = lst.filter((item: any) => item.placeBusinessRegistration.toLowerCase().includes(searchValue.placeBusinessRegistration.toLowerCase()));
                    }

                    if (searchValue.cooperationUnit) {
                        lst = lst.filter((item: any) => item.cooperationUnit.toLowerCase().includes(searchValue.cooperationUnit.toLowerCase()));
                    }

                    if (searchValue.cooperationContent) {
                        lst = lst.filter((item: any) => item.cooperationContent.toLowerCase().includes(searchValue.cooperationContent.toLowerCase()));
                    }

                    if (searchValue.cooperationTime) {
                        lst = lst.filter((item: any) => moment(item.cooperationTimeStart).format('DD/MM/YYYY').includes(searchValue.cooperationTime) || moment(item.cooperationTimeEnd).format('DD/MM/YYYY').includes(searchValue.cooperationTime));
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()));
                    }

                    if (searchValue.verificationResult) {
                        lst = lst.filter((item: any) => item.verificationResult.toLowerCase().includes(searchValue.verificationResult.toLowerCase()));
                    }

                    if (searchValue.relatedDocuments) {
                        lst = lst.filter((item: any) => item.relatedDocuments.toLowerCase().includes(searchValue.relatedDocuments.toLowerCase()));
                    }

                    if (searchValue.requestLetter) {
                        lst = lst.filter((item: any) => item.requestLetter.toLowerCase().includes(searchValue.requestLetter.toLowerCase()));
                    }

                    if (searchValue.requestDate) {
                        lst = lst.filter((item: any) => item.requestDate.toLowerCase().includes(searchValue.requestDate.toLowerCase()));
                    }

                    if (searchValue.verificationUnit) {
                        lst = lst.filter((item: any) => item.verificationUnit.toLowerCase().includes(searchValue.verificationUnit.toLowerCase()));
                    }

                    if (searchValue.replyLetter) {
                        // lst = lst.filter((item: any) => item.replyLetter.toLowerCase().includes(searchValue.replyLetter.toLowerCase()));
                    }

                    if (searchValue.replyLetterNumber) {
                        lst = lst.filter((item: any) => item.replyLetterNumber.toLowerCase().includes(searchValue.replyLetterNumber.toLowerCase()));
                    }

                    if (searchValue.replyLetterContent) {
                        lst = lst.filter((item: any) => item.replyLetterContent.toLowerCase().includes(searchValue.replyLetterContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequest(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            await apiCoopration.getAllByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.cooprations;

                    if (searchValue.registerNumber) {
                        lst = lst.filter((item: any) => item.registerNumber.toLowerCase().includes(searchValue.registerNumber.toLowerCase()));
                    }

                    if (searchValue.dateRegister) {
                        lst = lst.filter((item: any) => moment(item.dateRegister).format('DD/MM/YYYY').includes(searchValue.dateRegister));
                    }

                    if (searchValue.placeRegister) {
                        lst = lst.filter((item: any) => item.placeRegister.toLowerCase().includes(searchValue.placeRegister.toLowerCase()));
                    }

                    if (searchValue.businessRegistrationNumber) {
                        lst = lst.filter((item: any) => item.businessRegistrationNumber.toLowerCase().includes(searchValue.businessRegistrationNumber.toLowerCase()));
                    }

                    if (searchValue.dateBusinessRegistration) {
                        lst = lst.filter((item: any) => moment(item.dateBusinessRegistration).format('DD/MM/YYYY').includes(searchValue.dateBusinessRegistration));
                    }

                    if (searchValue.placeBusinessRegistration) {
                        lst = lst.filter((item: any) => item.placeBusinessRegistration.toLowerCase().includes(searchValue.placeBusinessRegistration.toLowerCase()));
                    }

                    if (searchValue.cooperationUnit) {
                        lst = lst.filter((item: any) => item.cooperationUnit.toLowerCase().includes(searchValue.cooperationUnit.toLowerCase()));
                    }

                    if (searchValue.cooperationContent) {
                        lst = lst.filter((item: any) => item.cooperationContent.toLowerCase().includes(searchValue.cooperationContent.toLowerCase()));
                    }

                    if (searchValue.cooperationTime) {
                        lst = lst.filter((item: any) => moment(item.cooperationTimeStart).format('DD/MM/YYYY').includes(searchValue.cooperationTime) || moment(item.cooperationTimeEnd).format('DD/MM/YYYY').includes(searchValue.cooperationTime));
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()));
                    }

                    if (searchValue.verificationResult) {
                        lst = lst.filter((item: any) => item.verificationResult.toLowerCase().includes(searchValue.verificationResult.toLowerCase()));
                    }

                    if (searchValue.relatedDocuments) {
                        lst = lst.filter((item: any) => item.relatedDocuments.toLowerCase().includes(searchValue.relatedDocuments.toLowerCase()));
                    }

                    if (searchValue.requestLetter) {
                        lst = lst.filter((item: any) => item.requestLetter.toLowerCase().includes(searchValue.requestLetter.toLowerCase()));
                    }

                    if (searchValue.requestDate) {
                        lst = lst.filter((item: any) => item.requestDate.toLowerCase().includes(searchValue.requestDate.toLowerCase()));
                    }

                    if (searchValue.verificationUnit) {
                        lst = lst.filter((item: any) => item.verificationUnit.toLowerCase().includes(searchValue.verificationUnit.toLowerCase()));
                    }

                    if (searchValue.replyLetter) {
                        // lst = lst.filter((item: any) => item.replyLetter.toLowerCase().includes(searchValue.replyLetter.toLowerCase()));
                    }

                    if (searchValue.replyLetterNumber) {
                        lst = lst.filter((item: any) => item.replyLetterNumber.toLowerCase().includes(searchValue.replyLetterNumber.toLowerCase()));
                    }

                    if (searchValue.replyLetterContent) {
                        lst = lst.filter((item: any) => item.replyLetterContent.toLowerCase().includes(searchValue.replyLetterContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequest(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const getAllRequestTmp = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiCoopration.getAllTmp(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.cooprations;
                    // Lọc ra status chờ phê duyệt hiển thị đầu tiên và đã tạo sau
                    lst = lst.sort((a: any, b: any) => {
                        if (a.status === 'Chờ phê duyệt' && b.status === 'Đã tạo') return -1;
                        if (a.status === 'Đã tạo' && b.status === 'Chờ phê duyệt') return 1;
                        return 0;
                    })

                    setNumberNewRecord(lst.filter((item: any) => item.status === 'Đã tạo').length);
                    setNumberUpdateRecord(lst.filter((item: any) => item.status === 'Chờ phê duyệt').length);

                    if (searchValue.registerNumber) {
                        lst = lst.filter((item: any) => item.registerNumber.toLowerCase().includes(searchValue.registerNumber.toLowerCase()));
                    }

                    if (searchValue.dateRegister) {
                        lst = lst.filter((item: any) => moment(item.dateRegister).format('DD/MM/YYYY').includes(searchValue.dateRegister));
                    }

                    if (searchValue.placeRegister) {
                        lst = lst.filter((item: any) => item.placeRegister.toLowerCase().includes(searchValue.placeRegister.toLowerCase()));
                    }

                    if (searchValue.businessRegistrationNumber) {
                        lst = lst.filter((item: any) => item.businessRegistrationNumber.toLowerCase().includes(searchValue.businessRegistrationNumber.toLowerCase()));
                    }

                    if (searchValue.dateBusinessRegistration) {
                        lst = lst.filter((item: any) => moment(item.dateBusinessRegistration).format('DD/MM/YYYY').includes(searchValue.dateBusinessRegistration));
                    }

                    if (searchValue.placeBusinessRegistration) {
                        lst = lst.filter((item: any) => item.placeBusinessRegistration.toLowerCase().includes(searchValue.placeBusinessRegistration.toLowerCase()));
                    }

                    if (searchValue.cooperationUnit) {
                        lst = lst.filter((item: any) => item.cooperationUnit.toLowerCase().includes(searchValue.cooperationUnit.toLowerCase()));
                    }

                    if (searchValue.cooperationContent) {
                        lst = lst.filter((item: any) => item.cooperationContent.toLowerCase().includes(searchValue.cooperationContent.toLowerCase()));
                    }

                    if (searchValue.cooperationTime) {
                        lst = lst.filter((item: any) => moment(item.cooperationTimeStart).format('DD/MM/YYYY').includes(searchValue.cooperationTime) || moment(item.cooperationTimeEnd).format('DD/MM/YYYY').includes(searchValue.cooperationTime));
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()));
                    }

                    if (searchValue.verificationResult) {
                        lst = lst.filter((item: any) => item.verificationResult.toLowerCase().includes(searchValue.verificationResult.toLowerCase()));
                    }

                    if (searchValue.relatedDocuments) {
                        lst = lst.filter((item: any) => item.relatedDocuments.toLowerCase().includes(searchValue.relatedDocuments.toLowerCase()));
                    }

                    if (searchValue.requestLetter) {
                        lst = lst.filter((item: any) => item.requestLetter.toLowerCase().includes(searchValue.requestLetter.toLowerCase()));
                    }

                    if (searchValue.requestDate) {
                        lst = lst.filter((item: any) => item.requestDate.toLowerCase().includes(searchValue.requestDate.toLowerCase()));
                    }

                    if (searchValue.verificationUnit) {
                        lst = lst.filter((item: any) => item.verificationUnit.toLowerCase().includes(searchValue.verificationUnit.toLowerCase()));
                    }

                    if (searchValue.replyLetter) {
                        // lst = lst.filter((item: any) => item.replyLetter.toLowerCase().includes(searchValue.replyLetter.toLowerCase()));
                    }

                    if (searchValue.replyLetterNumber) {
                        lst = lst.filter((item: any) => item.replyLetterNumber.toLowerCase().includes(searchValue.replyLetterNumber.toLowerCase()));
                    }

                    if (searchValue.replyLetterContent) {
                        lst = lst.filter((item: any) => item.replyLetterContent.toLowerCase().includes(searchValue.replyLetterContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequestTmp(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            await apiCoopration.getAllTmpByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.cooprations;

                    if (searchValue.registerNumber) {
                        lst = lst.filter((item: any) => item.registerNumber.toLowerCase().includes(searchValue.registerNumber.toLowerCase()));
                    }

                    if (searchValue.dateRegister) {
                        lst = lst.filter((item: any) => moment(item.dateRegister).format('DD/MM/YYYY').includes(searchValue.dateRegister));
                    }

                    if (searchValue.placeRegister) {
                        lst = lst.filter((item: any) => item.placeRegister.toLowerCase().includes(searchValue.placeRegister.toLowerCase()));
                    }

                    if (searchValue.businessRegistrationNumber) {
                        lst = lst.filter((item: any) => item.businessRegistrationNumber.toLowerCase().includes(searchValue.businessRegistrationNumber.toLowerCase()));
                    }

                    if (searchValue.dateBusinessRegistration) {
                        lst = lst.filter((item: any) => moment(item.dateBusinessRegistration).format('DD/MM/YYYY').includes(searchValue.dateBusinessRegistration));
                    }

                    if (searchValue.placeBusinessRegistration) {
                        lst = lst.filter((item: any) => item.placeBusinessRegistration.toLowerCase().includes(searchValue.placeBusinessRegistration.toLowerCase()));
                    }

                    if (searchValue.cooperationUnit) {
                        lst = lst.filter((item: any) => item.cooperationUnit.toLowerCase().includes(searchValue.cooperationUnit.toLowerCase()));
                    }

                    if (searchValue.cooperationContent) {
                        lst = lst.filter((item: any) => item.cooperationContent.toLowerCase().includes(searchValue.cooperationContent.toLowerCase()));
                    }

                    if (searchValue.cooperationTime) {
                        lst = lst.filter((item: any) => moment(item.cooperationTimeStart).format('DD/MM/YYYY').includes(searchValue.cooperationTime) || moment(item.cooperationTimeEnd).format('DD/MM/YYYY').includes(searchValue.cooperationTime));
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()));
                    }

                    if (searchValue.verificationResult) {
                        lst = lst.filter((item: any) => item.verificationResult.toLowerCase().includes(searchValue.verificationResult.toLowerCase()));
                    }

                    if (searchValue.relatedDocuments) {
                        lst = lst.filter((item: any) => item.relatedDocuments.toLowerCase().includes(searchValue.relatedDocuments.toLowerCase()));
                    }

                    if (searchValue.requestLetter) {
                        lst = lst.filter((item: any) => item.requestLetter.toLowerCase().includes(searchValue.requestLetter.toLowerCase()));
                    }

                    if (searchValue.requestDate) {
                        lst = lst.filter((item: any) => item.requestDate.toLowerCase().includes(searchValue.requestDate.toLowerCase()));
                    }

                    if (searchValue.verificationUnit) {
                        lst = lst.filter((item: any) => item.verificationUnit.toLowerCase().includes(searchValue.verificationUnit.toLowerCase()));
                    }

                    if (searchValue.replyLetter) {
                        // lst = lst.filter((item: any) => item.replyLetter.toLowerCase().includes(searchValue.replyLetter.toLowerCase()));
                    }

                    if (searchValue.replyLetterNumber) {
                        lst = lst.filter((item: any) => item.replyLetterNumber.toLowerCase().includes(searchValue.replyLetterNumber.toLowerCase()));
                    }

                    if (searchValue.replyLetterContent) {
                        lst = lst.filter((item: any) => item.replyLetterContent.toLowerCase().includes(searchValue.replyLetterContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequestTmp(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin đối tác',
            content: 'Bạn có chắc chắn muốn xóa thông tin đối tác này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiCoopration.deleteCooperation(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);
                        notification.success({
                            message: 'Xóa thành công',
                            description: 'Xóa thông tin đối tác thành công!'
                        });

                        getAllRequest(searchValue);
                        getAllRequestTmp(searchValue)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);


        formEdit.setFieldsValue({
            registerNumber: record.registerNumber,
            dateRegister: dayjs(record.dateRegister),
            placeRegister: record.placeRegister,
            businessRegistrationNumber: record.businessRegistrationNumber || record.registerNumber,
            dateBusinessRegistration: record.dateBusinessRegistration ? dayjs(record.dateBusinessRegistration) : dayjs(record.dateRegister),
            placeBusinessRegistration: record.placeBusinessRegistration || record.placeRegister,
            cooperationUnit: record.cooperationUnit,
            cooperationContent: record.cooperationContent,
            cooperationTime: [dayjs(record.cooperationTimeStart), dayjs(record.cooperationTimeEnd)],
            projectName: record.projectName,
            verificationResult: record.verificationResult,
            relatedDocuments: record.relatedDocuments,
            requestLetter: record.requestLetter,
            requestDate: dayjs(record.requestDate),
            verificationUnit: record.verificationUnit,
            replyLetter: dayjs(record.replyLetter),
            replyLetterNumber: record.replyLetterNumber,
            replyLetterContent: record.replyLetterContent,
            note: record.note,
        });

    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);


        const req = {
            id: requestChoose.id,
            registerNumber: values.registerNumber,
            dateRegister: new Date(values.dateRegister),
            placeRegister: values.placeRegister,
            businessRegistrationNumber: values.businessRegistrationNumber || values.registerNumber,
            dateBusinessRegistration: values.dateBusinessRegistration ? new Date(values.dateBusinessRegistration) : new Date(values.dateRegister),
            placeBusinessRegistration: values.placeBusinessRegistration || values.placeRegister,
            cooperationUnit: values.cooperationUnit,
            cooperationContent: values.cooperationContent,
            cooperationTimeStart: new Date(values.cooperationTime[0]),
            cooperationTimeEnd: new Date(values.cooperationTime[1]),
            projectName: values.projectName,
            verificationResult: values.verificationResult,
            relatedDocuments: values.relatedDocuments,
            requestLetter: values.requestLetter,
            requestDate: new Date(values.requestDate),
            verificationUnit: values.verificationUnit,
            replyLetter: new Date(values.replyLetter),
            replyLetterNumber: values.replyLetterNumber,
            replyLetterContent: values.replyLetterContent,
            note: values.note || '',
            status: 'Chờ phê duyệt'

        }

        console.log(req);

        await apiCoopration.updateCooperation(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                notification.success({
                    message: 'Sửa thành công',
                    description: 'Sửa thông tin đối tác thành công!'
                });

                getAllRequest(searchValue);
                getAllRequestTmp(searchValue)
                setIsOpenModalEdit(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} đối tác`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " đối tác / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {
        console.log(values);
        const req = {
            registerNumber: values.registerNumber,
            dateRegister: new Date(values.dateRegister),
            placeRegister: values.placeRegister,
            businessRegistrationNumber: values.businessRegistrationNumber || values.registerNumber,
            dateBusinessRegistration: values.dateBusinessRegistration ? new Date(values.dateBusinessRegistration) : new Date(values.dateRegister),
            placeBusinessRegistration: values.placeBusinessRegistration || values.placeRegister,
            cooperationUnit: values.cooperationUnit,
            cooperationContent: values.cooperationContent,
            cooperationTimeStart: new Date(values.cooperationTime[0]),
            cooperationTimeEnd: new Date(values.cooperationTime[1]),
            projectName: values.projectName,
            verificationResult: values.verificationResult,
            relatedDocuments: values.relatedDocuments,
            requestLetter: values.requestLetter,
            requestDate: new Date(values.requestDate),
            verificationUnit: values.verificationUnit,
            replyLetter: new Date(values.replyLetter),
            replyLetterNumber: values.replyLetterNumber,
            replyLetterContent: values.replyLetterContent,
            note: values.note || '',
            userId: localStorage.getItem('userId'),
            status: 'Đã tạo'
        }

        console.log(req);

        await apiCoopration.createCooperation(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                notification.success({
                    message: 'Thêm mới thành công',
                    description: 'Thêm mới đối tác thành công!'
                });

                getAllRequest(searchValue);
                getAllRequestTmp(searchValue)
                setIsOpenModalAdd(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleViewRequest = (record: any, index: any) => {
        console.log(record);
        setIsOpenModalView(true);
        setRequestChoose(record);

        form.setFieldsValue({
            registerNumber: record.registerNumber,
            dateRegister: moment(record.dateRegister),
            placeRegister: record.placeRegister,
            businessRegistrationNumber: record.businessRegistrationNumber || record.registerNumber,
            dateBusinessRegistration: record.dateBusinessRegistration ? moment(record.dateBusinessRegistration) : moment(record.dateRegister),
            placeBusinessRegistration: record.placeBusinessRegistration || record.placeRegister,
            cooperationUnit: record.cooperationUnit,
            cooperationContent: record.cooperationContent,
            cooperationTime: [moment(record.cooperationTimeStart), moment(record.cooperationTimeEnd)],
            projectName: record.projectName,
            verificationResult: record.verificationResult,
            relatedDocuments: record.relatedDocuments,
            requestLetter: record.requestLetter,
            requestDate: moment(record.requestDate),
            verificationUnit: record.verificationUnit,
            replyLetter: moment(record.replyLetter),
            replyLetterNumber: record.replyLetterNumber,
            replyLetterContent: record.replyLetterContent,
            note: record.note,

        });

        if (changeInfo[index]) {
            setIsChangeInfo(changeInfo[index]);
        }
        else {
            setIsChangeInfo('');
        }

    }

    const handleFinishViewRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            registerNumber: values.registerNumber,
            dateRegister: new Date(values.dateRegister),
            placeRegister: values.placeRegister,
            businessRegistrationNumber: values.businessRegistrationNumber || values.registerNumber,
            dateBusinessRegistration: values.dateBusinessRegistration ? new Date(values.dateBusinessRegistration) : new Date(values.dateRegister),
            placeBusinessRegistration: values.placeBusinessRegistration || values.placeRegister,
            cooperationUnit: values.cooperationUnit,
            cooperationContent: values.cooperationContent,
            cooperationTimeStart: new Date(values.cooperationTime[0]),
            cooperationTimeEnd: new Date(values.cooperationTime[1]),
            projectName: values.projectName,
            verificationResult: values.verificationResult,
            relatedDocuments: values.relatedDocuments,
            requestLetter: values.requestLetter,
            requestDate: new Date(values.requestDate),
            verificationUnit: values.verificationUnit,
            replyLetter: new Date(values.replyLetter),
            replyLetterNumber: values.replyLetterNumber,
            replyLetterContent: values.replyLetterContent,
            note: values.note || '',
            status: 'Đã tạo'
        }

        await apiCoopration.changeStatus(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Phê duyệt thành công',
                    description: 'Phê duyệt thông tin đối tác thành công!'
                });
                getAllRequestTmp(searchValue)
                getAllRequest(searchValue);
                setIsOpenModalView(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const exportToExcel = async () => {

        // Mở 1 modal trong đó cho nhận tên file, khi oke sẽ tạo file excel

        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        // const titleRow = worksheet.addRow([`${fileName} (tính đến ${formattedDate})`]);
        const titleRow = worksheet.addRow([`${fileName}`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:U1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3');
        worksheet.mergeCells('B2:B3');
        worksheet.mergeCells('C2:C3');
        worksheet.mergeCells('D2:D3');
        worksheet.mergeCells('E2:E3');
        worksheet.mergeCells('F2:F3');
        worksheet.mergeCells('G2:G3');
        worksheet.mergeCells('H2:H3');
        worksheet.mergeCells('I2:I3');
        worksheet.mergeCells('J2:J3');
        worksheet.mergeCells('K2:K3');
        worksheet.mergeCells('L2:L3');
        worksheet.mergeCells('M2:M3');
        worksheet.mergeCells('N2:N3');
        worksheet.mergeCells('O2:O3');
        worksheet.mergeCells('P2:P3');
        worksheet.mergeCells('Q2:Q3');
        worksheet.mergeCells('R2:R3');
        worksheet.mergeCells('S2:S3');
        worksheet.mergeCells('T2:T3');
        worksheet.mergeCells('U2:U3');

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Số đăng ký thành lập doanh nghiệp',
            'Ngày cấp giấy thành lập doanh nghiệp',
            'Nơi cấp giấy thành lập doanh nghiệp',
            'Số đăng ký kinh doanh',
            'Ngày cấp giấy đăng ký kinh doanh',
            'Nơi cấp giấy đăng ký kinh doanh',
            'Tên doanh nghiệp/ công ty',
            'Nội dung hợp tác',
            'Thời gian hợp tác',
            'Tên chương trình, dự án',
            'Kết quả thẩm định/xác minh',
            'Các giấy tờ có liên quan đến công ty',
            'Số công văn đề nghị/xác minh',
            'Ngày công văn đề nghị/xác minh',
            'Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)',
            'Số công văn trả lời',
            'Ngày công văn trả lời',
            'Nội dung công văn trả lời',
            'Ghi chú',
            'Trạng thái'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'registerNumber', width: 20 },
            { key: 'dateRegister', width: 20 },
            { key: 'placeRegister', width: 20 },
            { key: 'businessRegistrationNumber', width: 20 },
            { key: 'dateBusinessRegistration', width: 20 },
            { key: 'placeBusinessRegistration', width: 20 },
            { key: 'cooperationUnit', width: 20 },
            { key: 'cooperationContent', width: 75 },
            { key: 'cooperationTime', width: 20 },
            { key: 'projectName', width: 20 },
            { key: 'verificationResult', width: 20 },
            { key: 'relatedDocuments', width: 20 },
            { key: 'requestLetter', width: 20 },
            { key: 'requestDate', width: 20 },
            { key: 'verificationUnit', width: 20 },
            { key: 'replyLetter', width: 20 },
            { key: 'replyLetterNumber', width: 20 },
            { key: 'replyLetterContent', width: 20 },
            { key: 'note', width: 20 },
            { key: 'status', width: 20 }
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        lstRequestTmp.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: index + 1 || '',
                registerNumber: item.registerNumber || '',
                dateRegister: moment(item.dateRegister).format('DD/MM/YYYY') || '',
                placeRegister: item.placeRegister || '',
                businessRegistrationNumber: item.businessRegistrationNumber || '',
                dateBusinessRegistration: moment(item.dateBusinessRegistration).format('DD/MM/YYYY') || '',
                placeBusinessRegistration: item.placeBusinessRegistration || '',
                cooperationUnit: item.cooperationUnit || '',
                cooperationContent: item.cooperationContent || '',
                cooperationTime: `${moment(item.cooperationTimeStart).format('DD/MM/YYYY')} - ${moment(item.cooperationTimeEnd).format('DD/MM/YYYY')}` || '',
                projectName: item.projectName || '',
                verificationResult: item.verificationResult || '',
                relatedDocuments: item.relatedDocuments || '',
                requestLetter: item.requestLetter || '',
                requestDate: moment(item.requestDate).format('DD/MM/YYYY') || '',
                verificationUnit: item.verificationUnit || '',
                replyLetter: moment(item.replyLetter).format('DD/MM/YYYY') || '',
                replyLetterNumber: item.replyLetterNumber || '',
                replyLetterContent: item.replyLetterContent || '',
                note: item.note || 'Không có ghi chú',
                status: item.status || ''
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const localeTable = {
        emptyText: 'Không có dữ liệu đối tác'
    }

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <Breadcrumb
                items={[
                    {
                        title: 'Lĩnh vực kinh tế',
                    },

                    {
                        title: 'Thẩm định đối tác',
                    },
                ]}

            />

            <div className='title-home'>
                <h2>Thẩm định đối tác</h2>
            </div>

            <div className="filter-contailer">
                <div className="lst-filter">
                    <div className='lst-filter-item'>
                        <div className="filter-item">
                            <div className='label'>Tên doanh nghiệp/ công ty: </div>
                            <Input
                                allowClear
                                placeholder='Nhập tên doanh nghiệp/ công ty'
                                onChange={(e) => setSearchValue({ ...searchValue, cooperationUnit: e.target.value })}
                            />
                        </div>
                    </div>

                </div>
                <div className="lst-btn-filter">
                    {/* <Button type='default'>Làm mới</Button> */}
                    <Button type='primary'>Tìm kiếm</Button>
                </div>

            </div >
            <div className="table-request">
                <div className="title-table">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <h2>Danh sách đối tác</h2>
                        {
                            localStorage.getItem('role') === 'admin' &&
                            <div className="record-info">
                                <span>{numberNewRecord}</span>  đối tác mới - <span>{numberUpdateRecord}</span> đối tác chờ phê duyệt
                            </div>

                        }
                    </div>
                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>
                {
                    localStorage.getItem('role') === 'admin' ? (
                        <Table columns={columnsAdmin} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} rowClassName={(record) => record.status === 'Chờ phê duyệt' ? 'pending-approval' : ''} />
                    ) : (
                        <Table columns={columns} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} />
                    )}

                <div className='button-excel'>
                    <Button disabled={(lstRequestTmp && lstRequestTmp.length > 0) ? false : true} onClick={() => setIsOpenModalFile(true)} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                </div>
            </div>

            <Modal
                title="Thêm mới đối tác"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
                style={{
                    top: 20
                }}
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số đăng ký thành lập doanh nghiệp'
                                name='registerNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số đăng ký thành lập doanh nghiệp!' }]}
                            >
                                <Input placeholder='Nhập số đăng ký thành lập doanh nghiệp' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Ngày cấp giấy thành lập doanh nghiệp'
                                name='dateRegister'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày cấp giấy thành lập doanh nghiệp!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày cấp giấy thành lập doanh nghiệp'
                                    disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Nơi cấp giấy thành lập doanh nghiệp'
                                name='placeRegister'
                                rules={[{ required: true, message: 'Vui lòng nhập nơi cấp giấy thành lập doanh nghiệp!' }]}
                            >
                                <Input placeholder='Nhập nơi cấp giấy thành lập doanh nghiệp' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số đăng ký kinh doanh'
                                name='businessRegistrationNumber'
                            >
                                <Input placeholder='Nhập số đăng ký kinh doanh' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Ngày cấp giấy đăng ký kinh doanh'
                                name='dateBusinessRegistration'
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày cấp giấy đăng ký kinh doanh'
                                    disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Nơi cấp giấy đăng ký kinh doanh'
                                name='placeBusinessRegistration'
                            >
                                <Input placeholder='Nhập nơi cấp giấy đăng ký kinh doanh' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên doanh nghiệp/ công ty'
                                name='cooperationUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập tên doanh nghiệp/ công ty!' }]}
                            >
                                <Input placeholder='Nhập tên doanh nghiệp/ công ty' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Thời gian hợp tác'
                                name='cooperationTime'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian hợp tác!' }]}
                            >
                                <DatePicker.RangePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label='Các giấy tờ có liên quan đến công ty'
                        name='relatedDocuments'
                        rules={[{ required: true, message: 'Vui lòng nhập các giấy tờ có liên quan đến công ty!' }]}
                    >
                        <Input.TextArea
                            placeholder='Nhập các giấy tờ có liên quan đến công ty'
                            rows={4}
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên chương trình, dự án'
                                name='projectName'
                                rules={[{ required: true, message: 'Vui lòng nhập tên chương trình, dự án!' }]}
                            >
                                <Input placeholder='Nhập tên chương trình, dự án' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Nội dung hợp tác'
                                name='cooperationContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung hợp tác!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung hợp tác'
                                    rows={6}
                                />
                            </Form.Item>

                        </Col>

                    </Row>


                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn đề nghị/xác minh'
                                name='requestLetter'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn đề nghị/xác minh!' }]}
                            >
                                <Input placeholder='Nhập số công văn đề nghị/xác minh' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn đề nghị/xác minh'
                                name='requestDate'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn đề nghị/xác minh!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn đề nghị/xác minh'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)'
                                name='verificationUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)!' }]}
                            >
                                <Input placeholder='Nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)' />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn trả lời'
                                name='replyLetterNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn trả lời!' }]}
                            >
                                <Input placeholder='Nhập số công văn trả lời' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn trả lời'
                                name='replyLetter'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn trả lời!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn trả lời'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Nội dung công văn trả lời'
                                name='replyLetterContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn trả lời!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung công văn trả lời'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label='Kết quả thẩm định/xác minh'
                        name='verificationResult'
                        rules={[{ required: true, message: 'Vui lòng nhập kết quả thẩm định/xác minh!' }]}
                    >
                        <Input placeholder='Nhập kết quả thẩm định/xác minh' />
                    </Form.Item>



                    <Form.Item
                        label='Ghi chú'
                        name='note'
                    >
                        <Input.TextArea placeholder='Nhập ghi chú' />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Sửa thông tin đối tác"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => setIsOpenModalEdit(false)}
                okText='Sửa'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
                style={{
                    top: 20
                }}
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số đăng ký thành lập doanh nghiệp'
                                name='registerNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số đăng ký thành lập doanh nghiệp!' }]}
                            >
                                <Input placeholder='Nhập số đăng ký thành lập doanh nghiệp' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Ngày cấp giấy thành lập doanh nghiệp'
                                name='dateRegister'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày cấp giấy thành lập doanh nghiệp!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày cấp giấy thành lập doanh nghiệp'
                                    disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Nơi cấp giấy thành lập doanh nghiệp'
                                name='placeRegister'
                                rules={[{ required: true, message: 'Vui lòng nhập nơi cấp giấy thành lập doanh nghiệp!' }]}
                            >
                                <Input placeholder='Nhập nơi cấp giấy thành lập doanh nghiệp' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số đăng ký kinh doanh'
                                name='businessRegistrationNumber'
                            >
                                <Input placeholder='Nhập số đăng ký kinh doanh' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Ngày cấp giấy đăng ký kinh doanh'
                                name='dateBusinessRegistration'
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày cấp giấy đăng ký kinh doanh'
                                    disabledDate={(current) => current && current > moment().endOf('day')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Nơi cấp giấy đăng ký kinh doanh'
                                name='placeBusinessRegistration'
                            >
                                <Input placeholder='Nhập nơi cấp giấy đăng ký kinh doanh' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên doanh nghiệp/ công ty'
                                name='cooperationUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập tên doanh nghiệp/ công ty!' }]}
                            >
                                <Input placeholder='Nhập tên doanh nghiệp/ công ty' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Thời gian hợp tác'
                                name='cooperationTime'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian hợp tác!' }]}
                            >
                                <DatePicker.RangePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label='Các giấy tờ có liên quan đến công ty'
                        name='relatedDocuments'
                        rules={[{ required: true, message: 'Vui lòng nhập các giấy tờ có liên quan đến công ty!' }]}
                    >
                        <Input.TextArea
                            placeholder='Nhập các giấy tờ có liên quan đến công ty'
                            rows={4}
                        />
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên chương trình, dự án'
                                name='projectName'
                                rules={[{ required: true, message: 'Vui lòng nhập tên chương trình, dự án!' }]}
                            >
                                <Input placeholder='Nhập tên chương trình, dự án' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Nội dung hợp tác'
                                name='cooperationContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung hợp tác!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung hợp tác'
                                    rows={4}
                                />
                            </Form.Item>

                        </Col>

                    </Row>



                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn đề nghị/xác minh'
                                name='requestLetter'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn đề nghị/xác minh!' }]}
                            >
                                <Input placeholder='Nhập số công văn đề nghị/xác minh' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn đề nghị/xác minh'
                                name='requestDate'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn đề nghị/xác minh!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn đề nghị/xác minh'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)'
                                name='verificationUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)!' }]}
                            >
                                <Input placeholder='Nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)' />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn trả lời'
                                name='replyLetterNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn trả lời!' }]}
                            >
                                <Input placeholder='Nhập số công văn trả lời' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn trả lời'
                                name='replyLetter'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn trả lời!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn trả lời'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Nội dung công văn trả lời'
                                name='replyLetterContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn trả lời!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung công văn trả lời'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label='Kết quả thẩm định/xác minh'
                        name='verificationResult'
                        rules={[{ required: true, message: 'Vui lòng nhập kết quả thẩm định/xác minh!' }]}
                    >
                        <Input placeholder='Nhập kết quả thẩm định/xác minh' />
                    </Form.Item>



                    <Form.Item
                        label='Ghi chú'
                        name='note'
                    >
                        <Input.TextArea placeholder='Nhập ghi chú' />
                    </Form.Item>

                </Form>
            </Modal>

            <Modal
                title="Xem chi tiết đối tác"
                visible={isOpenModalView}
                onOk={form.submit}
                onCancel={() => setIsOpenModalView(false)}
                okText='Phê duyệt'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                <Form
                    form={form}
                    onFinish={handleFinishViewRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số đăng ký thành lập doanh nghiệp') ?
                                    <Form.Item
                                        label='Số đăng ký thành lập doanh nghiệp'
                                        name='registerNumber'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đăng ký thành lập doanh nghiệp!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số đăng ký thành lập doanh nghiệp' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số đăng ký thành lập doanh nghiệp'
                                        name='registerNumber'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đăng ký thành lập doanh nghiệp!' }]}
                                    >
                                        <Input placeholder='Nhập số đăng ký thành lập doanh nghiệp' />
                                    </Form.Item>

                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Ngày cấp giấy thành lập doanh nghiệp') ?
                                    <Form.Item
                                        label='Ngày cấp giấy
                                thành lập doanh nghiệp'
                                        name='dateRegister'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày cấp giấy thành lập doanh nghiệp!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày cấp giấy thành lập doanh nghiệp'
                                            disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày cấp giấy
                                thành lập doanh nghiệp'
                                        name='dateRegister'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày cấp giấy thành lập doanh nghiệp!' }]}
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày cấp giấy thành lập doanh nghiệp'
                                            disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Nơi cấp giấy thành lập doanh nghiệp') ?
                                    <Form.Item
                                        label='Nơi cấp giấy
                                thành lập doanh nghiệp'
                                        name='placeRegister'
                                        rules={[{ required: true, message: 'Vui lòng nhập nơi cấp giấy thành lập doanh nghiệp!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập nơi cấp giấy thành lập doanh nghiệp' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nơi cấp giấy
                                thành lập doanh nghiệp'
                                        name='placeRegister'
                                        rules={[{ required: true, message: 'Vui lòng nhập nơi cấp giấy thành lập doanh nghiệp!' }]}
                                    >
                                        <Input placeholder='Nhập nơi cấp giấy thành lập doanh nghiệp' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số đăng ký kinh doanh') ?
                                    <Form.Item
                                        label='Số đăng ký kinh doanh'
                                        name='businessRegistrationNumber'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số đăng ký kinh doanh' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số đăng ký kinh doanh'
                                        name='businessRegistrationNumber'
                                    >
                                        <Input placeholder='Nhập số đăng ký kinh doanh' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Ngày cấp giấy đăng ký kinh doanh') ?
                                    <Form.Item
                                        label='Ngày cấp giấy đăng ký kinh doanh'
                                        name='dateBusinessRegistration'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày cấp giấy đăng ký kinh doanh'
                                            disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày cấp giấy đăng ký kinh doanh'
                                        name='dateBusinessRegistration'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày cấp giấy đăng ký kinh doanh'
                                            disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Nơi cấp giấy đăng ký kinh doanh') ?
                                    <Form.Item
                                        label='Nơi cấp giấy đăng ký kinh doanh'
                                        name='placeBusinessRegistration'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập nơi cấp giấy đăng ký kinh doanh' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nơi cấp giấy đăng ký kinh doanh'
                                        name='placeBusinessRegistration'
                                    >
                                        <Input placeholder='Nhập nơi cấp giấy đăng ký kinh doanh' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Tên doanh nghiệp/ công ty') ?
                                    <Form.Item
                                        label='Tên doanh nghiệp/ công ty'
                                        name='cooperationUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên doanh nghiệp/ công ty!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập tên doanh nghiệp/ công ty' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tên doanh nghiệp/ công ty'
                                        name='cooperationUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên doanh nghiệp/ công ty!' }]}
                                    >
                                        <Input placeholder='Nhập tên doanh nghiệp/ công ty' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Thời gian hợp tác') ?
                                    <Form.Item
                                        label='Thời gian hợp tác'
                                        name='cooperationTime'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian hợp tác!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker.RangePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Thời gian hợp tác'
                                        name='cooperationTime'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian hợp tác!' }]}
                                    >
                                        <DatePicker.RangePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>


                    {
                        isChangeInfo.includes('Các giấy tờ có liên quan đến công ty') ?
                            <Form.Item
                                label='Các giấy tờ có liên quan đến công ty'
                                name='relatedDocuments'
                                rules={[{ required: true, message: 'Vui lòng nhập các giấy tờ có liên quan đến công ty!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input.TextArea placeholder='Nhập các giấy tờ có liên quan đến công ty' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Các giấy tờ có liên quan đến công ty'
                                name='relatedDocuments'
                                rules={[{ required: true, message: 'Vui lòng nhập các giấy tờ có liên quan đến công ty!' }]}
                            >
                                <Input.TextArea placeholder='Nhập các giấy tờ có liên quan đến công ty' />
                            </Form.Item>
                    }

                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Tên chương trình, dự án') ?
                                    <Form.Item
                                        label='Tên chương trình, dự án'
                                        name='projectName'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên chương trình, dự án!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập tên chương trình, dự án' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tên chương trình, dự án'
                                        name='projectName'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên chương trình, dự án!' }]}
                                    >
                                        <Input placeholder='Nhập tên chương trình, dự án' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Nội dung hợp tác') ?
                                    <Form.Item
                                        label='Nội dung hợp tác'
                                        name='cooperationContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung hợp tác!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung hợp tác'
                                            rows={4}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nội dung hợp tác'
                                        name='cooperationContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung hợp tác!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung hợp tác'
                                            rows={4}
                                        />
                                    </Form.Item>
                            }


                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số công văn đề nghị/xác minh') ?
                                    <Form.Item
                                        label='Số công văn đề nghị/xác minh'
                                        name='requestLetter'
                                        hasFeedback={true}
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn đề nghị/xác minh!' }]}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số công văn đề nghị/xác minh' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số công văn đề nghị/xác minh'
                                        name='requestLetter'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn đề nghị/xác minh!' }]}
                                    >
                                        <Input placeholder='Nhập số công văn đề nghị/xác minh' />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Ngày công văn đề nghị/xác minh') ?
                                    <Form.Item
                                        label='Ngày công văn đề nghị/xác minh'
                                        name='requestDate'
                                        hasFeedback={true}
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn đề nghị/xác minh!' }]}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn đề nghị/xác minh'
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày công văn đề nghị/xác minh'
                                        name='requestDate'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn đề nghị/xác minh!' }]}
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn đề nghị/xác minh'
                                            disabledDate={(current) => current && current > moment().endOf('day')}
                                        />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)') ?
                                    <Form.Item
                                        label='Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)'
                                        name='verificationUnit'
                                        hasFeedback={true}
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)!' }]}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)'
                                        name='verificationUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị phối hợp/ xác minh (công ty/doanh nghiệp)' />
                                    </Form.Item>
                            }
                        </Col>

                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số công văn trả lời') ?
                                    <Form.Item
                                        label='Số công văn trả lời'
                                        name='replyLetterNumber'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn trả lời!' }]}
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số công văn trả lời' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số công văn trả lời'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn trả lời!' }]}
                                        name='replyLetterNumber'
                                    >
                                        <Input placeholder='Nhập số công văn trả lời' />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Ngày công văn trả lời') ?
                                    <Form.Item
                                        label='Ngày công văn trả lời'
                                        name='replyLetter'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn trả lời!' }]}
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn trả lời'
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày công văn trả lời'
                                        name='replyLetter'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày công văn trả lời!' }]}
                                    >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder='Chọn ngày công văn trả lời'
                                        />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Nội dung công văn trả lời') ?
                                    <Form.Item
                                        label='Nội dung công văn trả lời'
                                        name='replyLetterContent'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn trả lời!' }]}
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung công văn trả lời'
                                            rows={4}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nội dung công văn trả lời'
                                        name='replyLetterContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung công văn trả lời!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung công văn trả lời'
                                            rows={4}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    {
                        isChangeInfo.includes('Kết quả thẩm định/xác minh') ?
                            <Form.Item
                                label='Kết quả thẩm định/xác minh'
                                name='verificationResult'
                                rules={[{ required: true, message: 'Vui lòng nhập kết quả thẩm định/xác minh!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập kết quả thẩm định/xác minh' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Kết quả thẩm định/xác minh'
                                name='verificationResult'
                                rules={[{ required: true, message: 'Vui lòng nhập kết quả thẩm định/xác minh!' }]}
                            >
                                <Input placeholder='Nhập kết quả thẩm định/xác minh' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Ghi chú') ?
                            <Form.Item
                                label='Ghi chú'
                                name='note'
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input.TextArea placeholder='Nhập ghi chú' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Ghi chú'
                                name='note'
                            >
                                <Input.TextArea placeholder='Nhập ghi chú' />
                            </Form.Item>
                    }

                </Form>
            </Modal>

            <Modal
                title='Xác nhận xuất file'
                visible={isOpenModalFile}
                onCancel={() => setIsOpenModalFile(false)}
                onOk={formExcel.submit}
                okText='Xuất file'
                cancelText='Hủy'
            >
                <Form
                    layout='vertical'
                    form={formExcel}
                    onFinish={exportToExcel}
                >
                    <Form.Item
                        label='Tên file'
                        name='fileName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                    >
                        <Input placeholder='Nhập tên file' onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
};

export default Editor;