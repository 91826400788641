import { Avatar, Button, Form, Input, Menu, Modal, notification, Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.header.scss';
import { MainState } from '../../MainProvider';
import { UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import Dropdown from 'antd/es/dropdown/dropdown';
import { apiUser } from '../../api/user';

const HeaderComponent: React.FC = () => {

    const [indexActive, setIndexActive] = React.useState(0);
    const navigate = useNavigate();
    const { isAdmin, setIsAdmin } = MainState();
    const [indexAdminActive, setIndexAdminActive] = React.useState(0);
    const [isShowHeader, setIsShowHeader] = React.useState(true);
    const [name, setName] = React.useState('');
    const [form] = Form.useForm();
    const [isOpenModal, setIsOpenModal] = React.useState(false);

    useEffect(() => {
        const role = localStorage.getItem('role');
        setIsAdmin(role === 'admin');

        const username = localStorage.getItem('username');
        if (username) {
            setName(username);
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname === '/trang-chu') {
            setIndexActive(0);
        }
        if (window.location.pathname === '/tong-hop') {
            setIndexActive(1);
        }
        if (window.location.pathname === '/bien-tap') {
            setIndexActive(2);
        }
        if (window.location.pathname === '/cap-nhat') {
            setIndexActive(4);
        }
        if (window.location.pathname === '/phe-duyet') {
            setIndexActive(3);
        }

        if (window.location.pathname === '/quan-ly-bo-phan') {
            setIndexAdminActive(0);
        }

        if (window.location.pathname === '/quan-ly-nguoi-dung') {
            setIndexAdminActive(1);
        }

        console.log(window.location.pathname);
        if (window.location.pathname === '/') {
            setIsShowHeader(false);
        }
        else {
            setIsShowHeader(true);
        }

    }, [window.location.pathname]);

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    }

    const handleSetting = () => {
        setIsOpenModal(true);
        form.resetFields();
    };

    const menu = (
        <Menu >
            <Menu.Item key="setting" icon={<SettingOutlined />} onClick={handleSetting}>
                Thay đổi mật khẩu
            </Menu.Item>

            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Đăng xuất
            </Menu.Item>
        </Menu>
    );

    const handleFinish = (values: any) => {
        const req = {
            id: localStorage.getItem('userId'),
            username: localStorage.getItem('username'),
            password: values.password,
            newPassword: values.newPassword,
        }

        apiUser.changePassword(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Đổi mật khẩu thành công',
                });

                setIsOpenModal(false);

            })
            .catch((err) => {
                console.log(err);
                if (err?.response?.data?.message === 'Invalid password') {
                    notification.error({
                        message: 'Mật khẩu cũ không chính xác',
                        description: 'Vui lòng kiểm tra lại mật khẩu cũ',
                    });
                }

                if (err?.response?.data?.message === 'User not found') {
                    notification.error({
                        message: 'Người dùng không tồn tại',
                        description: 'Vui lòng kiểm tra lại thông tin',
                    });
                }
            });

    }

    return (
        <>
            {
                isShowHeader &&
                <div className='main-header'>
                    <div className="main-header-left">
                        <div className='content'>
                            Hệ thống quản lý nghiệp vụ
                        </div>
                    </div>

                    <div className="main-header-right">
                        <Dropdown overlay={menu} trigger={['hover']}>
                            <div className='avatar'>
                                <Avatar
                                    style={{ backgroundColor: '#87d068' }}
                                    icon={<UserOutlined />}
                                // className="avatar"
                                />
                                <span className="name">{name}</span>
                            </div>
                        </Dropdown>
                    </div>

                    <Modal
                        title='Đặt lại mật khẩu'
                        visible={isOpenModal}
                        onOk={() => {
                            form.submit();
                            setIsOpenModal(false);
                        }}
                        onCancel={() => setIsOpenModal(false)}
                        okText='Đồng ý'
                        cancelText='Hủy'
                    >
                        <Form
                            name="resetPasswordForm"
                            form={form}
                            onFinish={handleFinish}
                            layout='vertical'
                            className='form-login'
                        >
                            <Form.Item
                                label="Mật khẩu cũ"
                                name="password"
                                className='form-item'
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập mật khẩu cũ!"
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder='Nhập mật khẩu cũ'
                                />
                            </Form.Item>
                            <Form.Item
                                label="Mật khẩu mới"
                                name="newPassword"
                                className='form-item'
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập mật khẩu mới!"
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder='Nhập mật khẩu mới'
                                />
                            </Form.Item>
                        </Form>
                    </Modal>

                    {/* {
                            isAdmin ?
                                <div className="main-header-right">
                                    <div className={`item-navbar ${indexAdminActive === 0 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexAdminActive(0);
                                            navigate('/quan-ly-bo-phan');
                                        }}
                                    >
                                        Quản lý bộ phận
                                    </div>

                                    <div className={`item-navbar ${indexAdminActive === 1 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexAdminActive(1);
                                            navigate('/quan-ly-nguoi-dung');
                                        }}
                                    >
                                        Quản lý người dùng
                                    </div>
                                    <Button
                                        type='primary'
                                        className='btn-logout'
                                        onClick={handleLogout}

                                    >
                                        Đăng xuất
                                    </Button>
                                </div>
                                :
                                <div className="main-header-right">
                                    <div className={`item-navbar ${indexActive === 0 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexActive(0);
                                            navigate('/trang-chu');
                                        }}
                                    >
                                        Danh sách kiến nghị
                                    </div>

                                    <div className={`item-navbar ${indexActive === 2 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexActive(2);
                                            navigate('/bien-tap');
                                        }}
                                    >
                                        Biên tập
                                    </div>
                                    <div className={`item-navbar ${indexActive === 1 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexActive(1);
                                            navigate('/tong-hop');
                                        }}
                                    >
                                        Tổng hợp
                                    </div>

                                    <div className={`item-navbar ${indexActive === 4 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexActive(4);
                                            navigate('/cap-nhat');
                                        }}
                                    >
                                        Cập nhật kết quả
                                    </div>
                                    <div className={`item-navbar ${indexActive === 3 ? 'active' : ''}`}
                                        onClick={() => {
                                            setIndexActive(3);
                                            navigate('/phe-duyet');
                                        }}
                                    >
                                        Phê duyệt
                                    </div>

                                    <Button
                                        type='primary'
                                        className='btn-logout'
                                        onClick={handleLogout}

                                    >
                                        Đăng xuất
                                    </Button>
                                </div>
                        } */}
                </div>
            }
        </>
    );
};

export default HeaderComponent;