import React, { useEffect, useState } from 'react';
import { Table, Button, notification } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './manage_user_history.scss';
import { apiUser } from '../../api/user';
import { MainState } from '../../MainProvider';

const ManageUserHistory: React.FC = () => {
    const [historyData, setHistoryData] = useState<any[]>([]);
    const [userInfo, setUserInfo] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { userSelected, isCollapsed } = MainState();
    const [widthHome, setWidthHome] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        const { username, email, role, region, id } = userSelected;
        setUserInfo({ username, email, role, region, id });
        getUserHistoryById(id);
    }, [location]);

    useEffect(() => {
        if (!userSelected) {
            navigate('/user-management');
        }
    }, [userSelected, navigate]);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    const historyColumns = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            render: (text: any, record: any, index: number) => <div>{index + 1}</div>,
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            width: 200,
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            width: 400,
        },
        {
            title: 'Thời gian xảy ra',
            dataIndex: 'time',
            key: 'time',
            width: 200,
            render: (text: any) => {
                return <div>{new Date(text).toLocaleString('vi-VN')}</div>
            }
        },
    ];

    const getUserHistoryById = async (id: any) => {
        await apiUser.getUserHistory(id, localStorage.getItem('token'))
            .then((res) => {
                console.log(res);
                // Sort the history data by time in descending order
                const sortedHistoryData = res.data.data.sort((a: any, b: any) => {
                    return new Date(b.time).getTime() - new Date(a.time).getTime();
                });
                setHistoryData(sortedHistoryData);

                if (sortedHistoryData.length === 0) {
                    notification.info({
                        message: 'Không có lịch sử hoạt động',
                        description: 'Hiện tại người dùng này chưa có lịch sử hoạt động.',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handlePagination = {
        pageSize: pageSize,
        total: historyData.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} hành động`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " hành động / trang" }
    }

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <div className='title-home'>
                <h2>Lịch sử hoạt động người dùng</h2>
            </div>

            {userInfo && (
                <div className="user-info-container">
                    <h3>Thông tin người dùng</h3>
                    <div className="user-info-grid">
                        <div className="info-item">
                            <span className="label">Tên đăng nhập:</span>
                            <span className="value">{userInfo.username}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Email:</span>
                            <span className="value">{userInfo.email}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Loại tài khoản:</span>
                            <span className="value">{userInfo.role}</span>
                        </div>
                        <div className="info-item">
                            <span className="label">Châu lục:</span>
                            <span className="value">{userInfo.region}</span>
                        </div>
                    </div>
                </div>
            )}

            <div className="table-request">
                <div className="title-table">
                    <h2>Danh sách hoạt động</h2>
                    <Button type='primary' onClick={() => navigate('/user-management')}>
                        <ArrowLeftOutlined /> Quay lại trang quản lý người dùng
                    </Button>
                </div>

                <Table
                    columns={historyColumns}
                    dataSource={historyData}
                    pagination={handlePagination}
                    scroll={{ y: window.innerWidth > 1900 ? 400 : Math.max(200, window.innerWidth / 4.75) }}
                />
            </div>
        </div>
    );
};

export default ManageUserHistory;
