import React from 'react'
import Header from './components/header/header'
import { Outlet } from 'react-router-dom'
import SiderComponent from './components/sider/Sider'
import { Layout } from 'antd'
import { MainState } from './MainProvider'

const LayoutComponent = () => {
    const { isLogin, setIsLogin, isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser } = MainState();

    return (
        <Layout>

            {
                isLogin &&
                <Header />
            }
            <Layout>
                {
                    isLogin &&
                    <SiderComponent />
                }
                <Outlet />
            </Layout>
        </Layout>
    )
}

export default LayoutComponent