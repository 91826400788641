import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import ResetPassword from '../../asset/images/reset-password-1.svg';
import { Button, Form, Input, Modal, notification, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { apiUser } from '../../api/user';
import { MainState } from '../../MainProvider';
import { useNavigate } from 'react-router-dom';

const ManageUser: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed, userSelected, setUserSelected } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>({}); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(0);
    const [totalUser, setTotalUser] = useState(0); // Tổng số người dùng
    const [searchValue, setSearchValue] = useState<any>({
        username: '',
        email: '',
        role: '',
        region: ''
    }); // Giá trị tìm kiếm
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const navigate = useNavigate();


    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            width: 150,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: 200,
        },
        {
            title: 'Loại tài khoản',
            dataIndex: 'role',
            width: 150,
        },
        {
            title: 'Châu lục',
            dataIndex: 'region',
            width: 150,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: 100,
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div className='icon-table' onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div className='icon-table' onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Lịch sử hoạt động'>
                        <div className='icon-table' onClick={() => handleViewHistory(record)}>
                            <HistoryOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Đặt lại mật khẩu'>
                        <div className='icon-table' onClick={() => handleResetRequest(record)}>
                            <img src={ResetPassword} alt='reset-password' width={14} height={14} />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]

    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        // getAllUser(searchValue);
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log(searchValue);
        getAllUser(searchValue);
    }, [searchValue]);

    const handleResetRequest = (record: any) => {

        const req = {
            username: record.username,
            password: '123456'
        }

        Modal.confirm({
            title: 'Đặt lại mật khẩu',
            content: 'Bạn có chắc chắn muốn đặt lại mật khẩu cho người dùng này không? Mật khẩu sẽ được đặt lại về mặc định là 123456',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiUser.resetPassword(req)
                    .then((res) => {
                        console.log(res.data);
                        notification.success({
                            message: 'Đặt lại mật khẩu thành công!',
                            description: 'Đặt lại mật khẩu cho người dùng thành công!',
                            duration: 3,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err?.response?.data?.message === 'User not found') {
                            notification.error({
                                message: 'Người dùng không tồn tại!',
                                description: 'Vui lòng kiểm tra lại thông tin người dùng!',
                                duration: 3,
                            });
                        }
                    });
            },
        });
    }

    const getAllUser = async (searchValue: any) => {
        await apiUser.getAll(localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data.data);

                let lst = res.data.data.users.filter((item: any) => item.role !== 'superadmin');

                if (searchValue.username) {
                    lst = lst.filter((item: any) => item.username.toLowerCase().includes(searchValue.username.toLowerCase()));
                }

                if (searchValue.email) {
                    lst = lst.filter((item: any) => item.email.toLowerCase().includes(searchValue.email.toLowerCase()));
                }

                if (searchValue.role) {
                    lst = lst.filter((item: any) => item.role === searchValue.role);
                }

                if (searchValue.region) {
                    lst = lst.filter((item: any) => item.region === searchValue.region);
                }

                setLstUser(lst);
                setTotalUser(lst.length);

            })
            .catch((err) => {
                console.log(err);
            });
    }

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin ý kiến',
            content: 'Bạn có chắc chắn muốn xóa thông tin ý kiến này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiUser.deleteUser(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);
                        if (res.data.data !== 'User deleted') {
                            notification.error({
                                message: 'Xóa thất bại!',
                                description: res.data.message,
                                duration: 3,
                            });
                        }
                        else {
                            notification.success({
                                message: 'Xóa thành công!',
                                description: 'Xóa người dùng thành công!',
                                duration: 3,
                            });
                        }

                        getAllUser(searchValue);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);


        formEdit.setFieldsValue({
            username: record.username,
            email: record.email,
            role: record.role,
            region: record.region
        });

    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            username: values.username,
            email: values.email,
            role: values.role,
            region: values.region,
        }

        await apiUser.updateUser(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Sửa thành công!',
                    description: 'Sửa thông tin người dùng thành công!',
                });

                setIsOpenModalEdit(false);
                getAllUser(searchValue);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'Username already exists') {
                    notification.error({
                        message: 'Tên đăng nhập đã tồn tại!',
                        description: 'Vui lòng chọn tên đăng nhập khác!',
                    });
                }

                if (err?.response?.data?.message === 'Email already exists') {
                    notification.error({
                        message: 'Email đã tồn tại!',
                        description: 'Vui lòng chọn email khác!',
                    });
                }
            });
    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} ý kiến`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " ý kiến / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {
        console.log(values);
        const req = {
            username: values.username,
            email: values.email,
            password: values.password,
            role: values.role,
            region: values.region,
        }

        await apiUser.createUser(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Thêm mới thành công!',
                    description: 'Thêm mới thông tin người dùng thành công!',
                });

                setIsOpenModalAdd(false);
                getAllUser(searchValue);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'Username already exists') {
                    notification.error({
                        message: 'Tên đăng nhập đã tồn tại!',
                        description: 'Vui lòng chọn tên đăng nhập khác!',
                    });
                }

                if (err?.response?.data?.message === 'Email already exists') {
                    notification.error({
                        message: 'Email đã tồn tại!',
                        description: 'Vui lòng chọn email khác!',
                    });
                }
            });
    }

    const handleSeachUser = async () => {

    }

    const handleViewHistory = (record: any) => {
        console.log(record);

        setUserSelected(record);
        navigate(`/user-management-history`);
    };

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <div className='title-home'>
                <h2>Quản lý người dùng</h2>
            </div>

            <div className="filter-contailer">
                <div className="lst-filter">
                    <div className='lst-filter-item'>

                        <div className="filter-item">
                            <div className='label'>Tên người dùng: </div>
                            <Input
                                allowClear
                                placeholder='Nhập tên người dùng'
                                onChange={(e) => setSearchValue({ ...searchValue, username: e.target.value })}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Email: </div>
                            <Input
                                allowClear
                                placeholder='Nhập email'
                                onChange={(e) => setSearchValue({ ...searchValue, email: e.target.value })}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Loại tài khoản: </div>
                            <Select
                                allowClear
                                placeholder='Chọn loại tài khoản'
                                onChange={(value) => setSearchValue({ ...searchValue, role: value })}
                            >
                                <Select.Option value='Nhân viên'>Nhân viên</Select.Option>
                                <Select.Option value='Trưởng phòng'>Trưởng phòng</Select.Option>
                            </Select>
                        </div>

                        <div className="filter-item">
                            <div className='label'>Châu lục: </div>
                            <Select
                                allowClear
                                placeholder='Chọn châu lục'
                                onChange={(value) => setSearchValue({ ...searchValue, region: value })}
                            >
                                <Select.Option value='Châu Á'>Châu Á</Select.Option>
                                <Select.Option value='Châu Âu'>Châu Âu</Select.Option>
                                <Select.Option value='Châu Phi'>Châu Phi</Select.Option>
                                <Select.Option value='Châu Mỹ'>Châu Mỹ</Select.Option>
                                <Select.Option value='Châu Úc'>Châu Úc</Select.Option>
                                <Select.Option value='Châu Nam Cực'>Châu Nam Cực</Select.Option>
                            </Select>
                        </div>
                    </div>

                </div>
                <div className="lst-btn-filter">
                    {/* <Button type='default' onClick={
                        () => {
                            setSearchValue({
                                username: '',
                                email: '',
                                role: '',
                                region: ''
                            })
                        }
                    }>Làm mới</Button> */}
                    {/* <Button type='primary' onClick={handleSeachUser}>Tìm kiếm</Button> */}
                </div>

            </div >
            <div className="table-request">
                <div className="title-table">
                    <h2>Danh sách người dùng</h2>
                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>

                <Table columns={columns} dataSource={lstUser} pagination={handlePagination} scroll={{ y: 5000 }} />
            </div>

            <Modal
                title="Thêm mới người dùng"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={'25%'}
                className='modal-add-expert'
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Form.Item
                        label='Tên đăng nhập'
                        name='username'
                        rules={[
                            { required: true, message: 'Vui lòng nhập tên đăng nhập!' }
                        ]}
                    >
                        <Input placeholder='Nhập tên đăng nhập' />
                    </Form.Item>

                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                            { type: 'email', message: 'Email không hợp lệ', },
                            { required: true, message: 'Vui lòng nhập email!' }
                        ]}
                    >
                        <Input placeholder='Nhập email' />
                    </Form.Item>

                    <Form.Item
                        label='Mật khẩu'
                        name='password'
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                    >
                        <Input.Password placeholder='Nhập mật khẩu' />
                    </Form.Item>

                    <Form.Item
                        label='Nhập lại mật khẩu'
                        name='repassword'
                        rules={[
                            { required: true, message: 'Vui lòng nhập lại mật khẩu!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu xác nhận không đúng!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Nhập lại mật khẩu' />
                    </Form.Item>

                    <Form.Item
                        label='Loại tài khoản'
                        name='role'
                        rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản!' }]}
                    >
                        <Select placeholder='Chọn loại tài khoản'>
                            <Select.Option value='Nhân viên'>Nhân viên</Select.Option>
                            <Select.Option value='Trưởng phòng'>Trưởng phòng</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Châu lục'
                        name='region'
                        rules={[{ required: true, message: 'Vui lòng chọn châu lục!' }]}
                    >
                        <Select placeholder='Chọn châu lục'>
                            <Select.Option value='Châu Á'>Châu Á</Select.Option>
                            <Select.Option value='Châu Âu'>Châu Âu</Select.Option>
                            <Select.Option value='Châu Phi'>Châu Phi</Select.Option>
                            <Select.Option value='Châu Mỹ'>Châu Mỹ</Select.Option>
                            <Select.Option value='Châu Úc'>Châu Úc</Select.Option>
                            <Select.Option value='Châu Nam Cực'>Châu Nam Cực</Select.Option>
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>

            <Modal
                title="Sửa thông tin người dùng"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => setIsOpenModalEdit(false)}
                okText='Sửa'
                cancelText='Hủy'
                width={'25%'}
                className='modal-add-expert'
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >

                    <Form.Item
                        label='Tên đăng nhập'
                        name='username'
                        rules={[
                            { required: true, message: 'Vui lòng nhập tên đăng nhập!' }
                        ]}
                    >
                        <Input placeholder='Nhập tên đăng nhập' />
                    </Form.Item>

                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                            { type: 'email', message: 'Email không hợp lệ', },
                            { required: true, message: 'Vui lòng nhập email!' }
                        ]}
                    >
                        <Input placeholder='Nhập email' />
                    </Form.Item>

                    {/* <Form.Item
                        label='Mật khẩu'
                        name='password'
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                    >
                        <Input.Password placeholder='Nhập mật khẩu' />
                    </Form.Item>

                    <Form.Item
                        label='Nhập lại mật khẩu'
                        name='repassword'
                        rules={[
                            { required: true, message: 'Vui lòng nhập lại mật khẩu!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu xác nhận không đúng!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Nhập lại mật khẩu' />
                    </Form.Item> */}

                    <Form.Item
                        label='Loại tài khoản'
                        name='role'
                        rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản!' }]}
                    >
                        <Select placeholder='Chọn loại tài khoản'>
                            <Select.Option value='Nhân viên'>Nhân viên</Select.Option>
                            <Select.Option value='Trưởng phòng'>Trưởng phòng</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Châu lục'
                        name='region'
                        rules={[{ required: true, message: 'Vui lòng chọn châu lục!' }]}
                    >
                        <Select placeholder='Chọn châu lục'>
                            <Select.Option value='Châu Á'>Châu Á</Select.Option>
                            <Select.Option value='Châu Âu'>Châu Âu</Select.Option>
                            <Select.Option value='Châu Phi'>Châu Phi</Select.Option>
                            <Select.Option value='Châu Mỹ'>Châu Mỹ</Select.Option>
                            <Select.Option value='Châu Úc'>Châu Úc</Select.Option>
                            <Select.Option value='Châu Nam Cực'>Châu Nam Cực</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
};

export default ManageUser;