import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import Home from './page/home/home';
import LoginForm from './page/login/login';
import HeaderComponent from './components/header/header';
import { useEffect, useState } from 'react';
import Editor from './page/editor/editor';
import Update from './page/update/update';
import Approve from './page/approve/approve';
import ManageDepartment from './page/manage_department/manage_department';
import ManageUser from './page/manage_user/manage_user';
import ManageUserHistory from './page/manage_user/manage_user_history';
import Summary from './page/summary/summary';
import axios from 'axios';
import { apiAction } from './api/action';
import { apiCategory } from './api/category';
import { apiUser } from './api/user';
import { MainState } from './MainProvider';
import LayoutComponent from './LayoutComponent';

// axios.defaults.withCredentials = true;
function App() {
	const {isLogin, setIsLogin, isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser } = MainState();
	const navigate = useNavigate();
	
	useEffect(() => {
		if (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'user' || localStorage.getItem('role') === 'superadmin') {
			setIsLogin(true);
		}
		else {
			setIsLogin(false);
		}

		if(localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'user' && localStorage.getItem('role') !== 'superadmin' && window.location.pathname !== '/') {
			navigate('/');
		}

		if(localStorage.getItem('role') === 'admin' && window.location.pathname === '/') {
			navigate('/expert-management');
		}

		if(localStorage.getItem('role') === 'user' && window.location.pathname === '/') {
			navigate('/expert-management');
		}

		if(localStorage.getItem('role') === 'superadmin' && window.location.pathname === '/') {
			navigate('/user-management');
		}

	}, [window.location.pathname]);

	// Khi load trang, scroll về đầu trang
	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.setAttribute('spellcheck', 'false');
	}, []);

	return (
		<div className="App" >
			<Routes>
				<Route path='/' element={<LayoutComponent />}>
					<Route index element={<LoginForm />} />
					<Route path="/expert-management" element={<Home />} />
					<Route path="/request-management" element={<Editor />} />
					<Route path="/partnet-appraisal" element={<Summary />} />
					<Route path="/project-appraisal" element={<Update />} />
					<Route path="/document-managment" element={<Approve />} />
					<Route path="/user-management" element={<ManageUser />} />
					<Route path="/user-management-history" element={<ManageUserHistory />} />
				</Route>
			</Routes>
		</div >
	);
}

export default App;
