import { DeleteOutlined, EditOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, DatePicker, Form, Input, Modal, notification, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../api/request';
import { MainState } from '../../MainProvider';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// import tmpData from './data';

const Editor: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [lstRequestTmp, setLstRequestTmp] = useState<any[]>([]); // Danh sách kiến nghị tạm
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>({}); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [changeInfo, setChangeInfo] = useState<any>([]); // Thông tin thay đổi
    const [isChangeInfo, setIsChangeInfo] = useState<any>(''); // Nội dung modal xem chi tiết kiến nghị
    const [numberNewRecord, setNumberNewRecord] = useState(0); // Số lượng bản ghi mới
    const [numberUpdateRecord, setNumberUpdateRecord] = useState(0); // Số lượng bản ghi cập nhậtF
    const columns: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Cơ quan, đơn vị đề nghị',
            dataIndex: 'agency',
            width: 250,
        },
        {
            title: 'Nội dung đề nghị tham gia ý kiến',
            dataIndex: 'content',
            width: 1000,
        },
        {
            title: 'Thời gian trả lời',
            dataIndex: 'timeReply',
            width: 150,
            render: (text: any, record: any) => (
                <div>{moment((record.timeReply)).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Thủ trưởng phân công',
            dataIndex: 'assign',
            width: 180,
        },
        {
            title: 'Người thực hiện',
            dataIndex: 'implement',
            width: 150,
        },
        {
            title: 'Số công văn',
            dataIndex: 'officialDocumentNumber',
            width: 150,
        },
        {
            title: 'Số đến công văn',
            dataIndex: 'incomingDocumentNumber',
            width: 220,
        },
        {
            title: 'Ngày đến',
            dataIndex: 'dateDocument',
            width: 150,
            render: (text: any, record: any) => (
                <div>{moment(record.dateDocument).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 150,
            fixed: 'right',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: 100,
            fixed: 'right',
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    {/* <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip> */}
                </div>
            )
        }
    ]
    const columnsAdmin: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Thông tin thay đổi',
            dataIndex: 'changeInfo',
            key: 'changeInfo',
            width: 250,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        <div>{changeInfo[index] || 'Không có thông tin thay đổi'}</div>
                    }
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Xem và phê duyệt'>
                                <div onClick={() => handleViewRequest(record, index)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(0);
    const [fileName, setFileName] = useState(''); // Tên file
    const [isOpenModalFile, setIsOpenModalFile] = useState(false); // Mở modal file
    const [formExcel] = Form.useForm(); // Form import file
    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        getAllRequest();
        getAllRequestTmp();
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log('lstRequestTmp', lstRequestTmp);
        console.log('lstRequest', lstRequest);

        let lstInfo: any[] = [];
        if (lstRequestTmp.length > 0 && lstRequest.length > 0 && lstRequest.length === lstRequestTmp.length) {
            lstRequestTmp.map((item: any, index: any) => {

                let lst = lstRequestTmp.map((item: any, index: any) => {
                    let lstTmp = lstRequest.filter((itemExpert: any) => itemExpert.codeRequest === item.codeRequest);
                    return lstTmp[0];
                })

                let info = '';
                if (item.agency !== lst[index].agency) {
                    info += `Cơ quan-đơn vị đề nghị, `;
                }
                if (item.content !== lst[index].content) {
                    info += `Nội dung đề nghị tham gia ý kiến, `;
                }
                if (item.timeReply !== lst[index].timeReply) {
                    info += `Thời gian trả lời, `;
                }
                if (item.assign !== lst[index].assign) {
                    info += `Thủ trưởng phân công, `;
                }
                if (item.implement !== lst[index].implement) {
                    info += `Người thực hiện`;
                }
                if (item.officialDocumentNumber !== lst[index].officialDocumentNumber) {
                    info += `Số công văn`;
                }
                if (item.incomingDocumentNumber !== lst[index].incomingDocumentNumber) {
                    info += `Số đến công văn`;
                }
                if (item.dateDocument !== lst[index].dateDocument) {
                    info += `Ngày đến`;
                }

                lstInfo.push(info)
            })
        }
        console.log('lstInfo', lstInfo);

        setChangeInfo(lstInfo);

    }, [lstRequestTmp, lstRequest]);

    const getAllRequest = async () => {
        if (localStorage.getItem('role') === 'admin') {
            await apiRequest.getAll(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.requests;
                    setLstRequest(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await apiRequest.getAllByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    setLstRequest(res.data.data.requests);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    };

    const getAllRequestTmp = async () => {
        if (localStorage.getItem('role') === 'admin') {
            await apiRequest.getAllTmp(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);

                    let lst = res.data.data.requests;
                    lst = lst.sort((a: any, b: any) => {
                        if (a.status === 'Chờ phê duyệt' && b.status === 'Đã tạo') return -1;
                        if (a.status === 'Đã tạo' && b.status === 'Chờ phê duyệt') return 1;
                        return 0;
                    })
                    setNumberNewRecord(lst.filter((item: any) => item.status === 'Đã tạo').length);
                    setNumberUpdateRecord(lst.filter((item: any) => item.status === 'Chờ phê duyệt').length);


                    setLstRequestTmp(lst);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            await apiRequest.getAllTmpByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);
                    setLstRequestTmp(res.data.data.requests);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin ý kiến',
            content: 'Bạn có chắc chắn muốn xóa thông tin ý kiến này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiRequest.deleteRequest(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);
                        notification.success({
                            message: 'Xóa thành công!',
                            description: 'Xóa thông tin ý kiến thành công!',
                            duration: 3,
                        });

                        getAllRequestTmp();
                        getAllRequest();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);

        formEdit.setFieldsValue({
            agency: record.agency,
            content: record.content,
            timeReply: dayjs(record.timeReply),
            assign: record.assign,
            implement: record.implement,
            officialDocumentNumber: record.officialDocumentNumber,
            incomingDocumentNumber: record.incomingDocumentNumber,
            dateDocument: dayjs(record.dateDocument),
        });

    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            agency: values.agency,
            content: values.content,
            timeReply: (new Date(values.timeReply)),
            assign: values.assign,
            implement: values.implement,
            officialDocumentNumber: values.officialDocumentNumber,
            incomingDocumentNumber: values.incomingDocumentNumber,
            dateDocument: dayjs(values.dateDocument),
            status: 'Chờ phê duyệt'
        }

        await apiRequest.updateRequest(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Sửa thành công!',
                    description: 'Sửa thông tin ý kiến thành công!',
                    duration: 3,
                });
                getAllRequestTmp();
                getAllRequest();
                setIsOpenModalEdit(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} ý kiến`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " ý kiến / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {

        console.log(values);
        const req = {
            agency: values.agency,
            content: values.content,
            timeReply: (new Date(values.timeReply)),
            assign: values.assign,
            implement: values.implement,
            officialDocumentNumber: values.officialDocumentNumber,
            incomingDocumentNumber: values.incomingDocumentNumber,
            dateDocument: dayjs(values.dateDocument),
            userId: localStorage.getItem('userId'),
            status: 'Đã tạo'
        }

        await apiRequest.createRequest(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Thêm mới thành công!',
                    description: 'Thêm mới ý kiến thành công!',
                    duration: 3,
                });
                getAllRequestTmp();
                getAllRequest();
                setIsOpenModalAdd(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleViewRequest = (record: any, index: any) => {
        console.log(record);
        setIsOpenModalView(true);
        setRequestChoose(record);

        form.setFieldsValue({
            agency: record.agency,
            content: record.content,
            timeReply: dayjs(record.timeReply),
            assign: record.assign,
            implement: record.implement,
            officialDocumentNumber: record.officialDocumentNumber,
            incomingDocumentNumber: record.incomingDocumentNumber,
            dateDocument: dayjs(record.dateDocument),
        });

        if (changeInfo[index]) {
            setIsChangeInfo(changeInfo[index]);
        }
        else {
            setIsChangeInfo('');
        }

    }

    const handleFinishViewRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            agency: values.agency,
            content: values.content,
            timeReply: (new Date(values.timeReply)),
            assign: values.assign,
            implement: values.implement,
            officialDocumentNumber: values.officialDocumentNumber,
            incomingDocumentNumber: values.incomingDocumentNumber,
            dateDocument: dayjs(values.dateDocument),
            status: 'Đã tạo'
        }

        await apiRequest.changeStatus(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Phê duyệt thành công!',
                    description: 'Phê duyệt thông tin ý kiến thành công!',
                    duration: 3,
                });
                getAllRequestTmp();
                getAllRequest();
                setIsOpenModalView(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const exportToExcel = async () => {

        // Mở 1 modal trong đó cho nhận tên file, khi oke sẽ tạo file excel

        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        // const titleRow = worksheet.addRow([`${fileName} (tính đến ${formattedDate})`]);
        const titleRow = worksheet.addRow([`${fileName}`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:J1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3');
        worksheet.mergeCells('B2:B3');
        worksheet.mergeCells('C2:C3');
        worksheet.mergeCells('D2:D3');
        worksheet.mergeCells('E2:E3');
        worksheet.mergeCells('F2:F3');
        worksheet.mergeCells('G2:G3');
        worksheet.mergeCells('H2:H3');
        worksheet.mergeCells('I2:I3');
        worksheet.mergeCells('J2:J3');

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Cơ quan, đơn vị đề nghị',
            'Nội dung đề nghị tham gia ý kiến',
            'Thời gian trả lời',
            'Thủ trưởng phân công',
            'Người thực hiện',
            'Số công văn',
            'Số đến công văn',
            'Ngày đến',
            'Trạng thái'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'agency', width: 20 },
            { key: 'content', width: 75 },
            { key: 'timeReply', width: 20 },
            { key: 'assign', width: 20 },
            { key: 'implement', width: 20 },
            { key: 'officialDocumentNumber', width: 20 },
            { key: 'incomingDocumentNumber', width: 20 },
            { key: 'dateDocument', width: 20 },
            { key: 'status', width: 20 }
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        lstRequestTmp.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: index + 1 || '',
                agency: item.agency || '',
                content: item.content || '',
                timeReply: moment(item.timeReply).format('DD/MM/YYYY') || '',
                assign: item.assign || '',
                implement: item.implement || '',
                officialDocumentNumber: item.officialDocumentNumber || '',
                incomingDocumentNumber: item.incomingDocumentNumber || '',
                dateDocument: moment(item.dateDocument).format('DD/MM/YYYY') || '',
                status: item.status || ''
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const localeTable = {
        emptyText: 'Không có dữ liệu ý kiến',
    }

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <Breadcrumb
                items={[
                    {
                        title: 'Lĩnh vực đối ngoại',
                    },

                    {
                        title: 'Quản lý ý kiến',
                    },
                ]}

            />

            <div className='title-home'>
                <h2>Quản lý ý kiến</h2>
            </div>

            {/* <div className="filter-contailer">
                <div className="lst-filter">
                    <div className="filter-item">
                        <div className='label'>Cơ quan, đơn vị đề nghị: </div>
                        <Input
                            allowClear
                            placeholder='Nhập tên ý kiến'
                        />
                    </div>

                    <div className="filter-item">
                        <div className='label'>Loại giấy tờ: </div>

                        <Select
                            placeholder='Chọn loại giấy tờ'
                            onChange={(value) => setSelectedPaper(value)}
                            allowClear
                        >
                            <Select.Option value={1}>Hộ chiếu</Select.Option>
                            <Select.Option value={2}>VISA</Select.Option>
                            <Select.Option value={3}>Thẻ cư trú</Select.Option>
                            <Select.Option value={4}>CCCD</Select.Option>
                        </Select>

                        {selectedPaper === 1 && <Input placeholder='Nhập số hộ chiếu' allowClear />}
                        {selectedPaper === 2 && <Input placeholder='Nhập số VISA' allowClear />}
                        {selectedPaper === 3 && <Input placeholder='Nhập số thẻ cư trú' allowClear />}
                        {selectedPaper === 4 && <Input placeholder='Nhập số CCCD' allowClear />}
                    </div>

                    <div className="filter-item">
                        <div className='label'>Cơ quan, đơn vị đề xuất: </div>
                        <Input
                            allowClear
                            placeholder='Nhập cơ quan, đơn vị đề xuất'
                        />
                    </div>

                    <div className="filter-item">
                        <div className='label'>Địa điểm: </div>
                        <Input
                            allowClear
                            placeholder='Nhập địa điểm'
                        />
                    </div>

                    <div className="filter-item">
                        <div className='label'>Thời gian làm việc:  </div>
                        <Select
                            placeholder='Chọn thời gian làm việc'
                            allowClear
                            onChange={(value) => setSelectedCategory(value)}
                        >
                            <Select.Option value={1}>Trong tháng</Select.Option>
                            <Select.Option value={2}>Trong quý</Select.Option>
                            <Select.Option value={3}>Trong năm</Select.Option>
                        </Select>


                        {
                            selectedCategory === 1 &&
                            <MonthPicker
                                style={{ width: '100%' }}
                                format='MM/YYYY'
                                placeholder='Chọn tháng làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                            />
                        }
                        {
                            selectedCategory === 2 &&
                            <QuarterPicker
                                style={{ width: '100%' }}
                                format='Q/YYYY'
                                placeholder='Chọn quý làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                            />
                        }
                        {
                            selectedCategory === 3 &&
                            <YearPicker
                                style={{ width: '100%' }}
                                format='YYYY'
                                placeholder='Chọn năm làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                            />
                        }
                    </div>

                    <div className="filter-item">
                        <div className='label'>Quốc tịch: </div>
                        <Select
                            placeholder='Chọn quốc tịch'
                            allowClear
                            showSearch
                            style={{
                                width: 200
                            }}
                        >
                            {
                                lstCountry.map((item, index) => {
                                    return (
                                        <Select.Option key={index} value={item.enName}>{item.vnName}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </div>

                </div>
                <div className="lst-btn-filter">
                    <Button type='default'>Làm mới</Button>
                    <Button type='primary'>Tìm kiếm</Button>
                </div>

            </div > */}
            <div className="table-request">
                <div className="title-table">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <h2> Danh sách ý kiến</h2>
                        {
                            localStorage.getItem('role') === 'admin' &&
                            <div className="record-info">
                                <span>{numberNewRecord}</span> ý kiến mới - <span>{numberUpdateRecord}</span> ý kiến chờ phê duyệt
                            </div>

                        }
                    </div>
                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>
                {
                    localStorage.getItem('role') === 'admin' ? (
                        <Table columns={columnsAdmin} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} rowClassName={(record) => record.status === 'Chờ phê duyệt' ? 'pending-approval' : ''} />
                    ) : (
                        <Table columns={columns} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} />
                    )
                }

                <div className='button-excel'>
                    <Button disabled={(lstRequestTmp && lstRequestTmp.length > 0) ? false : true} onClick={() => setIsOpenModalFile(true)} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                </div>
            </div>

            <Modal
                title="Thêm mới ý kiến"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={600}
                className='modal-add-expert'
                style={{
                    top: 30
                }}
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Form.Item
                        label='Cơ quan, đơn vị đề nghị'
                        name='agency'
                        rules={[{ required: true, message: 'Vui lòng nhập cơ quan, đơn vị đề nghị!' }]}
                    >
                        <Input placeholder='Nhập cơ quan, đơn vị đề nghị' />
                    </Form.Item>
                    <Form.Item
                        label='Số công văn'
                        name='officialDocumentNumber'
                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                    >
                        <Input placeholder='Nhập số công văn' />
                    </Form.Item>

                    <Form.Item
                        label='Số đến công văn'
                        name='incomingDocumentNumber'
                        rules={[{ required: true, message: 'Vui lòng nhập số đến công văn!' }]}
                    >
                        <Input placeholder='Nhập số đến công văn' />
                    </Form.Item>

                    <Form.Item
                        label='Ngày đến'
                        name='dateDocument'
                        rules={[{ required: true, message: 'Vui lòng nhập ngày đến công văn!' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format='DD/MM/YYYY'
                            placeholder='Chọn ngày đến công văn'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Nội dung đề nghị tham gia ý kiến'
                        name='content'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung đề nghị tham gia ý kiến!' }]}
                    >
                        <Input.TextArea
                            placeholder='Nhập nội dung đề nghị tham gia ý kiến'
                            autoSize={{ minRows: 5 }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Thời gian trả lời'
                        name='timeReply'
                        rules={[{ required: true, message: 'Vui lòng nhập thời gian trả lời!' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format='DD/MM/YYYY'
                            placeholder='Chọn thời gian trả lời'
                        />
                    </Form.Item>

                    <Form.Item
                        label='Thủ trưởng phân công'
                        name='assign'
                        rules={[{ required: true, message: 'Vui lòng nhập thủ trưởng phân công!' }]}
                    >
                        <Input placeholder='Nhập thủ trưởng phân công' />
                    </Form.Item>

                    <Form.Item
                        label='Người thực hiện'
                        name='implement'
                        rules={[{ required: true, message: 'Vui lòng nhập người thực hiện!' }]}
                    >
                        <Input placeholder='Nhập người thực hiện' />

                    </Form.Item>



                </Form>
            </Modal>

            <Modal
                title="Sửa ý kiến"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => setIsOpenModalEdit(false)}
                okText='Sửa'
                cancelText='Hủy'
                width={600}
                className='modal-add-expert'
                style={{
                    top: 30
                }}
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >
                    <Form.Item
                        label='Cơ quan, đơn vị đề nghị'
                        name='agency'
                        rules={[{ required: true, message: 'Vui lòng nhập cơ quan, đơn vị đề nghị!' }]}
                    >
                        <Input placeholder='Nhập cơ quan, đơn vị đề nghị' />
                    </Form.Item>
                    <Form.Item
                        label='Số công văn'
                        name='officialDocumentNumber'
                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                    >
                        <Input placeholder='Nhập số công văn' />
                    </Form.Item>

                    <Form.Item
                        label='Số đến công văn'
                        name='incomingDocumentNumber'
                        rules={[{ required: true, message: 'Vui lòng nhập số đến công văn!' }]}
                    >
                        <Input placeholder='Nhập số đến công văn' />
                    </Form.Item>

                    <Form.Item
                        label='Ngày đến'
                        name='dateDocument'
                        rules={[{ required: true, message: 'Vui lòng nhập ngày đến công văn!' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format='DD/MM/YYYY'
                            placeholder='Chọn ngày đến công văn'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Nội dung đề nghị tham gia ý kiến'
                        name='content'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung đề nghị tham gia ý kiến!' }]}
                    >
                        <Input.TextArea
                            placeholder='Nhập nội dung đề nghị tham gia ý kiến'
                            autoSize={{ minRows: 5 }}
                        />
                    </Form.Item>

                    <Form.Item
                        label='Thời gian trả lời'
                        name='timeReply'
                        rules={[{ required: true, message: 'Vui lòng nhập thời gian trả lời!' }]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format='DD/MM/YYYY'
                            placeholder='Chọn thời gian trả lời'
                        />
                    </Form.Item>

                    <Form.Item
                        label='Thủ trưởng phân công'
                        name='assign'
                        rules={[{ required: true, message: 'Vui lòng nhập thủ trưởng phân công!' }]}
                    >
                        <Input placeholder='Nhập thủ trưởng phân công' />
                    </Form.Item>

                    <Form.Item
                        label='Người thực hiện'
                        name='implement'
                        rules={[{ required: true, message: 'Vui lòng nhập người thực hiện!' }]}
                    >
                        <Input placeholder='Nhập người thực hiện' />

                    </Form.Item>



                </Form>
            </Modal>

            <Modal
                title='Thông tin ý kiến'
                visible={isOpenModalView}
                onCancel={() => setIsOpenModalView(false)}
                width={600}
                okText='Phê duyệt'
                cancelText='Hủy'
                onOk={form.submit}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleFinishViewRequest}
                >
                    {
                        isChangeInfo.includes('Cơ quan-đơn vị đề nghị') ?
                            <Form.Item
                                label='Cơ quan, đơn vị đề nghị'
                                name='agency'
                                rules={[{ required: true, message: 'Vui lòng nhập cơ quan, đơn vị đề nghị!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập cơ quan, đơn vị đề nghị' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Cơ quan, đơn vị đề nghị'
                                name='agency'
                                rules={[{ required: true, message: 'Vui lòng nhập cơ quan, đơn vị đề nghị!' }]}
                            >
                                <Input placeholder='Nhập cơ quan, đơn vị đề nghị' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Nội dung đề nghị tham gia ý kiến') ?
                            <Form.Item
                                label='Nội dung đề nghị tham gia ý kiến'
                                name='content'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung đề nghị tham gia ý kiến!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung đề nghị tham gia ý kiến'
                                    autoSize={{ minRows: 5 }}
                                />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Nội dung đề nghị tham gia ý kiến'
                                name='content'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung đề nghị tham gia ý kiến!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung đề nghị tham gia ý kiến'
                                    autoSize={{ minRows: 5 }}
                                />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Thời gian trả lời') ?
                            <Form.Item
                                label='Thời gian trả lời'
                                name='timeReply'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian trả lời!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD/MM/YYYY'
                                    placeholder='Chọn thời gian trả lời'
                                />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Thời gian trả lời'
                                name='timeReply'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian trả lời!' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD/MM/YYYY'
                                    placeholder='Chọn thời gian trả lời'
                                />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Thủ trưởng phân công') ?
                            <Form.Item
                                label='Thủ trưởng phân công'
                                name='assign'
                                rules={[{ required: true, message: 'Vui lòng nhập thủ trưởng phân công!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập thủ trưởng phân công' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Thủ trưởng phân công'
                                name='assign'
                                rules={[{ required: true, message: 'Vui lòng nhập thủ trưởng phân công!' }]}
                            >
                                <Input placeholder='Nhập thủ trưởng phân công' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Người thực hiện') ?
                            <Form.Item
                                label='Người thực hiện'
                                name='implement'
                                rules={[{ required: true, message: 'Vui lòng nhập người thực hiện!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập người thực hiện' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Người thực hiện'
                                name='implement'
                                rules={[{ required: true, message: 'Vui lòng nhập người thực hiện!' }]}
                            >
                                <Input placeholder='Nhập người thực hiện' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Số công văn') ?
                            <Form.Item
                                label='Số công văn'
                                name='officialDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Số công văn'
                                name='officialDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Số đến công văn') ?
                            <Form.Item
                                label='Số đến công văn'
                                name='incomingDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số đến công văn!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input placeholder='Nhập số đến công văn' />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Số đến công văn'
                                name='incomingDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số đến công văn!' }]}
                            >
                                <Input placeholder='Nhập số đến công văn' />
                            </Form.Item>
                    }

                    {
                        isChangeInfo.includes('Ngày đến') ?
                            <Form.Item
                                label='Ngày đến'
                                name='dateDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày đến công văn!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD/MM/YYYY'
                                    placeholder='Chọn ngày đến công văn'
                                />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Ngày đến'
                                name='dateDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày đến công văn!' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD/MM/YYYY'
                                    placeholder='Chọn ngày đến công văn'
                                />
                            </Form.Item>
                    }
                </Form>
            </Modal>

            <Modal
                title='Xác nhận xuất file'
                visible={isOpenModalFile}
                onCancel={() => setIsOpenModalFile(false)}
                onOk={formExcel.submit}
                okText='Xuất file'
                cancelText='Hủy'
            >
                <Form
                    layout='vertical'
                    form={formExcel}
                    onFinish={exportToExcel}
                >
                    <Form.Item
                        label='Tên file'
                        name='fileName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                    >
                        <Input placeholder='Nhập tên file' onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
};

export default Editor;