import { createContext, useContext, useState } from 'react';

const MainContext = createContext();
function MainProvider({ children }) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [lstAction, setLstAction] = useState([]);
    const [lstCategory, setLstCategory] = useState([]);
    const [lstUser, setLstUser] = useState([]);
    const [userId, setUserId] = useState(localStorage.getItem('usernameId'));
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [lstExpert, setLstExpert] = useState([]);
    const [lstExpertTmp, setLstExpertTmp] = useState([]);
    const [userSelected, setUserSelected] = useState('');
    return (
        <MainContext.Provider value={{
            isAdmin,
            setIsAdmin,
            lstAction,
            setLstAction,
            lstCategory,
            setLstCategory,
            lstUser,
            setLstUser,
            userId,
            setUserId,
            isCollapsed,
            setIsCollapsed,
            isLogin,
            setIsLogin,
            lstExpert,
            setLstExpert,
            lstExpertTmp,
            setLstExpertTmp,
            userSelected,
            setUserSelected
        }}>
            {children}
        </MainContext.Provider>
    );
}
export const MainState = () => {
    return useContext(MainContext);
};
export default MainProvider;
