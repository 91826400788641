import axios from 'axios';
import { API_URL } from '../common/enum'

const URL = API_URL.HOST + '/user';

export const apiUser = {
    getAll(token: any) {
        return axios.get(`${URL}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    register(user: any) {
        return axios.post(`${URL}/register`, user);
    },

    login(user: any) {
        return axios.post(`${URL}/login`, user);
    },

    createUser(user: any, token: any) {
        return axios.post(`${URL}`, user,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    getUserById(id: any, token: any) {
        return axios.get(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    updateUser(user: any, token: any) {
        return axios.put(`${URL}/${user.id}`, user,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    deleteUser(id: any, token: any) {
        return axios.delete(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    resetPassword(user: any) {
        return axios.post(`${URL}/reset-password`, user);

    },

    changePassword(user: any, token: any) {
        return axios.put(`${URL}/change-password/${user.id}`, user,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    getUserHistory(id: any, token: any) {
        return axios.get(`${URL}/history/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

};
