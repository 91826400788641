import axios from 'axios';
import { API_URL } from '../common/enum'

const URL = API_URL.HOST + '/project';

export const apiProject = {
    getAllTmp(token: any) {
        return axios.get(`${URL}/tmp`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getAll(token: any) {
        return axios.get(`${URL}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getAllTmpByUserId(userId: any, token: any) {
        return axios.get(`${URL}/tmp/user/${userId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

    },
    getAllByUserId(userId: any, token: any) {
        return axios.get(`${URL}/user/${userId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    createProject(expert: any, token: any) {
        return axios.post(`${URL}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getProjectById(id: any, token: any) {
        return axios.get(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    updateProject(expert: any, token: any) {
        return axios.put(`${URL}/${expert.id}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    deleteProject(id: any, token: any) {
        return axios.delete(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    changeStatus(expert: any, token: any) {
        return axios.put(`${URL}/changeStatus/${expert.id}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    }

};
