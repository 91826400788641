import { DeleteOutlined, EditOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Modal, notification, Row, Select, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiRequest } from '../../api/request';
import { MainState } from '../../MainProvider';
import { apiCoopration } from '../../api/cooperation';
import { apiProject } from '../../api/project';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// import tmpData from './data';


const Update: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [lstRequestTmp, setLstRequestTmp] = useState<any[]>([]); // Danh sách kiến nghị tạm
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>({}); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [changeInfo, setChangeInfo] = useState<any>([]); // Thông tin thay đổi
    const [isChangeInfo, setIsChangeInfo] = useState<any>(''); // Nội dung modal xem chi tiết kiến nghị
    const [numberNewRecord, setNumberNewRecord] = useState(0); // Số lượng bản ghi mới
    const [numberUpdateRecord, setNumberUpdateRecord] = useState(0); // Số lượng bản ghi cập nhật
    const columns: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Tên dự án',
            dataIndex: 'nameProject',
            width: 150,
        },
        {
            title: 'Đơn vị chủ đầu tư',
            dataIndex: 'investorUnit',
            width: 150,
        },
        {
            title: 'Độ mật dự án',
            dataIndex: 'secretLevel',
            width: 150,

        },
        {
            title: 'Tổng số gói thầu của dự án',
            dataIndex: 'totalPackage',
            width: 230,
        },
        {
            title: 'Nhà thầu tham gia dự án',
            dataIndex: 'contractor',
            width: 200,
        },
        {
            title: 'Thời gian, tiến độ hoàn thành',
            dataIndex: 'timeComplete',
            width: 220,
            render: (text: any, record: any) => (
                <div>{moment(record.timeCompleteStart).format('DD/MM/YYYY')} - {moment(record.timeCompleteEnd).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Đơn vị phối hợp',
            dataIndex: 'cooperatingUnit',
            width: 200,
        },
        {
            title: 'Số công văn',
            dataIndex: 'officialDocumentNumber',
            width: 200,
        },
        {
            title: 'Ngày công văn',
            dataIndex: 'officialDocumentDate',
            width: 200,
            render: (text: any, record: any) => (
                <div>{moment(record.officialDocumentDate).format('DD/MM/YYYY')}</div>
            )
        },
        {
            title: 'Tổng mức đầu tư dự án',
            dataIndex: 'totalProjectInvestment',
            width: 200,
        },
        {
            title: 'Nội dung dự án',
            dataIndex: 'projectContent',
            width: 1000,
        },
        {
            title: 'Nội dung tham gia ý kiến',
            dataIndex: 'feedbackContent',
            width: 1000,
        },
        {
            title: 'Điều chỉnh/Đồng ý',
            dataIndex: 'chooseProject',
            width: 200,
        },
        {
            title: 'Nội dung điều chỉnh/đồng ý',
            dataIndex: 'chooseProjectContent',
            width: 1000,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            width: 150,
            render: (text: any, record: any) => (
                <div>{record.note || 'Không có ghi chú'}</div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            width: 130,
            fixed: 'right',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            width: 100,
            fixed: 'right',
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    {/* <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip> */}
                </div>
            )
        }
    ]
    const columnsAdmin: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Thông tin thay đổi',
            dataIndex: 'changeInfo',
            key: 'changeInfo',
            width: 250,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        <div>{changeInfo[index] || 'Không có thông tin thay đổi'}</div>
                    }
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Xem và phê duyệt'>
                                <div onClick={() => handleViewRequest(record, index)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(0);
    const [searchValue, setSearchValue] = useState({
        nameProject: '',
        investorUnit: '',
        secretLevel: '',
        totalPackage: '',
        contractor: '',
        timeComplete: '',
        cooperatingUnit: '',
        officialDocumentNumber: '',
        officialDocumentDate: '',
        totalProjectInvestment: '',
        projectContent: '',
        feedbackContent: '',
        chooseProject: '',
        chooseProjectContent: '',
        note: ''
    }); // Giá trị tìm kiếm

    const [fileName, setFileName] = useState(''); // Tên file
    const [isOpenModalFile, setIsOpenModalFile] = useState(false); // Mở modal file
    const [formExcel] = Form.useForm(); // Form import file
    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        getAllRequest(searchValue);
        getAllRequestTmp(searchValue)
    }, []);

    useEffect(() => {

        getAllRequest(searchValue);
        getAllRequestTmp(searchValue)

    }, [searchValue]);

    useEffect(() => {
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log('lstRequestTmp', lstRequestTmp);
        console.log('lstRequest', lstRequest);

        let lstInfo: any[] = [];
        if (lstRequestTmp.length > 0 && lstRequest.length > 0 && lstRequest.length === lstRequestTmp.length) {
            lstRequestTmp.map((item: any, index: any) => {
                let lst = lstRequestTmp.map((item: any, index: any) => {
                    let lstTmp = lstRequest.filter((itemExpert: any) => itemExpert.codeProject === item.codeProject);
                    return lstTmp[0];
                })

                let info = '';
                if (item.nameProject !== lst[index].nameProject) {
                    info += `Tên dự án, `;
                }
                if (item.investorUnit !== lst[index].investorUnit) {
                    info += `Đơn vị chủ đầu tư, `;
                }
                if (item.secretLevel !== lst[index].secretLevel) {
                    info += `Độ mật dự án, `;
                }
                if (item.totalPackage !== lst[index].totalPackage) {
                    info += `Tổng số gói thầu của dự án, `;
                }
                if (item.contractor !== lst[index].contractor) {
                    info += `Nhà thầu tham gia dự án, `;
                }
                if (item.timeComplete !== lst[index].timeComplete) {
                    info += `Thời gian-tiến độ hoàn thành, `;
                }
                if (item.cooperatingUnit !== lst[index].cooperatingUnit) {
                    info += `Đơn vị phối hợp, `;
                }
                if (item.officialDocumentNumber !== lst[index].officialDocumentNumber) {
                    info += `Số công văn, `;
                }
                if (item.officialDocumentDate !== lst[index].officialDocumentDate) {
                    info += `Ngày công văn, `;
                }
                if (item.totalProjectInvestment !== lst[index].totalProjectInvestment) {
                    info += `Tổng mức đầu tư dự án, `;
                }
                if (item.projectContent !== lst[index].projectContent) {
                    info += `Nội dung dự án, `;
                }
                if (item.feedbackContent !== lst[index].feedbackContent) {
                    info += `Nội dung tham gia ý kiến, `;
                }
                if (item.chooseProject !== lst[index].chooseProject) {
                    info += `Điều chỉnh/Đồng ý, `;
                }
                if (item.chooseProjectContent !== lst[index].chooseProjectContent) {
                    info += `Nội dung điều chỉnh/đồng ý, `;
                }
                if (item.note !== lst[index].note) {
                    info += `Ghi chú, `;
                }


                lstInfo.push(info)
            })
        }
        console.log('lstInfo', lstInfo);

        setChangeInfo(lstInfo);
    }, [lstRequestTmp, lstRequest]);

    const getAllRequest = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiProject.getAll(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);
                    let lstRequest = res.data.data.projects;

                    if (searchValue.nameProject) {
                        lstRequest = lstRequest.filter((item: any) => item.nameProject.toLowerCase().includes(searchValue.nameProject.toLowerCase()));
                    }

                    if (searchValue.investorUnit) {
                        lstRequest = lstRequest.filter((item: any) => item.investorUnit.toLowerCase().includes(searchValue.investorUnit.toLowerCase()));
                    }

                    if (searchValue.secretLevel) {
                        lstRequest = lstRequest.filter((item: any) => item.secretLevel.toLowerCase().includes(searchValue.secretLevel.toLowerCase()));
                    }

                    if (searchValue.totalPackage) {
                        lstRequest = lstRequest.filter((item: any) => item.totalPackage.toLowerCase().includes(searchValue.totalPackage.toLowerCase()));
                    }

                    if (searchValue.contractor) {
                        lstRequest = lstRequest.filter((item: any) => item.contractor.toLowerCase().includes(searchValue.contractor.toLowerCase()));
                    }

                    if (searchValue.timeComplete) {
                        lstRequest = lstRequest.filter((item: any) => item.timeComplete.toLowerCase().includes(searchValue.timeComplete.toLowerCase()));
                    }

                    if (searchValue.cooperatingUnit) {
                        lstRequest = lstRequest.filter((item: any) => item.cooperatingUnit.toLowerCase().includes(searchValue.cooperatingUnit.toLowerCase()));
                    }

                    if (searchValue.officialDocumentNumber) {
                        lstRequest = lstRequest.filter((item: any) => item.officialDocumentNumber.toLowerCase().includes(searchValue.officialDocumentNumber.toLowerCase()));
                    }

                    if (searchValue.officialDocumentDate) {
                        lstRequest = lstRequest.filter((item: any) => item.officialDocumentDate.toLowerCase().includes(searchValue.officialDocumentDate.toLowerCase()));
                    }

                    if (searchValue.totalProjectInvestment) {
                        lstRequest = lstRequest.filter((item: any) => item.totalProjectInvestment.toLowerCase().includes(searchValue.totalProjectInvestment.toLowerCase()));
                    }

                    if (searchValue.projectContent) {
                        lstRequest = lstRequest.filter((item: any) => item.projectContent.toLowerCase().includes(searchValue.projectContent.toLowerCase()));
                    }

                    if (searchValue.feedbackContent) {
                        lstRequest = lstRequest.filter((item: any) => item.feedbackContent.toLowerCase().includes(searchValue.feedbackContent.toLowerCase()));
                    }

                    if (searchValue.chooseProject) {
                        lstRequest = lstRequest.filter((item: any) => item.chooseProject.toLowerCase().includes(searchValue.chooseProject.toLowerCase()));
                    }

                    if (searchValue.chooseProjectContent) {
                        lstRequest = lstRequest.filter((item: any) => item.chooseProjectContent.toLowerCase().includes(searchValue.chooseProjectContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lstRequest = lstRequest.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequest(lstRequest);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await apiProject.getAllByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);
                    let lstRequest = res.data.data.projects;

                    if (searchValue.nameProject) {
                        lstRequest = lstRequest.filter((item: any) => item.nameProject.toLowerCase().includes(searchValue.nameProject.toLowerCase()));
                    }

                    if (searchValue.investorUnit) {
                        lstRequest = lstRequest.filter((item: any) => item.investorUnit.toLowerCase().includes(searchValue.investorUnit.toLowerCase()));
                    }

                    if (searchValue.secretLevel) {
                        lstRequest = lstRequest.filter((item: any) => item.secretLevel.toLowerCase().includes(searchValue.secretLevel.toLowerCase()));
                    }

                    if (searchValue.totalPackage) {
                        lstRequest = lstRequest.filter((item: any) => item.totalPackage.toLowerCase().includes(searchValue.totalPackage.toLowerCase()));
                    }

                    if (searchValue.contractor) {
                        lstRequest = lstRequest.filter((item: any) => item.contractor.toLowerCase().includes(searchValue.contractor.toLowerCase()));
                    }

                    if (searchValue.timeComplete) {
                        lstRequest = lstRequest.filter((item: any) => item.timeComplete.toLowerCase().includes(searchValue.timeComplete.toLowerCase()));
                    }

                    if (searchValue.cooperatingUnit) {
                        lstRequest = lstRequest.filter((item: any) => item.cooperatingUnit.toLowerCase().includes(searchValue.cooperatingUnit.toLowerCase()));
                    }

                    if (searchValue.officialDocumentNumber) {
                        lstRequest = lstRequest.filter((item: any) => item.officialDocumentNumber.toLowerCase().includes(searchValue.officialDocumentNumber.toLowerCase()));
                    }

                    if (searchValue.officialDocumentDate) {
                        lstRequest = lstRequest.filter((item: any) => item.officialDocumentDate.toLowerCase().includes(searchValue.officialDocumentDate.toLowerCase()));
                    }

                    if (searchValue.totalProjectInvestment) {
                        lstRequest = lstRequest.filter((item: any) => item.totalProjectInvestment.toLowerCase().includes(searchValue.totalProjectInvestment.toLowerCase()));
                    }

                    if (searchValue.projectContent) {
                        lstRequest = lstRequest.filter((item: any) => item.projectContent.toLowerCase().includes(searchValue.projectContent.toLowerCase()));
                    }

                    if (searchValue.feedbackContent) {
                        lstRequest = lstRequest.filter((item: any) => item.feedbackContent.toLowerCase().includes(searchValue.feedbackContent.toLowerCase()));
                    }

                    if (searchValue.chooseProject) {
                        lstRequest = lstRequest.filter((item: any) => item.chooseProject.toLowerCase().includes(searchValue.chooseProject.toLowerCase()));
                    }

                    if (searchValue.chooseProjectContent) {
                        lstRequest = lstRequest.filter((item: any) => item.chooseProjectContent.toLowerCase().includes(searchValue.chooseProjectContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lstRequest = lstRequest.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequest(lstRequest);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const getAllRequestTmp = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiProject.getAllTmp(localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);
                    let lstRequestTmp = res.data.data.projects;
                    // Lọc ra status chờ phê duyệt hiển thị đầu tiên và đã tạo sau
                    lstRequestTmp = lstRequestTmp.sort((a: any, b: any) => {
                        if (a.status === 'Chờ phê duyệt' && b.status === 'Đã tạo') return -1;
                        if (a.status === 'Đã tạo' && b.status === 'Chờ phê duyệt') return 1;
                        return 0;
                    })

                    setNumberNewRecord(lstRequestTmp.filter((item: any) => item.status === 'Đã tạo').length);
                    setNumberUpdateRecord(lstRequestTmp.filter((item: any) => item.status === 'Chờ phê duyệt').length);

                    if (searchValue.nameProject) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.nameProject.toLowerCase().includes(searchValue.nameProject.toLowerCase()));
                    }

                    if (searchValue.investorUnit) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.investorUnit.toLowerCase().includes(searchValue.investorUnit.toLowerCase()));
                    }

                    if (searchValue.secretLevel) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.secretLevel.toLowerCase().includes(searchValue.secretLevel.toLowerCase()));
                    }

                    if (searchValue.totalPackage) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.totalPackage.toLowerCase().includes(searchValue.totalPackage.toLowerCase()));
                    }

                    if (searchValue.contractor) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.contractor.toLowerCase().includes(searchValue.contractor.toLowerCase()));
                    }

                    if (searchValue.timeComplete) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.timeComplete.toLowerCase().includes(searchValue.timeComplete.toLowerCase()));
                    }

                    if (searchValue.cooperatingUnit) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.cooperatingUnit.toLowerCase().includes(searchValue.cooperatingUnit.toLowerCase()));
                    }

                    if (searchValue.officialDocumentNumber) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.officialDocumentNumber.toLowerCase().includes(searchValue.officialDocumentNumber.toLowerCase()));
                    }

                    if (searchValue.officialDocumentDate) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.officialDocumentDate.toLowerCase().includes(searchValue.officialDocumentDate.toLowerCase()));
                    }

                    if (searchValue.totalProjectInvestment) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.totalProjectInvestment.toLowerCase().includes(searchValue.totalProjectInvestment.toLowerCase()));
                    }

                    if (searchValue.projectContent) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.projectContent.toLowerCase().includes(searchValue.projectContent.toLowerCase()));
                    }

                    if (searchValue.feedbackContent) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.feedbackContent.toLowerCase().includes(searchValue.feedbackContent.toLowerCase()));
                    }

                    if (searchValue.chooseProject) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.chooseProject.toLowerCase().includes(searchValue.chooseProject.toLowerCase()));
                    }

                    if (searchValue.chooseProjectContent) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.chooseProjectContent.toLowerCase().includes(searchValue.chooseProjectContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequestTmp(lstRequestTmp);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await apiProject.getAllTmpByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log(res.data);
                    let lstRequestTmp = res.data.data.projects;

                    if (searchValue.nameProject) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.nameProject.toLowerCase().includes(searchValue.nameProject.toLowerCase()));
                    }

                    if (searchValue.investorUnit) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.investorUnit.toLowerCase().includes(searchValue.investorUnit.toLowerCase()));
                    }

                    if (searchValue.secretLevel) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.secretLevel.toLowerCase().includes(searchValue.secretLevel.toLowerCase()));
                    }

                    if (searchValue.totalPackage) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.totalPackage.toLowerCase().includes(searchValue.totalPackage.toLowerCase()));
                    }

                    if (searchValue.contractor) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.contractor.toLowerCase().includes(searchValue.contractor.toLowerCase()));
                    }

                    if (searchValue.timeComplete) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.timeComplete.toLowerCase().includes(searchValue.timeComplete.toLowerCase()));
                    }

                    if (searchValue.cooperatingUnit) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.cooperatingUnit.toLowerCase().includes(searchValue.cooperatingUnit.toLowerCase()));
                    }

                    if (searchValue.officialDocumentNumber) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.officialDocumentNumber.toLowerCase().includes(searchValue.officialDocumentNumber.toLowerCase()));
                    }

                    if (searchValue.officialDocumentDate) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.officialDocumentDate.toLowerCase().includes(searchValue.officialDocumentDate.toLowerCase()));
                    }

                    if (searchValue.totalProjectInvestment) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.totalProjectInvestment.toLowerCase().includes(searchValue.totalProjectInvestment.toLowerCase()));
                    }

                    if (searchValue.chooseProject) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.chooseProject.toLowerCase().includes(searchValue.chooseProject.toLowerCase()));
                    }

                    if (searchValue.chooseProjectContent) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.chooseProjectContent.toLowerCase().includes(searchValue.chooseProjectContent.toLowerCase()));
                    }

                    if (searchValue.note) {
                        lstRequestTmp = lstRequestTmp.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()));
                    }

                    setLstRequestTmp(lstRequestTmp);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin dự án',
            content: 'Bạn có chắc chắn muốn xóa thông tin dự án này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiProject.deleteProject(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);
                        notification.success({
                            message: 'Xóa thành công',
                            description: 'Xóa thông tin dự án thành công!'
                        });

                        getAllRequest(searchValue);
                        getAllRequestTmp(searchValue)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);


        formEdit.setFieldsValue({
            nameProject: record.nameProject,
            investorUnit: record.investorUnit,
            secretLevel: record.secretLevel,
            totalPackage: record.totalPackage,
            contractor: record.contractor,
            timeComplete: [dayjs(record.timeCompleteStart), dayjs(record.timeCompleteEnd)],
            cooperatingUnit: record.cooperatingUnit,
            officialDocumentNumber: record.officialDocumentNumber,
            officialDocumentDate: record.officialDocumentDate,
            totalProjectInvestment: record.totalProjectInvestment,
            projectContent: record.projectContent,
            feedbackContent: record.feedbackContent,
            chooseProject: record.chooseProject,
            chooseProjectContent: record.chooseProjectContent,
            note: record.note,
        });

    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);


        const req = {
            id: requestChoose.id,
            nameProject: values.nameProject,
            investorUnit: values.investorUnit,
            secretLevel: values.secretLevel,
            totalPackage: values.totalPackage,
            contractor: values.contractor,
            timeCompleteStart: new Date(values.timeComplete[0]),
            timeCompleteEnd: new Date(values.timeComplete[1]),
            cooperatingUnit: values.cooperatingUnit,
            officialDocumentNumber: values.officialDocumentNumber,
            officialDocumentDate: new Date(values.officialDocumentDate),
            totalProjectInvestment: values.totalProjectInvestment,
            projectContent: values.projectContent,
            feedbackContent: values.feedbackContent,
            chooseProject: values.chooseProject,
            chooseProjectContent: values.chooseProjectContent,
            note: values.note || '',
            status: 'Chờ phê duyệt'
        }

        console.log(req);

        await apiProject.updateProject(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                notification.success({
                    message: 'Sửa thành công',
                    description: 'Sửa thông tin dự án thành công!'
                });

                getAllRequest(searchValue);
                getAllRequestTmp(searchValue)
                setIsOpenModalEdit(false);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} đối tác`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " dự án / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {
        console.log(values);
        const req = {
            nameProject: values.nameProject,
            investorUnit: values.investorUnit,
            secretLevel: values.secretLevel,
            totalPackage: values.totalPackage,
            contractor: values.contractor,
            timeCompleteStart: new Date(values.timeComplete[0]),
            timeCompleteEnd: new Date(values.timeComplete[1]),
            cooperatingUnit: values.cooperatingUnit,
            officialDocumentNumber: values.officialDocumentNumber,
            officialDocumentDate: new Date(values.officialDocumentDate),
            totalProjectInvestment: values.totalProjectInvestment,
            projectContent: values.projectContent,
            feedbackContent: values.feedbackContent,
            chooseProject: values.chooseProject,
            chooseProjectContent: values.chooseProjectContent,
            note: values.note || '',
            userId: localStorage.getItem('userId'),
            status: 'Đã tạo'
        }

        console.log(req);

        await apiProject.createProject(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);
                notification.success({
                    message: 'Thêm mới thành công',
                    description: 'Thêm mới dự án thành công!'
                });

                getAllRequest(searchValue);
                getAllRequestTmp(searchValue)
                setIsOpenModalAdd(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleViewRequest = (record: any, index: any) => {
        console.log(record);
        setIsOpenModalView(true);
        setRequestChoose(record);

        form.setFieldsValue({
            nameProject: record.nameProject,
            investorUnit: record.investorUnit,
            secretLevel: record.secretLevel,
            totalPackage: record.totalPackage,
            contractor: record.contractor,
            timeComplete: [dayjs(record.timeCompleteStart), dayjs(record.timeCompleteEnd)],
            cooperatingUnit: record.cooperatingUnit,
            officialDocumentNumber: record.officialDocumentNumber,
            officialDocumentDate: dayjs(record.officialDocumentDate),
            totalProjectInvestment: record.totalProjectInvestment,
            projectContent: record.projectContent,
            feedbackContent: record.feedbackContent,
            chooseProject: record.chooseProject,
            chooseProjectContent: record.chooseProjectContent,
            note: record.note,
        });

        if (changeInfo[index]) {
            setIsChangeInfo(changeInfo[index]);
        }
        else {
            setIsChangeInfo('');
        }

    }

    const handleFinishViewRequest = async (values: any) => {
        console.log(values);
        const req = {
            id: requestChoose.id,
            nameProject: values.nameProject,
            investorUnit: values.investorUnit,
            secretLevel: values.secretLevel,
            totalPackage: values.totalPackage,
            contractor: values.contractor,
            timeCompleteStart: new Date(values.timeComplete[0]),
            timeCompleteEnd: new Date(values.timeComplete[1]),
            cooperatingUnit: values.cooperatingUnit,
            officialDocumentNumber: values.officialDocumentNumber,
            officialDocumentDate: new Date(values.officialDocumentDate),
            totalProjectInvestment: values.totalProjectInvestment,
            projectContent: values.projectContent,
            feedbackContent: values.feedbackContent,
            chooseProject: values.chooseProject,
            chooseProjectContent: values.chooseProjectContent,
            note: values.note || '',
            status: 'Đã tạo'
        }

        await apiProject.changeStatus(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Phê duyệt thành công',
                    description: 'Phê duyệt thông tin dự án thành công!'
                });
                getAllRequestTmp(searchValue)
                getAllRequest(searchValue);
                setIsOpenModalView(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const exportToExcel = async () => {

        // Mở 1 modal trong đó cho nhận tên file, khi oke sẽ tạo file excel

        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        // const titleRow = worksheet.addRow([`${fileName} (tính đến ${formattedDate})`]);
        const titleRow = worksheet.addRow([`${fileName}`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:Q1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3');
        worksheet.mergeCells('B2:B3');
        worksheet.mergeCells('C2:C3');
        worksheet.mergeCells('D2:D3');
        worksheet.mergeCells('E2:E3');
        worksheet.mergeCells('F2:F3');
        worksheet.mergeCells('G2:G3');
        worksheet.mergeCells('H2:H3');
        worksheet.mergeCells('I2:I3');
        worksheet.mergeCells('J2:J3');
        worksheet.mergeCells('K2:K3');
        worksheet.mergeCells('L2:L3');
        worksheet.mergeCells('M2:M3');
        worksheet.mergeCells('N2:N3');
        worksheet.mergeCells('O2:O3');
        worksheet.mergeCells('P2:P3');
        worksheet.mergeCells('Q2:Q3');

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Tên dự án',
            'Đơn vị chủ đầu tư',
            'Độ mật dự án',
            'Tổng số gói thầu của dự án',
            'Nhà thầu tham gia dự án',
            'Thời gian, tiến độ hoàn thành',
            'Đơn vị phối hợp',
            'Số công văn',
            'Ngày công văn',
            'Tổng mức đầu tư dự án	',
            'Nội dung dự án',
            'Nội dung tham gia ý kiến',
            'Điều chỉnh/Đồng ý',
            'Nội dung điều chỉnh',
            'Ghi chú',
            'Trạng thái'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'nameProject', width: 20 },
            { key: 'investorUnit', width: 20 },
            { key: 'secretLevel', width: 20 },
            { key: 'totalPackage', width: 20 },
            { key: 'contractor', width: 20 },
            { key: 'timeComplete', width: 20 },
            { key: 'cooperatingUnit', width: 20 },
            { key: 'officialDocumentNumber', width: 20 },
            { key: 'officialDocumentDate', width: 20 },
            { key: 'totalProjectInvestment', width: 20 },
            { key: 'projectContent', width: 20 },
            { key: 'feedbackContent', width: 20 },
            { key: 'chooseProject', width: 20 },
            { key: 'chooseProjectContent', width: 20 },
            { key: 'note', width: 20 },
            { key: 'status', width: 20 }
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        lstRequestTmp.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: index + 1,
                nameProject: item.nameProject || '',
                investorUnit: item.investorUnit || '',
                secretLevel: item.secretLevel || '',
                totalPackage: item.totalPackage || '',
                contractor: item.contractor || '',
                timeComplete: `${moment(item.timeCompleteStart).format('DD/MM/YYYY')} - ${moment(item.timeCompleteEnd).format('DD/MM/YYYY')}` || '',
                cooperatingUnit: item.cooperatingUnit || '',
                officialDocumentNumber: item.officialDocumentNumber || '',
                officialDocumentDate: item.officialDocumentDate || '',
                totalProjectInvestment: item.totalProjectInvestment || '',
                projectContent: item.projectContent || '',
                feedbackContent: item.feedbackContent || '',
                chooseProject: item.chooseProject || '',
                chooseProjectContent: item.chooseProjectContent || '',
                note: item.note || 'Không có ghi chú',
                status: item.status || ''
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const localeTable = {
        emptyText: 'Không có dữ liệu dự án'
    }
    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <Breadcrumb
                items={[
                    {
                        title: 'Lĩnh vực kinh tế',
                    },

                    {
                        title: 'Thẩm định dự án',
                    },
                ]}

            />

            <div className='title-home'>
                <h2>Thẩm định dự án</h2>
            </div>

            <div className="filter-contailer">
                <div className="lst-filter">
                    <div className='lst-filter-item'>   
                        <div className="filter-item">
                            <div className='label'>Tên dự án: </div>
                            <Input
                                allowClear
                                placeholder='Nhập tên dự án'
                                onChange={(e) => setSearchValue({ ...searchValue, nameProject: e.target.value })}
                            />
                        </div>
                    </div>

                </div>
                <div className="lst-btn-filter">

                    <Button type='primary'>Tìm kiếm</Button>
                </div>

            </div >
            <div className="table-request">
                <div className="title-table">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <h2>Danh sách dự án</h2>
                        {
                            localStorage.getItem('role') === 'admin' &&
                            <div className="record-info">
                                <span>{numberNewRecord}</span>  dự án mới - <span>{numberUpdateRecord}</span> dự án chờ phê duyệt
                            </div>

                        }
                    </div>
                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>
                {
                    localStorage.getItem('role') === 'admin' ? (
                        <Table columns={columnsAdmin} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} rowClassName={(record) => record.status === 'Chờ phê duyệt' ? 'pending-approval' : ''} />
                    ) : (
                        <Table columns={columns} dataSource={lstRequestTmp} pagination={handlePagination} scroll={{ y: 5000, x: 1000 }} />
                    )}

                <div className='button-excel'>
                    <Button disabled={(lstRequestTmp && lstRequestTmp.length > 0) ? false : true} onClick={() => setIsOpenModalFile(true)} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                </div>
            </div>

            <Modal
                title="Thêm mới dự án"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên dự án'
                                name='nameProject'
                                rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
                            >
                                <Input placeholder='Nhập tên dự án' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Đơn vị chủ đầu tư'
                                name='investorUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị chủ đầu tư!' }]}
                            >
                                <Input placeholder='Nhập đơn vị chủ đầu tư' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label='Độ mật dự án'
                                name='secretLevel'
                                rules={[{ required: true, message: 'Vui lòng nhập độ mật dự án!' }]}
                            >
                                <Select
                                    placeholder='Chọn độ mật dự án'
                                >
                                    <Select.Option value='Không mật'>Không mật</Select.Option>
                                    <Select.Option value='Mật'>Mật</Select.Option>
                                    <Select.Option value='Tối mật'>Tối mật</Select.Option>
                                    <Select.Option value='Tuyệt mật'>Tuyệt mật</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Tổng số gói thầu của dự án'
                                name='totalPackage'
                                rules={[{ required: true, message: 'Vui lòng nhập tổng số gói thầu của dự án!' }]}
                            >
                                <Input placeholder='Nhập tổng số gói thầu của dự án' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Tổng mức đầu tư dự án	'
                                name='totalProjectInvestment'
                                rules={[{ required: true, message: 'Vui lòng nhập tổng mức đầu tư dự án	!' }]}
                            >
                                <Input placeholder='Nhập tổng mức đầu tư dự án	' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Nhà thầu tham gia dự án'
                                name='contractor'
                                rules={[{ required: true, message: 'Vui lòng nhập nhà thầu tham gia dự án!' }]}
                            >
                                <Input placeholder='Nhập nhà thầu tham gia dự án' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label='Thời gian, tiến độ hoàn thành'
                                name='timeComplete'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian, tiến độ hoàn thành!' }]}
                            >
                                <DatePicker.RangePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                label='Ghi chú'
                                name='note'
                            >
                                <Input placeholder='Nhập ghi chú' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Đơn vị phối hợp'
                                name='cooperatingUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp!' }]}
                            >
                                <Input placeholder='Nhập đơn vị phối hợp' />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn'
                                name='officialDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Ngày công văn'
                                name='officialDocumentDate'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày công văn!' }]}
                            >
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder='Chọn ngày công văn'
                                />
                            </Form.Item>
                        </Col>


                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Nội dung dự án'
                                name='projectContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung dự án!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung dự án'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Nội dung tham gia ý kiến'
                                name='feedbackContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung tham gia ý kiến!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung tham gia ý kiến'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Điều chỉnh/Đồng ý'
                                name='chooseProject'
                                rules={[{ required: true, message: 'Vui lòng chọn điều chỉnh/đồng ý!' }]}
                            >
                                <Select
                                    placeholder='Điều chỉnh/Đồng ý'
                                >
                                    <Select.Option value='Điều chỉnh'>Điều chỉnh</Select.Option>
                                    <Select.Option value='Đồng ý'>Đồng ý</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.chooseProject !== currentValues.chooseProject}>
                                {({ getFieldValue }) =>
                                    getFieldValue('chooseProject') === 'Điều chỉnh' ? (
                                        <Form.Item
                                            label='Nội dung điều chỉnh'
                                            name='chooseProjectContent'
                                            rules={[{ required: true, message: 'Vui lòng nhập nội dung điều chỉnh!' }]}>
                                            <Input.TextArea placeholder='Nhập nội dung điều chỉnh' rows={4} />
                                        </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>

            <Modal
                title="Sửa thông tin dự án"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => setIsOpenModalEdit(false)}
                okText='Sửa'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Tên dự án'
                                name='nameProject'
                                rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
                            >
                                <Input placeholder='Nhập tên dự án' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Đơn vị chủ đầu tư'
                                name='investorUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị chủ đầu tư!' }]}
                            >
                                <Input placeholder='Nhập đơn vị chủ đầu tư' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label='Độ mật dự án'
                                name='secretLevel'
                                rules={[{ required: true, message: 'Vui lòng nhập độ mật dự án!' }]}
                            >
                                <Select
                                    placeholder='Chọn độ mật dự án'
                                >
                                    <Select.Option value='Không mật'>Không mật</Select.Option>
                                    <Select.Option value='Mật'>Mật</Select.Option>
                                    <Select.Option value='Tối mật'>Tối mật</Select.Option>
                                    <Select.Option value='Tuyệt mật'>Tuyệt mật</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Tổng số gói thầu của dự án'
                                name='totalPackage'
                                rules={[{ required: true, message: 'Vui lòng nhập tổng số gói thầu của dự án!' }]}
                            >
                                <Input placeholder='Nhập tổng số gói thầu của dự án' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Nhà thầu tham gia dự án'
                                name='contractor'
                                rules={[{ required: true, message: 'Vui lòng nhập nhà thầu tham gia dự án!' }]}
                            >
                                <Input placeholder='Nhập nhà thầu tham gia dự án' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label='Thời gian, tiến độ hoàn thành'
                                name='timeComplete'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian, tiến độ hoàn thành!' }]}
                            >
                                <DatePicker.RangePicker
                                    format='DD/MM/YYYY'
                                    style={{ width: '100%' }}
                                    placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                label='Ghi chú'
                                name='note'
                            >
                                <Input placeholder='Nhập ghi chú' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Đơn vị phối hợp'
                                name='cooperatingUnit'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp!' }]}
                            >
                                <Input placeholder='Nhập đơn vị phối hợp' />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Số công văn'
                                name='officialDocumentNumber'
                                rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                            >
                                <Input placeholder='Nhập số công văn' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Tổng mức đầu tư dự án	'
                                name='totalProjectInvestment'
                                rules={[{ required: true, message: 'Vui lòng nhập tổng mức đầu tư dự án	!' }]}
                            >
                                <Input placeholder='Nhập tổng mức đầu tư dự án	' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label='Nội dung dự án'
                                name='projectContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung dự án!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung dự án'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Nội dung tham gia ý kiến'
                                name='feedbackContent'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung tham gia ý kiến!' }]}
                            >
                                <Input.TextArea
                                    placeholder='Nhập nội dung tham gia ý kiến'
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Điều chỉnh/Đồng ý'
                                name='chooseProject'
                                rules={[{ required: true, message: 'Vui lòng chọn điều chỉnh/đồng ý!' }]}
                            >
                                <Select
                                    placeholder='Điều chỉnh/Đồng ý'
                                >
                                    <Select.Option value='Điều chỉnh'>Điều chỉnh</Select.Option>
                                    <Select.Option value='Đồng ý'>Đồng ý</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Modal>

            <Modal
                title="Xem chi tiết dự án"
                visible={isOpenModalView}
                onOk={form.submit}
                onCancel={() => setIsOpenModalView(false)}
                okText='Phê duyệt'
                cancelText='Hủy'
                width={'95%'}
                className='modal-add-expert'
            >
                <Form
                    form={form}
                    onFinish={handleFinishViewRequest}
                    layout='vertical'
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Tên dự án') ?
                                    <Form.Item
                                        label='Tên dự án'
                                        name='nameProject'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập tên dự án' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tên dự án'
                                        name='nameProject'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên dự án!' }]}
                                    >
                                        <Input placeholder='Nhập tên dự án' />
                                    </Form.Item>

                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Đơn vị chủ đầu tư') ?
                                    <Form.Item
                                        label='Đơn vị chủ đầu tư'
                                        name='investorUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị chủ đầu tư!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập đơn vị chủ đầu tư' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đơn vị chủ đầu tư'
                                        name='investorUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị chủ đầu tư!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị chủ đầu tư' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Độ mật dự án') ?
                                    <Form.Item
                                        label='Độ mật dự án'
                                        name='secretLevel'
                                        rules={[{ required: true, message: 'Vui lòng nhập độ mật dự án!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Select
                                            placeholder='Chọn độ mật dự án'
                                        >
                                            <Select.Option value='Không mật'>Không mật</Select.Option>
                                            <Select.Option value='Mật'>Mật</Select.Option>
                                            <Select.Option value='Tối mật'>Tối mật</Select.Option>
                                            <Select.Option value='Tuyệt mật'>Tuyệt mật</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Độ mật dự án'
                                        name='secretLevel'
                                        rules={[{ required: true, message: 'Vui lòng nhập độ mật dự án!' }]}
                                    >
                                        <Select
                                            placeholder='Chọn độ mật dự án'
                                        >
                                            <Select.Option value='Không mật'>Không mật</Select.Option>
                                            <Select.Option value='Mật'>Mật</Select.Option>
                                            <Select.Option value='Tối mật'>Tối mật</Select.Option>
                                            <Select.Option value='Tuyệt mật'>Tuyệt mật</Select.Option>
                                        </Select>
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Tổng số gói thầu của dự án') ?
                                    <Form.Item
                                        label='Tổng số gói thầu của dự án'
                                        name='totalPackage'
                                        rules={[{ required: true, message: 'Vui lòng nhập tổng số gói thầu của dự án!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập tổng số gói thầu của dự án' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tổng số gói thầu của dự án'
                                        name='totalPackage'
                                        rules={[{ required: true, message: 'Vui lòng nhập tổng số gói thầu của dự án!' }]}
                                    >
                                        <Input placeholder='Nhập tổng số gói thầu của dự án' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Nhà thầu tham gia dự án') ?
                                    <Form.Item
                                        label='Nhà thầu tham gia dự án'
                                        name='contractor'
                                        rules={[{ required: true, message: 'Vui lòng nhập nhà thầu tham gia dự án!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập nhà thầu tham gia dự án' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nhà thầu tham gia dự án'
                                        name='contractor'
                                        rules={[{ required: true, message: 'Vui lòng nhập nhà thầu tham gia dự án!' }]}
                                    >
                                        <Input placeholder='Nhập nhà thầu tham gia dự án' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Thời gian-tiến độ hoàn thành') ?
                                    <Form.Item
                                        label='Thời gian, tiến độ hoàn thành'
                                        name='timeComplete'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian, tiến độ hoàn thành!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker.RangePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Thời gian, tiến độ hoàn thành'
                                        name='timeComplete'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian, tiến độ hoàn thành!' }]}
                                    >
                                        <DatePicker.RangePicker
                                            format='DD/MM/YYYY'
                                            style={{ width: '100%' }}
                                            placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={18}>
                            {
                                isChangeInfo.includes('Ghi chú') ?
                                    <Form.Item
                                        label='Ghi chú'
                                        name='note'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập ghi chú' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ghi chú'
                                        name='note'
                                    >
                                        <Input placeholder='Nhập ghi chú' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Đơn vị phối hợp') ?
                                    <Form.Item
                                        label='Đơn vị phối hợp'
                                        name='cooperatingUnit'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị phối hợp' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đơn vị phối hợp'
                                        name='cooperatingUnit'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị phối hợp!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị phối hợp' />
                                    </Form.Item>
                            }

                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số công văn') ?
                                    <Form.Item
                                        label='Số công văn'
                                        name='officialDocumentNumber'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                                    >
                                        <Input placeholder='Nhập số công văn' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số công văn'
                                        name='officialDocumentNumber'
                                        rules={[{ required: true, message: 'Vui lòng nhập số công văn!' }]}
                                    >
                                        <Input placeholder='Nhập số công văn' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Tổng mức đầu tư dự án	') ?
                                    <Form.Item
                                        label='Tổng mức đầu tư dự án	'
                                        name='totalProjectInvestment'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng nhập tổng mức đầu tư dự án	!' }]}
                                    >
                                        <Input placeholder='Nhập tổng mức đầu tư dự án	' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tổng mức đầu tư dự án	'
                                        name='totalProjectInvestment'
                                        rules={[{ required: true, message: 'Vui lòng nhập tổng mức đầu tư dự án	!' }]}
                                    >
                                        <Input placeholder='Nhập tổng mức đầu tư dự án	' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Nội dung dự án') ?
                                    <Form.Item
                                        label='Nội dung dự án'
                                        name='projectContent'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung dự án!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung dự án'
                                            rows={4}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nội dung dự án'
                                        name='projectContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung dự án!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung dự án'
                                            rows={4}
                                        />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={12}>
                            {
                                isChangeInfo.includes('Nội dung tham gia ý kiến') ?
                                    <Form.Item
                                        label='Nội dung tham gia ý kiến'
                                        name='feedbackContent'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung tham gia ý kiến!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung tham gia ý kiến'
                                            rows={4}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nội dung tham gia ý kiến'
                                        name='feedbackContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung tham gia ý kiến!' }]}
                                    >
                                        <Input.TextArea
                                            placeholder='Nhập nội dung tham gia ý kiến'
                                            rows={4}
                                        />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Điều chỉnh/Đồng ý') ?
                                    <Form.Item
                                        label='Điều chỉnh/Đồng ý'
                                        name='chooseProject'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                        rules={[{ required: true, message: 'Vui lòng chọn điều chỉnh/đồng ý!' }]}
                                    >
                                        <Select
                                            placeholder='Điều chỉnh/Đồng ý'
                                        >
                                            <Select.Option value='Điều chỉnh'>Điều chỉnh</Select.Option>
                                            <Select.Option value='Đồng ý'>Đồng ý</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Điều chỉnh/Đồng ý'
                                        name='chooseProject'
                                        rules={[{ required: true, message: 'Vui lòng chọn điều chỉnh/đồng ý!' }]}
                                    >
                                        <Select
                                            placeholder='Điều chỉnh/Đồng ý'
                                        >
                                            <Select.Option value='Điều chỉnh'>Điều chỉnh</Select.Option>
                                            <Select.Option value='Đồng ý'>Đồng ý</Select.Option>
                                        </Select>
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                </Form>
            </Modal>
            <Modal
                title='Xác nhận xuất file'
                visible={isOpenModalFile}
                onCancel={() => setIsOpenModalFile(false)}
                onOk={formExcel.submit}
                okText='Xuất file'
                cancelText='Hủy'
            >
                <Form
                    layout='vertical'
                    form={formExcel}
                    onFinish={exportToExcel}
                >
                    <Form.Item
                        label='Tên file'
                        name='fileName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                    >
                        <Input placeholder='Nhập tên file' onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>

        </div >
    );
};

export default Update;