import { Breadcrumb, Button, Col, DatePicker, Form, Input, Modal, notification, Popover, Row, Select, Table, Steps, message, Tooltip, TableColumnType, TableColumnsType, Tag } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsThreeDots } from "react-icons/bs";
import { EditOutlined, DeleteOutlined, FormOutlined, DownloadOutlined } from '@ant-design/icons';
import { apiRequest } from '../../api/request';
import { MainState } from '../../MainProvider';
import './styles.home.scss';
import { apiExpert } from '../../api/expert';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// import tmpData from './data';
const lstCountry = [
    {
        "enName": "Saudi Arabia",
        "vnName": "Ả Rập Xê Út"
    },
    {
        "enName": "Afghanistan",
        "vnName": "Afghanistan"
    },
    {
        "enName": "Egypt",
        "vnName": "Ai Cập"
    },
    {
        "enName": "Albania",
        "vnName": "Albania"
    },
    {
        "enName": "Algeria",
        "vnName": "Algeria"
    },
    {
        "enName": "India",
        "vnName": "Ấn Độ"
    },
    {
        "enName": "Andorra",
        "vnName": "Andorra"
    },
    {
        "enName": "Angola",
        "vnName": "Angola"
    },
    {
        "enName": "Anguilla",
        "vnName": "Anguilla"
    },
    {
        "enName": "Antarctica",
        "vnName": "Antarctica"
    },
    {
        "enName": "Antigua and Barbuda",
        "vnName": "Antigua và Barbuda"
    },
    {
        "enName": "Austria",
        "vnName": "Áo"
    },
    {
        "enName": "Argentina",
        "vnName": "Argentina"
    },
    {
        "enName": "Armenia",
        "vnName": "Armenia"
    },
    {
        "enName": "Aruba",
        "vnName": "Aruba"
    },
    {
        "enName": "Azerbaijan",
        "vnName": "Azerbaijan"
    },
    {
        "enName": "Poland",
        "vnName": "Ba Lan"
    },
    {
        "enName": "Bahamas",
        "vnName": "Bahamas"
    },
    {
        "enName": "Bahrain",
        "vnName": "Bahrain"
    },
    {
        "enName": "Bangladesh",
        "vnName": "Bangladesh"
    },
    {
        "enName": "Barbados",
        "vnName": "Barbados"
    },
    {
        "enName": "Belarus",
        "vnName": "Belarus"
    },
    {
        "enName": "Belize",
        "vnName": "Belize"
    },
    {
        "enName": "Benin",
        "vnName": "Benin"
    },
    {
        "enName": "Bermuda",
        "vnName": "Bermuda"
    },
    {
        "enName": "Bhutan",
        "vnName": "Bhutan"
    },
    {
        "enName": "Belgium",
        "vnName": "Bỉ"
    },
    {
        "enName": "Côte d’Ivoire",
        "vnName": "Bờ Biển Ngà"
    },
    {
        "enName": "Portugal",
        "vnName": "Bồ Đào Nha"
    },
    {
        "enName": "Bolivia",
        "vnName": "Bolivia"
    },
    {
        "enName": "Bonaire",
        "vnName": "Bonaire"
    },
    {
        "enName": "Bosnia and Herzegovina",
        "vnName": "Bosnia và Herzegovina"
    },
    {
        "enName": "Botswana",
        "vnName": "Botswana"
    },
    {
        "enName": "Brazil",
        "vnName": "Brazil"
    },
    {
        "enName": "Bulgaria",
        "vnName": "Bulgaria"
    },
    {
        "enName": "Burkina Faso",
        "vnName": "Burkina Faso"
    },
    {
        "enName": "Burundi",
        "vnName": "Burundi"
    },
    {
        "enName": "United States Minor Outlying Islands",
        "vnName": "Các tiểu đảo xa của Hoa Kỳ"
    },
    {
        "enName": "United Arab Emirates",
        "vnName": "Các tiểu vương quốc Ả Rập Thống nhất"
    },
    {
        "enName": "Cameroon",
        "vnName": "Cameroon"
    },
    {
        "enName": "Cambodia",
        "vnName": "Campuchia"
    },
    {
        "enName": "Canada",
        "vnName": "Canada"
    },
    {
        "enName": "Cape Verde",
        "vnName": "Cape Verde"
    },
    {
        "enName": "Chile",
        "vnName": "Chile"
    },
    {
        "enName": "Colombia",
        "vnName": "Colombia"
    },
    {
        "enName": "Comoros",
        "vnName": "Comoros"
    },
    {
        "enName": "Congo",
        "vnName": "Cộng hòa Congo"
    },
    {
        "enName": "Democratic Republic of the Congo",
        "vnName": "Cộng hòa Dân chủ Congo"
    },
    {
        "enName": "Dominican Republic",
        "vnName": "Cộng hòa Dominica"
    },
    {
        "enName": "Costa Rica",
        "vnName": "Costa Rica"
    },
    {
        "enName": "Croatia",
        "vnName": "Croatia"
    },
    {
        "enName": "Cuba",
        "vnName": "Cuba"
    },
    {
        "enName": "Curacao",
        "vnName": "Curacao"
    },
    {
        "enName": "Taiwan",
        "vnName": "Đài Loan "
    },
    {
        "enName": "Denmark",
        "vnName": "Đan Mạch"
    },
    {
        "enName": "Bouvet Island",
        "vnName": "Đảo Bouvet"
    },
    {
        "enName": "Christmas Island",
        "vnName": "Đảo Giáng Sinh"
    },
    {
        "enName": "Guam",
        "vnName": "Đảo Guam"
    },
    {
        "enName": "Heard Island and McDonald Islands",
        "vnName": "Đảo Heard và quần đảo McDonald"
    },
    {
        "enName": "Isle of Man",
        "vnName": "Đảo Man"
    },
    {
        "enName": "Norfolk Island",
        "vnName": "Đảo Norfolk"
    },
    {
        "enName": "Djibouti",
        "vnName": "Djibouti"
    },
    {
        "enName": "Dominica",
        "vnName": "Dominica"
    },
    {
        "enName": "Timor-Leste",
        "vnName": "Đông Timor"
    },
    {
        "enName": "Germany",
        "vnName": "Đức"
    },
    {
        "enName": "Ecuador",
        "vnName": "Ecuador"
    },
    {
        "enName": "El Salvador",
        "vnName": "El Salvador"
    },
    {
        "enName": "Eritrea",
        "vnName": "Eritrea"
    },
    {
        "enName": "Estonia",
        "vnName": "Estonia"
    },
    {
        "enName": "Ethiopia",
        "vnName": "Ethiopia"
    },
    {
        "enName": "Fiji",
        "vnName": "Fiji"
    },
    {
        "enName": "Gabon",
        "vnName": "Gabon"
    },
    {
        "enName": "Gambia",
        "vnName": "Gambia"
    },
    {
        "enName": "Georgia",
        "vnName": "Georgia"
    },
    {
        "enName": "Ghana",
        "vnName": "Ghana"
    },
    {
        "enName": "Gibraltar",
        "vnName": "Gibraltar"
    },
    {
        "enName": "Grenada",
        "vnName": "Grenada"
    },
    {
        "enName": "Guadeloupe",
        "vnName": "Guadeloupe"
    },
    {
        "enName": "Guatemala",
        "vnName": "Guatemala"
    },
    {
        "enName": "Guernsey",
        "vnName": "Guernsey"
    },
    {
        "enName": "French Guiana",
        "vnName": "Guiana thuộc Pháp"
    },
    {
        "enName": "Guinea",
        "vnName": "Guinea"
    },
    {
        "enName": "Equatorial Guinea",
        "vnName": "Guinea Xích Đạo"
    },
    {
        "enName": "Guinea-Bissau",
        "vnName": "Guinea-Bissau"
    },
    {
        "enName": "Guyana",
        "vnName": "Guyana"
    },
    {
        "enName": "Netherlands",
        "vnName": "Hà Lan"
    },
    {
        "enName": "Haiti",
        "vnName": "Haiti"
    },
    {
        "enName": "South Korea",
        "vnName": "Hàn Quốc"
    },
    {
        "enName": "Honduras",
        "vnName": "Honduras"
    },
    {
        "enName": "Hong Kong",
        "vnName": "Hồng Kông"
    },
    {
        "enName": "Hungary",
        "vnName": "Hungary"
    },
    {
        "enName": "Greece",
        "vnName": "Hy Lạp"
    },
    {
        "enName": "Iceland",
        "vnName": "Iceland"
    },
    {
        "enName": "Indonesia",
        "vnName": "Indonesia"
    },
    {
        "enName": "Iran",
        "vnName": "Iran"
    },
    {
        "enName": "Iraq",
        "vnName": "Iraq"
    },
    {
        "enName": "Ireland",
        "vnName": "Ireland"
    },
    {
        "enName": "Israel",
        "vnName": "Israel"
    },
    {
        "enName": "Jamaica",
        "vnName": "Jamaica"
    },
    {
        "enName": "Jersey",
        "vnName": "Jersey"
    },
    {
        "enName": "Jordan",
        "vnName": "Jordan"
    },
    {
        "enName": "Kazakhstan",
        "vnName": "Kazakhstan"
    },
    {
        "enName": "Kenya",
        "vnName": "Kenya"
    },
    {
        "enName": "Kiribati",
        "vnName": "Kiribati"
    },
    {
        "enName": "Kuwait",
        "vnName": "Kuwait"
    },
    {
        "enName": "Kyrgyzstan",
        "vnName": "Kyrgyzstan"
    },
    {
        "enName": "British Indian Ocean Territory",
        "vnName": "Lãnh thổ Ấn Độ Dương thuộc Anh"
    },
    {
        "enName": "Laos",
        "vnName": "Lào"
    },
    {
        "enName": "Latvia",
        "vnName": "Latvia"
    },
    {
        "enName": "Lebanon",
        "vnName": "Lebanon"
    },
    {
        "enName": "Lesotho",
        "vnName": "Lesotho"
    },
    {
        "enName": "Liberia",
        "vnName": "Liberia"
    },
    {
        "enName": "Libya",
        "vnName": "Libya"
    },
    {
        "enName": "Liechtenstein",
        "vnName": "Liechtenstein"
    },
    {
        "enName": "Federated States of Micronesia",
        "vnName": "Liên bang Micronesia"
    },
    {
        "enName": "Lithuania",
        "vnName": "Lithuania"
    },
    {
        "enName": "Luxembourg",
        "vnName": "Luxembourg"
    },
    {
        "enName": "Morocco",
        "vnName": "Ma Rốc (Maroc)"
    },
    {
        "enName": "Macao",
        "vnName": "Macao"
    },
    {
        "enName": "Macedonia",
        "vnName": "Macedonia"
    },
    {
        "enName": "Madagascar",
        "vnName": "Madagascar"
    },
    {
        "enName": "Malawi",
        "vnName": "Malawi"
    },
    {
        "enName": "Malaysia",
        "vnName": "Malaysia"
    },
    {
        "enName": "Maldives",
        "vnName": "Maldives"
    },
    {
        "enName": "Mali",
        "vnName": "Mali"
    },
    {
        "enName": "Malta",
        "vnName": "Malta"
    },
    {
        "enName": "Martinique",
        "vnName": "Martinique"
    },
    {
        "enName": "Mauritania",
        "vnName": "Mauritania"
    },
    {
        "enName": "Mauritius",
        "vnName": "Mauritius"
    },
    {
        "enName": "Mayotte",
        "vnName": "Mayotte"
    },
    {
        "enName": "Mexico",
        "vnName": "Mexico"
    },
    {
        "enName": "Moldova",
        "vnName": "Moldova"
    },
    {
        "enName": "Monaco",
        "vnName": "Monaco"
    },
    {
        "enName": "Mongolia",
        "vnName": "Mông Cổ"
    },
    {
        "enName": "Montenegro",
        "vnName": "Montenegro"
    },
    {
        "enName": "Montserrat",
        "vnName": "Montserrat"
    },
    {
        "enName": "Greenland",
        "vnName": "Một quốc gia tự trị thuộc Đan Mạch"
    },
    {
        "enName": "Mozambique",
        "vnName": "Mozambique"
    },
    {
        "enName": "United States",
        "vnName": "Mỹ (Hoa Kỳ)"
    },
    {
        "enName": "Myanmar",
        "vnName": "Myanmar"
    },
    {
        "enName": "Norway",
        "vnName": "Na Uy"
    },
    {
        "enName": "South Georgia and the South Sandwich Islands",
        "vnName": "Nam Georgia và Quần đảo Nam Sandwich"
    },
    {
        "enName": "South Africa",
        "vnName": "Nam Phi"
    },
    {
        "enName": "South Sudan",
        "vnName": "Nam Sudan"
    },
    {
        "enName": "Namibia",
        "vnName": "Namibia"
    },
    {
        "enName": "Nauru",
        "vnName": "Nauru"
    },
    {
        "enName": "Nepal",
        "vnName": "Nepal"
    },
    {
        "enName": "New Caledonia",
        "vnName": "New Caledonia"
    },
    {
        "enName": "New Zealand",
        "vnName": "New Zealand"
    },
    {
        "enName": "Russian ",
        "vnName": "Nga"
    },
    {
        "enName": "Brunei",
        "vnName": "Nhà nước Brunei Darussalam"
    },
    {
        "enName": "Japan",
        "vnName": "Nhật Bản"
    },
    {
        "enName": "Nicaragua",
        "vnName": "Nicaragua"
    },
    {
        "enName": "Niger",
        "vnName": "Niger"
    },
    {
        "enName": "Nigeria",
        "vnName": "Nigeria"
    },
    {
        "enName": "Niue",
        "vnName": "Niue"
    },
    {
        "enName": "Oman",
        "vnName": "Oman"
    },
    {
        "enName": "Pakistan",
        "vnName": "Pakistan"
    },
    {
        "enName": "Palau",
        "vnName": "Palau"
    },
    {
        "enName": "Palestine",
        "vnName": "Palestine"
    },
    {
        "enName": "Panama",
        "vnName": "Panama"
    },
    {
        "enName": "Papua New Guinea",
        "vnName": "Papua New Guinea"
    },
    {
        "enName": "Paraguay",
        "vnName": "Paraguay"
    },
    {
        "enName": "Peru",
        "vnName": "Peru"
    },
    {
        "enName": "Finland",
        "vnName": "Phần Lan"
    },
    {
        "enName": "France",
        "vnName": "Pháp"
    },
    {
        "enName": "Philippines",
        "vnName": "Philippines"
    },
    {
        "enName": "Pitcairn",
        "vnName": "Pitcairn"
    },
    {
        "enName": "French Polynesia",
        "vnName": "Polynesia thuộc Pháp"
    },
    {
        "enName": "Puerto Rico",
        "vnName": "Puerto Rico"
    },
    {
        "enName": "Qatar",
        "vnName": "Qatar"
    },
    {
        "enName": "Northern Mariana Islands",
        "vnName": "Quần đảo Bắc Mariana"
    },
    {
        "enName": "Cayman Islands",
        "vnName": "Quần đảo Cayman"
    },
    {
        "enName": "Cocos (Keeling) Islands",
        "vnName": "Quần đảo Cocos"
    },
    {
        "enName": "Cook Islands",
        "vnName": "Quần đảo Cook"
    },
    {
        "enName": "Falkland Islands (Malvinas)",
        "vnName": "Quần đảo Falkland"
    },
    {
        "enName": "Faroe Islands",
        "vnName": "Quần đảo Faroe"
    },
    {
        "enName": "Marshall Islands",
        "vnName": "Quần đảo Marshall"
    },
    {
        "enName": "Solomon Islands",
        "vnName": "Quần đảo Solomon"
    },
    {
        "enName": "Turks and Caicos Islands",
        "vnName": "Quần đảo Turks và Caicos"
    },
    {
        "enName": "British Virgin Islands",
        "vnName": "Quần đảo Virgin thuộc Anh"
    },
    {
        "enName": "US Virgin Islands",
        "vnName": "Quần đảo Virgin thuộc Mỹ "
    },
    {
        "enName": "Reunion",
        "vnName": "Reunion"
    },
    {
        "enName": "Romania",
        "vnName": "Romania"
    },
    {
        "enName": "Rwanda",
        "vnName": "Rwanda"
    },
    {
        "enName": "Saint Barthelemy",
        "vnName": "Saint Barthelemy"
    },
    {
        "enName": "Saint Helena",
        "vnName": "Saint Helena"
    },
    {
        "enName": "Saint Kitts and Nevis",
        "vnName": "Saint Kitts và Nevis"
    },
    {
        "enName": "Saint Lucia",
        "vnName": "Saint Lucia"
    },
    {
        "enName": "Saint Martin (French part)",
        "vnName": "Saint Martin (French part)"
    },
    {
        "enName": "Saint Pierre and Miquelon",
        "vnName": "Saint Pierre và Miquelon"
    },
    {
        "enName": "Saint Vincent and the Grenadines",
        "vnName": "Saint Vincent và the Grenadines"
    },
    {
        "enName": "Samoa",
        "vnName": "Samoa"
    },
    {
        "enName": "American Samoa",
        "vnName": "Samoa thuộc Mỹ"
    },
    {
        "enName": "San Marino",
        "vnName": "San Marino"
    },
    {
        "enName": "Sao Tome and Principe",
        "vnName": "Sao Tome và Principe"
    },
    {
        "enName": "Chad",
        "vnName": "Sát (Tchad)"
    },
    {
        "enName": "Czech Republic",
        "vnName": "Séc"
    },
    {
        "enName": "Senegal",
        "vnName": "Senegal"
    },
    {
        "enName": "Serbia",
        "vnName": "Serbia"
    },
    {
        "enName": "Seychelles",
        "vnName": "Seychelles"
    },
    {
        "enName": "Sierra Leone",
        "vnName": "Sierra Leone"
    },
    {
        "enName": "Singapore",
        "vnName": "Singapore"
    },
    {
        "enName": "Sint Maarten",
        "vnName": "Sint Maarten"
    },
    {
        "enName": "Cyprus",
        "vnName": "Síp"
    },
    {
        "enName": "Slovakia",
        "vnName": "Slovakia"
    },
    {
        "enName": "Slovenia",
        "vnName": "Slovenia"
    },
    {
        "enName": "Somalia",
        "vnName": "Somalia"
    },
    {
        "enName": "Sri Lanka",
        "vnName": "Sri Lanka"
    },
    {
        "enName": "Sudan",
        "vnName": "Sudan"
    },
    {
        "enName": "Suriname",
        "vnName": "Suriname"
    },
    {
        "enName": "Svalbard and Jan Mayen",
        "vnName": "Svalbard và Jan Mayen"
    },
    {
        "enName": "Swaziland",
        "vnName": "Swaziland"
    },
    {
        "enName": "Syria",
        "vnName": "Syria"
    },
    {
        "enName": "Tajikistan",
        "vnName": "Tajikistan"
    },
    {
        "enName": "Tanzania",
        "vnName": "Tanzania"
    },
    {
        "enName": "Spain",
        "vnName": "Tây Ban Nha"
    },
    {
        "enName": "Western Sahara",
        "vnName": "Tây Sahara"
    },
    {
        "enName": "Thailand",
        "vnName": "Thái Lan"
    },
    {
        "enName": "Turkey",
        "vnName": "Thổ Nhĩ Kỳ"
    },
    {
        "enName": "Sweden",
        "vnName": "Thụy Điển"
    },
    {
        "enName": "Switzerland",
        "vnName": "Thụy Sĩ"
    },
    {
        "enName": "Vatican City ",
        "vnName": "Tòa thánh Vatican"
    },
    {
        "enName": "Togo",
        "vnName": "Togo"
    },
    {
        "enName": "Tokelau",
        "vnName": "Tokelau"
    },
    {
        "enName": "Tonga",
        "vnName": "Tonga"
    },
    {
        "enName": "North Korea",
        "vnName": "Triều tiên"
    },
    {
        "enName": "Trinidad and Tobago",
        "vnName": "Trinidad và Tobago"
    },
    {
        "enName": "Central African Republic",
        "vnName": "Trung Phi"
    },
    {
        "enName": "China",
        "vnName": "Trung Quốc"
    },
    {
        "enName": "Tunisia",
        "vnName": "Tunisia"
    },
    {
        "enName": "Turkmenistan",
        "vnName": "Turkmenistan"
    },
    {
        "enName": "Tuvalu",
        "vnName": "Tuvalu"
    },
    {
        "enName": "Australia",
        "vnName": "Úc"
    },
    {
        "enName": "Uganda",
        "vnName": "Uganda"
    },
    {
        "enName": "Ukraine",
        "vnName": "Ukraine"
    },
    {
        "enName": "Uruguay",
        "vnName": "Uruguay"
    },
    {
        "enName": "Uzbekistan",
        "vnName": "Uzbekistan"
    },
    {
        "enName": "Vanuatu",
        "vnName": "Vanuatu"
    },
    {
        "enName": "Venezuela",
        "vnName": "Venezuela"
    },
    {
        "enName": "Vietnam",
        "vnName": "Việt Nam"
    },
    {
        "enName": "French Southern Territories",
        "vnName": "Vùng đất phía Nam và châu Nam Cực thuộc Pháp"
    },
    {
        "enName": "United Kingdom",
        "vnName": "Vương quốc Liên hiệp Anh và Bắc Ireland"
    },
    {
        "enName": "Wallis and Futuna",
        "vnName": "Wallis và Futuna"
    },
    {
        "enName": "Italy",
        "vnName": "Ý"
    },
    {
        "enName": "Yemen",
        "vnName": "Yemen"
    },
    {
        "enName": "Zambia",
        "vnName": "Zambia"
    },
    {
        "enName": "Zimbabwe",
        "vnName": "Zimbabwe"
    }
]

const { MonthPicker, QuarterPicker, YearPicker } = DatePicker;

const { Step } = Steps;

const Home: React.FC = () => {
    const [form] = Form.useForm(); // Form tạo mới kiến nghị
    const [formAdd] = Form.useForm(); // Form tạo mới kiến nghị
    const [formForward] = Form.useForm(); // Form chuyển tiếp kiến nghị
    const [formEdit] = Form.useForm(); // Form sửa kiến nghị
    const { isAdmin, setIsAdmin, lstAction, setLstAction, lstCategory, setLstCategory, lstUser, setLstUser, userId, setUserId, isCollapsed, setIsCollapsed, lstExpertTmp, setLstExpertTmp, lstExpert, setLstExpert } = MainState();
    const [lstRequest, setLstRequest] = useState<any[]>([]); // Danh sách kiến nghị
    const [isOpenModalView, setIsOpenModalView] = useState(false); // Mở modal xem chi tiết kiến nghị
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false); // Mở modal tạo mới kiến nghị
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false); // Mở modal sửa kiến nghị
    const [isOpenModalForward, setIsOpenModalForward] = useState(false); // Mở modal chuyển tiếp kiến nghị
    const [requestChoose, setRequestChoose] = useState<any>(null); // Kiến nghị cần chuyển tiếp
    const [pageSize, setPageSize] = useState(10); // Số lượng kiến nghị trên 1 trang
    const [changeInfo, setChangeInfo] = useState<any>([]); // Thông tin thay đổi
    const [isChangeInfo, setIsChangeInfo] = useState<any>(''); // Nội dung modal xem chi tiết kiến nghị
    const columns: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Số Công văn',
            dataIndex: 'numberDocument',
            key: 'numberDocument',
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Số đến',
            dataIndex: 'numberTo',
            key: 'numberTo',
            width: 130,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].numberTo !== lstExpertTmp[index].numberTo ?
                        //     <div>
                        //         <Tag color='yellow'>{record.numberTo}</Tag>
                        //     </div>
                        //     :
                        <div>{record.numberTo}</div>
                    }
                </>
            )
        },
        {
            title: 'Ngày Công văn',
            dataIndex: 'dateDocument',
            key: 'dateDocument',
            width: 130,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].dateDocument !== lstExpertTmp[index].dateDocument ?
                        //     <div>
                        //         <Tag color='yellow'>{moment(record.dateDocument).format('DD/MM/YYYY')}</Tag>
                        //     </div>
                        //     :
                        <div>{moment(record.dateDocument).format('DD/MM/YYYY')}</div>
                    }
                </>
            )
        },
        {
            title: 'Nội dung Công văn',
            dataIndex: 'contentDocument',
            key: 'contentDocument',
            width: 1100,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].contentDocument !== lstExpertTmp[index].contentDocument ?
                        //     <div
                        //         style={{
                        //             border: '1px solid red',
                        //             borderRadius: '5px',
                        //         }}
                        //         // onClick={() => handleOpenModalView(lstExpert[index].contentDocument, lstExpertTmp[index].contentDocument)}
                        //     >
                        //         {record.contentDocument}
                        //     </div>
                        //     :
                        <div>{record.contentDocument}</div>
                    }
                </>
            )
        },
        {
            title: 'Họ tên Chuyên gia',
            dataIndex: 'nameExpert',
            key: 'nameExpert',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].nameExpert !== lstExpertTmp[index].nameExpert ?
                        //     <div>
                        //         <Tag color='yellow'>{record.nameExpert}</Tag>
                        //     </div>
                        //     :
                        <div>{record.nameExpert}</div>
                    }
                </>
            )
        },
        {
            title: 'Ngày tháng năm sinh',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 170,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].dateOfBirth !== lstExpertTmp[index].dateOfBirth ?
                        //     <div>
                        //         <Tag color='yellow'>{moment(record.dateOfBirth).format('DD/MM/YYYY')}</Tag>
                        //     </div>
                        //     :
                        <div>{moment(record.dateOfBirth).format('DD/MM/YYYY')}</div>
                    }
                </>
            )
        },
        {
            title: 'Giới tính',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].gender !== lstExpertTmp[index].gender ?
                        //     <div>
                        //         <Tag color='yellow'>{(record.gender)}</Tag>
                        //     </div>
                        //     :
                        <div>{(record.gender)}</div>
                    }
                </>
            )
        },
        {
            title: 'Quốc tịch',
            dataIndex: 'national',
            key: 'national',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].national !== lstExpertTmp[index].national ?
                        //     <div>
                        //         <Tag color='yellow'>{record.national}</Tag>
                        //     </div>
                        //     :
                        <div>{record.national}</div>
                    }
                </>
            )
        },
        {
            title: 'Loại giấy tờ',
            dataIndex: 'typePaper',
            key: 'typePaper',
            width: 120,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].typePaper !== lstExpertTmp[index].typePaper ?
                        //     <div>
                        //         <Tag color='yellow'>{record.typePaper}</Tag>
                        //     </div>
                        //     :
                        <div>{record.typePaper}</div>
                    }
                </>
            )
        },
        {
            title: 'Số giấy tờ',
            dataIndex: 'numberPaper',
            key: 'numberPaper',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].numberPaper !== lstExpertTmp[index].numberPaper ?
                        //     <div>
                        //         <Tag color='yellow'>{record.numberPaper}</Tag>
                        //     </div>
                        //     :
                        <div>{record.numberPaper}</div>
                    }
                </>
            )
        },
        {
            title: 'Diện VISA',
            dataIndex: 'visa',
            key: 'visa',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].visa !== lstExpertTmp[index].visa ?
                        //     <div>
                        //         <Tag color='yellow'>{record.visa || 'Không có thông tin visa'}</Tag>
                        //     </div>
                        //     :
                        <div>{record.visa || 'Không có thông tin visa'}</div>
                    }
                </>
            )
        },
        {
            title: 'Nơi cư trú',
            dataIndex: 'placeResidence',
            key: 'placeResidence',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].placeResidence !== lstExpertTmp[index].placeResidence ?
                        //     <div>
                        //         <Tag color='yellow'>{record.placeResidence}</Tag>
                        //     </div>
                        //     :
                        <div>{record.placeResidence}</div>
                    }
                </>
            )
        },
        {
            title: 'Đơn vị, công ty chuyên gia đến làm việc',
            dataIndex: 'unitProposal',
            key: 'unitProposal',
            width: 200,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].unitProposal !== lstExpertTmp[index].unitProposal ?
                        //     <div>
                        //         <Tag color='yellow'>{record.unitProposal}</Tag>
                        //     </div>
                        //     :
                        <div>{record.unitProposal}</div>
                    }
                </>
            )
        },
        {
            title: 'Thời gian làm việc',
            dataIndex: 'workingTime',
            key: 'workingTime',
            width: 200,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].workingTime !== lstExpertTmp[index].workingTime ?
                        //     <div>
                        //         <Tag color='yellow'>{moment(record.workingTimeStart).format('DD/MM/YYYY')} - {moment(record.workingTimeEnd).format('DD/MM/YYYY')}</Tag>
                        //     </div>
                        //     :
                        <div>{moment(record.workingTimeStart).format('DD/MM/YYYY')} - {moment(record.workingTimeEnd).format('DD/MM/YYYY')}</div>
                    }
                </>
            )

        },
        {
            title: 'Địa điểm làm việc',
            dataIndex: 'workingPlace',
            key: 'workingPlace',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].workingPlace !== lstExpertTmp[index].workingPlace ?
                        //     <div>
                        //         <Tag color='yellow'>{record.workingPlace}</Tag>
                        //     </div>
                        //     :
                        <div>{record.workingPlace}</div>
                    }
                </>
            )
        },
        {
            title: 'Mục đích / Nội dung làm việc',
            dataIndex: 'workingContent',
            key: 'workingContent',
            width: 1000,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].workingContent !== lstExpertTmp[index].workingContent ?
                        //     <div>
                        //         <Tag color='yellow'>{record.workingContent || 'Không có mục đích / nội dung làm việc'}</Tag>
                        //     </div>
                        //     :
                        <div>{record.workingContent || 'Không có mục đích / nội dung làm việc'}</div>
                    }
                </>
            )
        },
        {
            title: 'Lưu ý về an ninh',
            dataIndex: 'securityNote',
            key: 'securityNote',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].securityNote !== lstExpertTmp[index].securityNote ?
                        //     <div>
                        //         <Tag color='yellow'>{record.securityNote || 'Không có thông tin an ninh'}</Tag>
                        //     </div>
                        //     :
                        <div>{record.securityNote || 'Không có thông tin an ninh'}</div>
                    }
                </>
            )
        },
        {
            title: 'Tên dự án, gói thầu',
            dataIndex: 'projectName',
            key: 'projectName',
            width: 200,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].projectName !== lstExpertTmp[index].projectName ?
                        //     <div>
                        //         <Tag color='yellow'>{record.projectName}</Tag>
                        //     </div>
                        //     :
                        <div>{record.projectName}</div>
                    }
                </>
            )
        },
        {
            title: 'Chủ đầu tư',
            dataIndex: 'investor',
            key: 'investor',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].investor !== lstExpertTmp[index].investor ?
                        //     <div>
                        //         <Tag color='yellow'>{record.investor}</Tag>
                        //     </div>
                        //     :
                        <div>{record.investor}</div>
                    }
                </>
            )
        },
        {
            title: 'Cán bộ xử lý (Thẩm định chuyên gia) ',
            dataIndex: 'processingOfficer',
            key: 'processingOfficer',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        // lstExpert[index].processingOfficer !== lstExpertTmp[index].processingOfficer ?
                        //     <div>
                        //         <Tag color='yellow'>{record.processingOfficer}</Tag>
                        //     </div>
                        //     :
                        <div>{record.processingOfficer}</div>
                    }
                </>
            )
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            width: 150,
            render: (text: any, record: any, index: any) => (
                <>
                    <div>{record.note}</div>
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    {/* <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip> */}
                    {/* {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Phê duyệt'>
                                <div onClick={() => (record)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    } */}
                </div>
            )
        }
    ]

    const columnsAdmin: TableColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 70,
            fixed: 'left',
            render: (text: any, record: any, index: number) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Số Công văn',
            dataIndex: 'numberDocument',
            key: 'numberDocument',
            fixed: 'left',
            width: 120,
        },
        {
            title: 'Thông tin thay đổi',
            dataIndex: 'changeInfo',
            key: 'changeInfo',
            width: 250,
            render: (text: any, record: any, index: any) => (
                <>
                    {
                        <div>{changeInfo[index] || 'Không có thông tin thay đổi'}</div>
                    }
                </>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text: any, record: any, index: any) => (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '10px'
                }}>
                    {
                        localStorage.getItem('role') === 'admin' && (
                            <Tooltip title='Xem và phê duyệt'>
                                <div onClick={() => handleViewRequest(record, index)}>
                                    <FormOutlined />
                                </div>
                            </Tooltip>
                        )
                    }
                    <Tooltip title='Sửa'>
                        <div onClick={() => handleEditRequest(record)}>
                            <EditOutlined />
                        </div>
                    </Tooltip>
                    <Tooltip title='Xóa'>
                        <div onClick={() => handleDeleteRequest(record)}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
            )
        }
    ]
    const [selectedPaper, setSelectedPaper] = useState<any>(0); // Giấy tờ được chọn
    const [selectedCategory, setSelectedCategory] = useState<any>(0); // Danh mục được chọn
    const [currentStep, setCurrentStep] = useState(0);
    const [widthHome, setWidthHome] = useState(270);
    const [addValue, setAddValue] = useState<any>({
        numberDocument: '',
        numberTo: '',
        dateDocument: '',
        contentDocument: '',
        nameExpert: '',
        dateOfBirth: '',
        gender: '',
        national: '',
        typePaper: '',
        numberPaper: '',
        visa: '',
        placeResidence: '',
        unitProposal: '',
        workingTime: '',
        workingPlace: '',
        workingContent: '',
        securityNote: '',
        projectName: '',
        investor: '',
        processingOfficer: '',
        note: '',
    }); // Giá trị form tạo mới kiến nghị
    const [searchValue, setSearchValue] = useState({
        numberDocument: '',
        numberTo: '',
        dateDocument: '',
        contentDocument: '',
        nameExpert: '',
        dateOfBirth: '',
        gender: '',
        national: '',
        typePaper: '',
        numberPaper: '',
        visa: '',
        placeResidence: '',
        unitProposal: '',
        workingTimeStart: '',
        workingTimeEnd: '',
        workingPlace: '',
        workingContent: '',
        securityNote: '',
        projectName: '',
        investor: '',
        processingOfficer: '',
        note: '',
    }); // Giá trị tìm kiếm
    const [fileName, setFileName] = useState(''); // Tên file
    const [isOpenModalFile, setIsOpenModalFile] = useState(false); // Mở modal file
    const [formExcel] = Form.useForm(); // Form import file
    const [numberNewRecord, setNumberNewRecord] = useState(0); // Số lượng bản ghi mới
    const [numberUpdateRecord, setNumberUpdateRecord] = useState(0); // Số lượng bản ghi cập nhật
    useEffect(() => {
        console.log(searchValue);
        getAllExpertTmp(searchValue)
        getAllExpert(searchValue)

    }, [searchValue]);


    useEffect(() => {
        setUserId(localStorage.getItem('usernameId'))
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        getAllExpertTmp(searchValue)
        getAllExpert(searchValue)

        localStorage.getItem('role') === 'admin' ? setIsAdmin(true) : setIsAdmin(false);

    }, []);

    useEffect(() => {
        console.log('isCollapsed', isCollapsed);
        if (isCollapsed) {
            setWidthHome(80);
        } else {
            setWidthHome(270);
        }
    }, [isCollapsed]);

    useEffect(() => {
        console.log('addValue', addValue);
    }, [addValue]);

    useEffect(() => {
        let lstInfo: any[] = [];
        console.log('lstExpert', lstExpert);
        console.log('lstExpertTmp', lstExpertTmp);

        if (lstExpert.length > 0 && lstExpertTmp.length > 0 && lstExpert.length === lstExpertTmp.length) {

            let lst = lstExpertTmp.map((item: any, index: any) => {
                let lstTmp = lstExpert.filter((itemExpert: any) => itemExpert.codeExpert === item.codeExpert);
                return lstTmp[0];
            })

            console.log('lstExpertTmp', lst);


            lstExpertTmp.map((item: any, index: any) => {
                let info = '';

                if (item.numberDocument !== lst[index]?.numberDocument) {
                    info += 'Số công văn, ';
                }
                if (item.numberTo !== lst[index].numberTo) {
                    info += 'Số đến, ';
                }
                if (item.dateDocument !== lst[index].dateDocument) {
                    info += 'Ngày công văn, ';
                }
                if (item.contentDocument !== lst[index].contentDocument) {
                    info += 'Nội dung công văn, ';
                }
                if (item.nameExpert !== lst[index].nameExpert) {
                    info += 'Họ tên chuyên gia, ';
                }
                if (item.dateOfBirth !== lst[index].dateOfBirth) {
                    info += 'Ngày tháng năm sinh, ';
                }
                if (item.gender !== lst[index].gender) {
                    info += 'Giới tính, ';
                }
                if (item.national !== lst[index].national) {
                    info += 'Quốc tịch, ';
                }
                if (item.typePaper !== lst[index].typePaper) {
                    info += 'Loại giấy tờ, ';
                }
                if (item.numberPaper !== lst[index].numberPaper) {
                    info += 'Số giấy tờ, ';
                }
                if (item.visa !== lst[index].visa) {
                    info += 'Diện visa, ';
                }
                if (item.placeResidence !== lst[index].placeResidence) {
                    info += 'Nơi cư trú, ';
                }
                if (item.unitProposal !== lst[index].unitProposal) {
                    info += 'Cơ quan-đơn vị đề nghị, ';
                }
                if (item.workingTime !== lst[index].workingTime) {
                    info += 'Thời gian làm việc, ';
                }
                if (item.workingPlace !== lst[index].workingPlace) {
                    info += 'Địa điểm làm việc, ';
                }
                if (item.workingContent !== lst[index].workingContent) {
                    info += 'Mục đích / Nội dung làm việc, ';
                }
                if (item.securityNote !== lst[index].securityNote) {
                    info += 'Lưu ý về an ninh, ';
                }
                if (item.projectName !== lst[index].projectName) {
                    info += 'Tên dự án-gói thầu, ';
                }
                if (item.investor !== lst[index].investor) {
                    info += 'Chủ đầu tư, ';
                }
                if (item.processingOfficer !== lst[index].processingOfficer) {
                    info += 'Cán bộ xử lý (Thẩm định chuyên gia) , ';
                }
                if (item.note !== lst[index].note) {
                    info += 'Ghi chú, ';
                }

                lstInfo.push(info);
            })
        }
        console.log('lstInfo', lstInfo);

        setChangeInfo(lstInfo);
    }, [lstExpertTmp, lstExpert]);

    const getAllExpertTmp = async (searchValue: any) => {

        if (localStorage.getItem('role') === 'admin') {
            await apiExpert.getAllTmp(localStorage.getItem('token'))
                .then((res) => {
                    console.log('res', res.data);

                    let lst = res.data.data.experts
                    // Lọc ra status chờ phê duyệt hiển thị đầu tiên và đã tạo sau
                    lst = lst.sort((a: any, b: any) => {
                        if (a.status === 'Chờ phê duyệt' && b.status === 'Đã tạo') return -1;
                        if (a.status === 'Đã tạo' && b.status === 'Chờ phê duyệt') return 1;
                        return 0;
                    })

                    setNumberNewRecord(lst.filter((item: any) => item.status === 'Đã tạo').length);
                    setNumberUpdateRecord(lst.filter((item: any) => item.status === 'Chờ phê duyệt').length);


                    if (searchValue.numberDocument) {
                        lst = lst.filter((item: any) => item.numberDocument.toLowerCase().includes(searchValue.numberDocument.toLowerCase()))
                    }

                    if (searchValue.numberTo) {
                        lst = lst.filter((item: any) => item.numberTo.toLowerCase().includes(searchValue.numberTo.toLowerCase()))
                    }

                    if (searchValue.dateDocument) {
                        lst = lst.filter((item: any) => item.dateDocument.toLowerCase().includes(searchValue.dateDocument.toLowerCase()))
                    }

                    if (searchValue.contentDocument) {
                        lst = lst.filter((item: any) => item.contentDocument.toLowerCase().includes(searchValue.contentDocument.toLowerCase()))
                    }

                    if (searchValue.nameExpert) {
                        lst = lst.filter((item: any) => item.nameExpert.toLowerCase().includes(searchValue.nameExpert.toLowerCase()))
                    }

                    if (searchValue.dateOfBirth) {

                        console.log('searchValue.dateOfBirth', searchValue.dateOfBirth);

                        const searchDate = new Date(searchValue.dateOfBirth).getTime();

                        lst = lst.filter((item: any) => {
                            const itemDate = new Date(item.dateOfBirth).getTime();
                            return itemDate === searchDate;
                        });

                    }

                    if (searchValue.gender) {
                        lst = lst.filter((item: any) => item.gender.toLowerCase().includes(searchValue.gender.toLowerCase()))
                    }

                    if (searchValue.national) {
                        lst = lst.filter((item: any) => item.national.toLowerCase().includes(searchValue.national.toLowerCase()))
                    }

                    if (searchValue.typePaper) {
                        lst = lst.filter((item: any) => item.typePaper.toLowerCase().includes(searchValue.typePaper.toLowerCase()))
                    }

                    if (searchValue.numberPaper) {
                        lst = lst.filter((item: any) => item.numberPaper.toLowerCase().includes(searchValue.numberPaper.toLowerCase()))
                    }

                    if (searchValue.visa) {
                        lst = lst.filter((item: any) => item.visa.toLowerCase().includes(searchValue.visa.toLowerCase()))
                    }

                    if (searchValue.placeResidence) {
                        lst = lst.filter((item: any) => item.placeResidence.toLowerCase().includes(searchValue.placeResidence.toLowerCase()))
                    }

                    if (searchValue.unitProposal) {
                        lst = lst.filter((item: any) => item.unitProposal.toLowerCase().includes(searchValue.unitProposal.toLowerCase()))
                    }

                    if (searchValue.workingTimeStart && searchValue.workingTimeEnd) {
                        const startDate = new Date(searchValue.workingTimeStart).getTime();
                        const endDate = new Date(searchValue.workingTimeEnd).getTime();

                        console.log('startDate', startDate);
                        console.log('endDate', endDate);

                        lst = lst.filter((item: any) => {
                            const itemStartDate = new Date(item.workingTimeStart).getTime();
                            const itemEndDate = new Date(item.workingTimeEnd).getTime();

                            console.log('itemStartDate', itemStartDate);
                            console.log('itemEndDate', itemEndDate);


                            return itemStartDate >= startDate && itemEndDate <= endDate;
                        });
                    }

                    if (searchValue.workingPlace) {
                        lst = lst.filter((item: any) => item.workingPlace.toLowerCase().includes(searchValue.workingPlace.toLowerCase()))
                    }

                    if (searchValue.workingContent) {
                        lst = lst.filter((item: any) => item.workingContent.toLowerCase().includes(searchValue.workingContent.toLowerCase()))
                    }

                    if (searchValue.securityNote) {
                        lst = lst.filter((item: any) => item.securityNote.toLowerCase().includes(searchValue.securityNote.toLowerCase()))
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()))
                    }

                    if (searchValue.investor) {
                        lst = lst.filter((item: any) => item.investor.toLowerCase().includes(searchValue.investor.toLowerCase()))
                    }

                    if (searchValue.processingOfficer) {
                        lst = lst.filter((item: any) => item.processingOfficer.toLowerCase().includes(searchValue.processingOfficer.toLowerCase()))
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()))
                    }

                    setLstExpertTmp(lst);

                })
                .catch((err) => {
                    console.log('err', err);
                });
        }

        else {
            await apiExpert.getAllTmpByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log('res', res.data);

                    let lst = res.data.data.experts

                    if (searchValue.numberDocument) {
                        lst = lst.filter((item: any) => item.numberDocument.toLowerCase().includes(searchValue.numberDocument.toLowerCase()))
                    }

                    if (searchValue.numberTo) {
                        lst = lst.filter((item: any) => item.numberTo.toLowerCase().includes(searchValue.numberTo.toLowerCase()))
                    }

                    if (searchValue.dateDocument) {
                        lst = lst.filter((item: any) => item.dateDocument.toLowerCase().includes(searchValue.dateDocument.toLowerCase()))
                    }

                    if (searchValue.contentDocument) {
                        lst = lst.filter((item: any) => item.contentDocument.toLowerCase().includes(searchValue.contentDocument.toLowerCase()))
                    }

                    if (searchValue.nameExpert) {
                        lst = lst.filter((item: any) => item.nameExpert.toLowerCase().includes(searchValue.nameExpert.toLowerCase()))
                    }

                    if (searchValue.dateOfBirth) {

                        console.log('searchValue.dateOfBirth', searchValue.dateOfBirth);
                        // const searchDate = new Date(searchValue.dateOfBirth);
                        // console.log('searchDate', searchDate);

                        const parseDate = (dateString: string): Date => {
                            const [day, month, year] = dateString.split('/').map(Number);
                            return new Date(year, month - 1, day); // Lưu ý: tháng trong JS Date bắt đầu từ 0
                        };

                        const searchDate = parseDate(searchValue.dateOfBirth);

                        console.log('searchDate.getDate()', searchDate.getDate());
                        console.log('searchDate.getMonth()', searchDate.getMonth() + 1);
                        console.log('searchDate.getFullYear()', searchDate.getFullYear());

                        const lstTmp = lst.filter((item: any) => {
                            console.log('item.dateOfBirth', item.dateOfBirth);

                            const itemDate = new Date(item.dateOfBirth);
                            console.log('itemDate', itemDate);

                            const day = itemDate.getDate();
                            const month = itemDate.getMonth() + 1;
                            const year = itemDate.getFullYear();
                            console.log('day', day);
                            console.log('month', month);
                            console.log('year', year);
                            if (day === searchDate.getDate() && month === searchDate.getMonth() + 1 && year === searchDate.getFullYear()) {
                                return item;
                            }

                            // itemDate.setDate(itemDate.getDate());
                            // console.log(itemDate.getDate());
                            // console.log(itemDate.getMonth() + 1);
                            // console.log(itemDate.getFullYear());

                            // console.log('item.dateOfBirth', itemDate);
                            // if (itemDate.getDay() === searchDate.getDay() && itemDate.getMonth() + 1 === searchDate.getMonth() && itemDate.getFullYear() === searchDate.getFullYear()) {
                            //     return item;
                            // }
                        });

                        lst = lstTmp;
                    }

                    if (searchValue.gender) {
                        lst = lst.filter((item: any) => item.gender.toLowerCase().includes(searchValue.gender.toLowerCase()))
                    }

                    if (searchValue.national) {
                        lst = lst.filter((item: any) => item.national.toLowerCase().includes(searchValue.national.toLowerCase()))
                    }

                    if (searchValue.typePaper) {
                        lst = lst.filter((item: any) => item.typePaper.toLowerCase().includes(searchValue.typePaper.toLowerCase()))
                    }

                    if (searchValue.numberPaper) {
                        lst = lst.filter((item: any) => item.numberPaper.toLowerCase().includes(searchValue.numberPaper.toLowerCase()))
                    }

                    if (searchValue.visa) {
                        lst = lst.filter((item: any) => item.visa.toLowerCase().includes(searchValue.visa.toLowerCase()))
                    }

                    if (searchValue.placeResidence) {
                        lst = lst.filter((item: any) => item.placeResidence.toLowerCase().includes(searchValue.placeResidence.toLowerCase()))
                    }

                    if (searchValue.unitProposal) {
                        lst = lst.filter((item: any) => item.unitProposal.toLowerCase().includes(searchValue.unitProposal.toLowerCase()))
                    }

                    if (searchValue.workingTimeStart && searchValue.workingTimeEnd) {
                        const startDate = new Date(searchValue.workingTimeStart).getTime();
                        const endDate = new Date(searchValue.workingTimeEnd).getTime();

                        console.log('startDate', startDate);
                        console.log('endDate', endDate);

                        lst = lst.filter((item: any) => {
                            const itemStartDate = new Date(item.workingTimeStart).getTime();
                            const itemEndDate = new Date(item.workingTimeEnd).getTime();

                            console.log('itemStartDate', itemStartDate);
                            console.log('itemEndDate', itemEndDate);


                            return itemStartDate >= startDate && itemEndDate <= endDate;
                        });
                    }

                    if (searchValue.workingPlace) {
                        lst = lst.filter((item: any) => item.workingPlace.toLowerCase().includes(searchValue.workingPlace.toLowerCase()))
                    }

                    if (searchValue.workingContent) {
                        lst = lst.filter((item: any) => item.workingContent.toLowerCase().includes(searchValue.workingContent.toLowerCase()))
                    }

                    if (searchValue.securityNote) {
                        lst = lst.filter((item: any) => item.securityNote.toLowerCase().includes(searchValue.securityNote.toLowerCase()))
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()))
                    }

                    if (searchValue.investor) {
                        lst = lst.filter((item: any) => item.investor.toLowerCase().includes(searchValue.investor.toLowerCase()))
                    }

                    if (searchValue.processingOfficer) {
                        lst = lst.filter((item: any) => item.processingOfficer.toLowerCase().includes(searchValue.processingOfficer.toLowerCase()))
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()))
                    }

                    setLstExpertTmp(lst);

                })
                .catch((err) => {
                    console.log('err', err);
                });
        }

    }

    const getAllExpert = async (searchValue: any) => {
        if (localStorage.getItem('role') === 'admin') {
            await apiExpert.getAll(localStorage.getItem('token'))
                .then((res) => {
                    console.log('res', res.data);

                    let lst = res.data.data.experts

                    console.log('lst', lst);


                    if (searchValue.numberDocument) {
                        lst = lst.filter((item: any) => item.numberDocument.toLowerCase().includes(searchValue.numberDocument.toLowerCase()))
                    }

                    if (searchValue.numberTo) {
                        lst = lst.filter((item: any) => item.numberTo.toLowerCase().includes(searchValue.numberTo.toLowerCase()))
                    }

                    if (searchValue.dateDocument) {
                        lst = lst.filter((item: any) => item.dateDocument.toLowerCase().includes(searchValue.dateDocument.toLowerCase()))
                    }

                    if (searchValue.contentDocument) {
                        lst = lst.filter((item: any) => item.contentDocument.toLowerCase().includes(searchValue.contentDocument.toLowerCase()))
                    }

                    if (searchValue.nameExpert) {
                        lst = lst.filter((item: any) => item.nameExpert.toLowerCase().includes(searchValue.nameExpert.toLowerCase()))
                    }

                    if (searchValue.dateOfBirth) {
                        lst = lst.filter((item: any) => item.dateOfBirth.toLowerCase().includes(searchValue.dateOfBirth.toLowerCase()))
                    }

                    if (searchValue.gender) {
                        lst = lst.filter((item: any) => item.gender.toLowerCase().includes(searchValue.gender.toLowerCase()))
                    }

                    if (searchValue.national) {
                        lst = lst.filter((item: any) => item.national.toLowerCase().includes(searchValue.national.toLowerCase()))
                    }

                    if (searchValue.typePaper) {
                        lst = lst.filter((item: any) => item.typePaper.toLowerCase().includes(searchValue.typePaper.toLowerCase()))
                    }

                    if (searchValue.numberPaper) {
                        lst = lst.filter((item: any) => item.numberPaper.toLowerCase().includes(searchValue.numberPaper.toLowerCase()))
                    }

                    if (searchValue.visa) {
                        lst = lst.filter((item: any) => item.visa.toLowerCase().includes(searchValue.visa.toLowerCase()))
                    }

                    if (searchValue.placeResidence) {
                        lst = lst.filter((item: any) => item.placeResidence.toLowerCase().includes(searchValue.placeResidence.toLowerCase()))
                    }

                    if (searchValue.unitProposal) {
                        lst = lst.filter((item: any) => item.unitProposal.toLowerCase().includes(searchValue.unitProposal.toLowerCase()))
                    }

                    if (searchValue.workingTime) {
                        lst = lst.filter((item: any) => item.workingTime.toLowerCase().includes(searchValue.workingTime.toLowerCase()))
                    }

                    if (searchValue.workingPlace) {
                        lst = lst.filter((item: any) => item.workingPlace.toLowerCase().includes(searchValue.workingPlace.toLowerCase()))
                    }

                    if (searchValue.workingContent) {
                        lst = lst.filter((item: any) => item.workingContent.toLowerCase().includes(searchValue.workingContent.toLowerCase()))
                    }

                    if (searchValue.securityNote) {
                        lst = lst.filter((item: any) => item.securityNote.toLowerCase().includes(searchValue.securityNote.toLowerCase()))
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()))
                    }

                    if (searchValue.investor) {
                        lst = lst.filter((item: any) => item.investor.toLowerCase().includes(searchValue.investor.toLowerCase()))
                    }

                    if (searchValue.processingOfficer) {
                        lst = lst.filter((item: any) => item.processingOfficer.toLowerCase().includes(searchValue.processingOfficer.toLowerCase()))
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()))
                    }

                    setLstExpert(lst);

                })
                .catch((err) => {
                    console.log('err', err);
                });
        }
        else {
            await apiExpert.getAllByUserId(localStorage.getItem('userId'), localStorage.getItem('token'))
                .then((res) => {
                    console.log('res', res.data);

                    let lst = res.data.data.experts


                    if (searchValue.numberDocument) {
                        lst = lst.filter((item: any) => item.numberDocument.toLowerCase().includes(searchValue.numberDocument.toLowerCase()))
                    }

                    if (searchValue.numberTo) {
                        lst = lst.filter((item: any) => item.numberTo.toLowerCase().includes(searchValue.numberTo.toLowerCase()))
                    }

                    if (searchValue.dateDocument) {
                        lst = lst.filter((item: any) => item.dateDocument.toLowerCase().includes(searchValue.dateDocument.toLowerCase()))
                    }

                    if (searchValue.contentDocument) {
                        lst = lst.filter((item: any) => item.contentDocument.toLowerCase().includes(searchValue.contentDocument.toLowerCase()))
                    }

                    if (searchValue.nameExpert) {
                        lst = lst.filter((item: any) => item.nameExpert.toLowerCase().includes(searchValue.nameExpert.toLowerCase()))
                    }

                    if (searchValue.dateOfBirth) {
                        lst = lst.filter((item: any) => item.dateOfBirth.toLowerCase().includes(searchValue.dateOfBirth.toLowerCase()))
                    }

                    if (searchValue.gender) {
                        lst = lst.filter((item: any) => item.gender.toLowerCase().includes(searchValue.gender.toLowerCase()))
                    }

                    if (searchValue.national) {
                        lst = lst.filter((item: any) => item.national.toLowerCase().includes(searchValue.national.toLowerCase()))
                    }

                    if (searchValue.typePaper) {
                        lst = lst.filter((item: any) => item.typePaper.toLowerCase().includes(searchValue.typePaper.toLowerCase()))
                    }

                    if (searchValue.numberPaper) {
                        lst = lst.filter((item: any) => item.numberPaper.toLowerCase().includes(searchValue.numberPaper.toLowerCase()))
                    }

                    if (searchValue.visa) {
                        lst = lst.filter((item: any) => item.visa.toLowerCase().includes(searchValue.visa.toLowerCase()))
                    }

                    if (searchValue.placeResidence) {
                        lst = lst.filter((item: any) => item.placeResidence.toLowerCase().includes(searchValue.placeResidence.toLowerCase()))
                    }

                    if (searchValue.unitProposal) {
                        lst = lst.filter((item: any) => item.unitProposal.toLowerCase().includes(searchValue.unitProposal.toLowerCase()))
                    }

                    if (searchValue.workingTime) {
                        lst = lst.filter((item: any) => item.workingTime.toLowerCase().includes(searchValue.workingTime.toLowerCase()))
                    }

                    if (searchValue.workingPlace) {
                        lst = lst.filter((item: any) => item.workingPlace.toLowerCase().includes(searchValue.workingPlace.toLowerCase()))
                    }

                    if (searchValue.workingContent) {
                        lst = lst.filter((item: any) => item.workingContent.toLowerCase().includes(searchValue.workingContent.toLowerCase()))
                    }

                    if (searchValue.securityNote) {
                        lst = lst.filter((item: any) => item.securityNote.toLowerCase().includes(searchValue.securityNote.toLowerCase()))
                    }

                    if (searchValue.projectName) {
                        lst = lst.filter((item: any) => item.projectName.toLowerCase().includes(searchValue.projectName.toLowerCase()))
                    }

                    if (searchValue.investor) {
                        lst = lst.filter((item: any) => item.investor.toLowerCase().includes(searchValue.investor.toLowerCase()))
                    }

                    if (searchValue.processingOfficer) {
                        lst = lst.filter((item: any) => item.processingOfficer.toLowerCase().includes(searchValue.processingOfficer.toLowerCase()))
                    }

                    if (searchValue.note) {
                        lst = lst.filter((item: any) => item.note.toLowerCase().includes(searchValue.note.toLowerCase()))
                    }

                    setLstExpert(lst);

                })
                .catch((err) => {
                    console.log('err', err);
                });
        }
    }

    const next = async () => {
        try {
            // Validate current step fields before proceeding
            await formAdd.validateFields();
            console.log(formAdd.getFieldsValue());

            setAddValue({
                ...addValue,
                ...formAdd.getFieldsValue()
            });

            setCurrentStep(currentStep + 1);
        } catch (error) {
            // message.error('Vui lòng điền đầy đủ thông tin trước khi tiếp tục!');
        }
    };


    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const nextEdit = async () => {
        try {
            // Validate current step fields before proceeding
            await formEdit.validateFields();
            setAddValue({
                ...addValue,
                ...formEdit.getFieldsValue()
            });

            setCurrentStep(currentStep + 1);
        } catch (error) {
            // message.error('Vui lòng điền đầy đủ thông tin trước khi tiếp tục!');
        }
    };

    const steps = [
        {
            title: 'Thông tin Công văn',
            content: (
                <>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label='Số Công văn'
                                name='numberDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập số Công văn!' }]}
                            >
                                <Input placeholder='Nhập số Công văn' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Số đến'
                                name='numberTo'
                                rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                            >
                                <Input placeholder='Nhập số đến' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Ngày Công văn'
                                name='dateDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày Công văn!' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    placeholder='Nhập ngày Công văn'
                                    disabledDate={(current) => current && current.isAfter(dayjs())}
                                    format='DD/MM/YYYY'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label='Nội dung Công văn'
                        name='contentDocument'
                        rules={[{ required: true, message: 'Vui lòng nhập nội dung Công văn!' }]}
                    >
                        <Input.TextArea placeholder='Nhập nội dung Công văn' rows={10} />
                    </Form.Item>
                </>
            ),
        },
        {
            title: 'Thông tin Chuyên gia',
            content: (
                <>
                    <Row
                        gutter={16}
                    >
                        <Col span={6}>
                            <Form.Item
                                label='Họ tên Chuyên gia'
                                name='nameExpert'
                                rules={[{ required: true, message: 'Vui lòng nhập họ tên Chuyên gia!' }]}
                            >
                                <Input placeholder='Nhập họ tên Chuyên gia' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Ngày tháng năm sinh'
                                name='dateOfBirth'
                                rules={[{ required: true, message: 'Vui lòng nhập ngày tháng năm sinh!' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    placeholder='Nhập ngày tháng năm sinh'
                                    disabledDate={(current) => current && current.isAfter(dayjs())}
                                    format='DD/MM/YYYY'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Giới tính'
                                name='gender'
                                rules={[{ required: true, message: 'Vui lòng nhập giới tính!' }]}
                            >
                                <Select
                                    placeholder='Chọn giới tính'
                                >
                                    <Select.Option value='Nam'>Nam</Select.Option>
                                    <Select.Option value='Nữ'>Nữ</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Quốc tịch'
                                name='national'
                                rules={[{ required: true, message: 'Vui lòng nhập quốc tịch!' }]}
                            >
                                <Select
                                    placeholder='Chọn quốc tịch'
                                    allowClear
                                    showSearch
                                >
                                    {
                                        lstCountry.map((item, index) => {
                                            return (
                                                <Select.Option key={index} value={item.vnName}>{item.vnName}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={6}>
                            <Form.Item
                                label='Loại giấy tờ'
                                name='typePaper'
                                rules={[{ required: true, message: 'Vui lòng nhập loại giấy tờ!' }]}
                            >
                                <Select
                                    placeholder='Chọn loại giấy tờ'
                                >
                                    <Select.Option value='Hộ chiếu'>Hộ chiếu</Select.Option>
                                    <Select.Option value='VISA'>VISA</Select.Option>
                                    <Select.Option value='Thẻ cư trú'>Thẻ cư trú</Select.Option>
                                    <Select.Option value='CCCD'>CCCD</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Số giấy tờ'
                                name='numberPaper'
                                rules={[{ required: true, message: 'Vui lòng nhập số giấy tờ!' }]}
                            >
                                <Input placeholder='Nhập số giấy tờ' />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label='Diện VISA'
                                name='visa'
                            // rules={[{ required: true, message: 'Vui lòng nhập diện VISA!' }]}
                            >
                                <Input placeholder='Nhập diện VISA' />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label='Nơi cư trú'
                                name='placeResidence'
                                rules={[{ required: true, message: 'Vui lòng nhập nơi cư trú!' }]}
                                extra='Địa chỉ sẽ cách nhau bởi dấu "/"'
                            >
                                <Input placeholder='Nhập nơi cư trú' />
                            </Form.Item>
                        </Col>
                    </Row>

                </>
            ),
        },
        {
            title: 'Thông tin Cơ quan',
            content: (
                <>
                    <Row
                        gutter={16}
                    >
                        <Col span={8}>
                            <Form.Item
                                label='Đơn vị, công ty chuyên gia đến làm việc'
                                name='unitProposal'
                                rules={[{ required: true, message: 'Vui lòng nhập đơn vị, công ty chuyên gia đến làm việc!' }]}
                                extra='Thông tin đơn vị/Công ty chuyên gia đến làm việc cách nhau bằng dấu "/" '
                            >
                                <Input placeholder='Nhập đơn vị, công ty chuyên gia đến làm việc' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Thời gian làm việc'
                                name='workingTime'
                                rules={[{ required: true, message: 'Vui lòng nhập thời gian làm việc!' }]}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    placeholder={['Từ ngày', 'Đến ngày']}
                                    format='DD/MM/YYYY'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label='Địa điểm làm việc'
                                name='workingPlace'
                                rules={[{ required: true, message: 'Vui lòng nhập địa điểm làm việc!' }]}
                                extra='Địa chỉ sẽ cách nhau bởi dấu "/"'
                            >
                                <Input placeholder='Nhập địa điểm làm việc' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            <Form.Item
                                label='Mục đích / Nội dung làm việc'
                                name='workingContent'
                                rules={[{ required: true, message: 'Vui lòng nhập mục đích / nội dung làm việc!' }]}
                            >
                                <Input.TextArea placeholder='Nhập mục đích / nội dung làm việc' rows={5} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Lưu ý về an ninh'
                                name='securityNote'
                            // rules={[{ required: true, message: 'Vui lòng nhập lưu ý về an ninh!' }]}
                            >
                                <Input.TextArea placeholder='Nhập lưu ý về an ninh' rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            <Form.Item
                                label='Tên dự án, gói thầu'
                                name='projectName'
                                rules={[{ required: true, message: 'Vui lòng nhập tên dự án, gói thầu!' }]}
                            >
                                <Input placeholder='Nhập tên dự án, gói thầu' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Chủ đầu tư'
                                name='investor'
                                rules={[{ required: true, message: 'Vui lòng nhập chủ đầu tư!' }]}
                            >
                                <Input placeholder='Nhập chủ đầu tư' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            <Form.Item
                                label='Cán bộ xử lý (Thẩm định chuyên gia) '
                                name='processingOfficer'
                                rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý (thẩm định chuyên gia) !' }]}
                            >
                                <Input placeholder='Nhập cán bộ xử lý (thẩm định chuyên gia) ' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Ghi chú'
                                name='note'
                            >
                                <Input.TextArea placeholder='Nhập ghi chú' rows={5} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    // Hàm xử lý khi xóa kiến nghị
    const handleDeleteRequest = (record: any) => {
        console.log(record);
        Modal.confirm({
            title: 'Xóa thông tin chuyên gia',
            content: 'Bạn có chắc chắn muốn xóa thông tin chuyên gia này không?',
            okText: 'Xác nhận',
            cancelText: 'Hủy',
            maskClosable: true,
            onOk: async () => {
                await apiExpert.deleteExpert(record.id, localStorage.getItem('token'))
                    .then((res) => {
                        console.log(res.data);

                        notification.success({
                            message: 'Xóa chuyên gia thành công!',
                            description: 'Xóa thông tin chuyên gia thành công!',
                            duration: 3,
                        });

                        getAllExpertTmp(searchValue);
                        getAllExpert(searchValue)

                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
        })
    }

    // Hàm xử lý khi sửa kiến nghị
    const handleEditRequest = (record: any) => {
        console.log(record);
        setIsOpenModalEdit(true);
        setRequestChoose(record);
        setCurrentStep(0);

        // let [workTimeStart, workTimeEnd] = record.workingTime.split(' - ');
        setAddValue({
            numberDocument: '',
            numberTo: '',
            dateDocument: '',
            contentDocument: '',
            nameExpert: '',
            dateOfBirth: '',
            gender: '',
            national: '',
            typePaper: '',
            numberPaper: '',
            visa: '',
            placeResidence: '',
            unitProposal: '',
            workingTime: '',
            workingPlace: '',
            workingContent: '',
            securityNote: '',
            projectName: '',
            investor: '',
            processingOfficer: '',
            note: '',
        });

        formEdit.setFieldsValue({
            numberDocument: record.numberDocument,
            numberTo: record.numberTo,
            dateDocument: dayjs(record.dateDocument),
            contentDocument: record.contentDocument,
            nameExpert: record.nameExpert,
            dateOfBirth: dayjs(record.dateOfBirth),
            gender: record.gender,
            national: record.national,
            typePaper: record.typePaper,
            numberPaper: record.numberPaper,
            visa: record.visa,
            placeResidence: record.placeResidence,
            unitProposal: record.unitProposal,
            workingTime: [dayjs(record.workingTimeStart), dayjs(record.workingTimeEnd)],
            workingPlace: record.workingPlace,
            workingContent: record.workingContent,
            securityNote: record.securityNote,
            projectName: record.projectName,
            investor: record.investor,
            processingOfficer: record.processingOfficer,
            note: record.note,
        });
    }

    // Hàm xử lý khi sửa kiến nghị thành công
    const handleFinishEditRequest = async (values: any) => {
        console.log(values);

        console.log(formEdit.getFieldsValue());

        console.log(addValue);


        // const workTimeStart = values.workingTime ? moment(new Date(values.workingTime[0])).format('DD/MM/YYYY') : '';
        // const workTimeEnd = values.workingTime ? moment(new Date(values.workingTime[1])).format('DD/MM/YYYY') : '';

        // console.log(workTimeStart);
        // console.log(workTimeEnd);

        const req = {
            id: requestChoose.id,
            numberDocument: addValue.numberDocument,
            numberTo: addValue.numberTo,
            dateDocument: new Date(addValue.dateDocument),
            contentDocument: addValue.contentDocument,
            nameExpert: addValue.nameExpert,
            dateOfBirth: new Date(addValue.dateOfBirth),
            gender: addValue.gender,
            national: addValue.national,
            typePaper: addValue.typePaper,
            numberPaper: addValue.numberPaper,
            visa: addValue.visa || 'Không có thông tin',
            placeResidence: addValue.placeResidence,
            unitProposal: values.unitProposal,
            workingTimeStart: new Date(values.workingTime[0]),
            workingTimeEnd: new Date(values.workingTime[1]),
            workingPlace: values.workingPlace,
            workingContent: values.workingContent,
            securityNote: values.securityNote || 'Không có lưu ý',
            projectName: values.projectName,
            investor: values.investor,
            processingOfficer: values.processingOfficer,
            note: values.note,
            status: 'Chờ phê duyệt'
        }

        console.log(req);

        await apiExpert.updateExpert(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Sửa chuyên gia thành công!',
                    description: 'Sửa thông tin chuyên gia thành công!',
                    duration: 3,
                });
                getAllExpertTmp(searchValue);
                getAllExpert(searchValue)
                setIsOpenModalEdit(false);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'Number document already exists') {
                    notification.error({
                        message: 'Sửa chuyên gia thất bại!',
                        description: 'Số công văn đã tồn tại!',
                        duration: 3,
                    });
                }

            });
    }

    // Chỉnh sửa lại phần phân trang
    const handlePagination = {
        pageSize: pageSize,
        total: lstRequest.length,
        showSizeChanger: true,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} của ${total} chuyên gia`,
        onShowSizeChange: (current: number, size: number) => {
            console.log(current, size);
            setPageSize(size);
        },
        locale: { items_per_page: " chuyên gia / trang" }
    }

    const handleAddExpert = () => {
        formAdd.resetFields();
        setRequestChoose(null);
        setIsOpenModalAdd(true);
    }

    const handleAddRequest = async (values: any) => {
        console.log(values);

        console.log(formAdd.getFieldsValue());

        console.log(addValue);

        const req = {
            numberDocument: addValue.numberDocument,
            numberTo: addValue.numberTo,
            dateDocument: new Date(addValue.dateDocument),
            contentDocument: addValue.contentDocument,
            nameExpert: addValue.nameExpert,
            dateOfBirth: new Date(addValue.dateOfBirth),
            gender: addValue.gender,
            national: addValue.national,
            typePaper: addValue.typePaper,
            numberPaper: addValue.numberPaper,
            visa: addValue.visa || 'Không có thông tin',
            placeResidence: addValue.placeResidence,
            unitProposal: values.unitProposal,
            workingTimeStart: new Date(values.workingTime[0]),
            workingTimeEnd: new Date(values.workingTime[1]),
            workingPlace: values.workingPlace,
            workingContent: values.workingContent,
            securityNote: values.securityNote || 'Không có lưu ý',
            projectName: values.projectName,
            investor: values.investor,
            processingOfficer: values.processingOfficer,
            note: values.note,
            userId: localStorage.getItem('userId'),
            status: 'Đã tạo'
        }

        console.log(req);

        await apiExpert.createExpert(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Thêm mới thành công!',
                    description: 'Thêm mới chuyên gia thành công!',
                    duration: 3,
                });
                getAllExpertTmp(searchValue);
                getAllExpert(searchValue)
                setIsOpenModalAdd(false);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'Number document already exists') {
                    notification.error({
                        message: 'Thêm mới thất bại!',
                        description: 'Số công văn đã tồn tại!',
                        duration: 3,
                    });
                }
            });

    }

    // const handleOpenModalView = (oldValue: any, newValue: any) => {
    //     Modal.success({
    //         width: 1000,
    //         title: 'Thông tin chuyên gia',
    //         content: (
    //             <ReactDiffViewer oldValue={oldValue} newValue={newValue} splitView={true} />
    //         )
    //     });
    // }

    const handleViewRequest = (record: any, index: any) => {
        console.log(record);
        console.log(index);

        setIsOpenModalView(true);
        setRequestChoose(record);
        form.setFieldsValue({
            numberDocument: record.numberDocument,
            numberTo: record.numberTo,
            dateDocument: dayjs(record.dateDocument),
            contentDocument: record.contentDocument,
            nameExpert: record.nameExpert,
            dateOfBirth: dayjs(record.dateOfBirth),
            gender: record.gender,
            national: record.national,
            typePaper: record.typePaper,
            numberPaper: record.numberPaper,
            visa: record.visa || '',
            placeResidence: record.placeResidence,
            unitProposal: record.unitProposal,
            workingTime: [dayjs(record.workingTimeStart), dayjs(record.workingTimeEnd)],
            workingPlace: record.workingPlace,
            workingContent: record.workingContent,
            securityNote: record.securityNote || '',
            projectName: record.projectName,
            investor: record.investor,
            processingOfficer: record.processingOfficer,
            note: record.note,
        });

        if (changeInfo[index]) {
            setIsChangeInfo(changeInfo[index]);
        }
        else {
            setIsChangeInfo('');
        }

    }

    const handleFinishViewRequest = async (values: any) => {
        console.log(values);

        console.log(formEdit.getFieldsValue());

        console.log(addValue);

        const req = {
            id: requestChoose.id,
            numberDocument: values.numberDocument,
            numberTo: values.numberTo,
            dateDocument: new Date(values.dateDocument),
            contentDocument: values.contentDocument,
            nameExpert: values.nameExpert,
            dateOfBirth: new Date(values.dateOfBirth),
            gender: values.gender,
            national: values.national,
            typePaper: values.typePaper,
            numberPaper: values.numberPaper,
            visa: values.visa || 'Không có thông tin',
            placeResidence: values.placeResidence,
            unitProposal: values.unitProposal,
            workingTimeStart: new Date(values.workingTime[0]),
            workingTimeEnd: new Date(values.workingTime[1]),
            workingPlace: values.workingPlace,
            workingContent: values.workingContent,
            securityNote: values.securityNote || 'Không có lưu ý',
            projectName: values.projectName,
            investor: values.investor,
            processingOfficer: values.processingOfficer,
            note: values.note,
            status: 'Đã tạo'
        }

        console.log(req);

        await apiExpert.changeStatus(req, localStorage.getItem('token'))
            .then((res) => {
                console.log(res.data);

                notification.success({
                    message: 'Phê duyệt chuyên gia thành công!',
                    description: 'Phê duyệt thông tin chuyên gia thành công!',
                    duration: 3,
                });
                getAllExpertTmp(searchValue);
                getAllExpert(searchValue)
                setIsOpenModalView(false);
                // setIsOpenModalEdit(false);
            })
            .catch((err) => {
                console.log(err);

                if (err?.response?.data?.message === 'Number document already exists') {
                    notification.error({
                        message: 'Sửa chuyên gia thất bại!',
                        description: 'Số công văn đã tồn tại!',
                        duration: 3,
                    });
                }

            });

    }

    const exportToExcel = async () => {

        // Mở 1 modal trong đó cho nhận tên file, khi oke sẽ tạo file excel

        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        // const titleRow = worksheet.addRow([`${fileName} (tính đến ${formattedDate})`]);
        const titleRow = worksheet.addRow([`${fileName}`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:W1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3');
        worksheet.mergeCells('B2:B3');
        worksheet.mergeCells('C2:C3');
        worksheet.mergeCells('D2:D3');
        worksheet.mergeCells('E2:E3');
        worksheet.mergeCells('F2:F3');
        worksheet.mergeCells('G2:G3');
        worksheet.mergeCells('H2:H3');
        worksheet.mergeCells('I2:I3');
        worksheet.mergeCells('J2:J3');
        worksheet.mergeCells('K2:K3');
        worksheet.mergeCells('L2:L3');
        worksheet.mergeCells('M2:M3');
        worksheet.mergeCells('N2:N3');
        worksheet.mergeCells('O2:O3');
        worksheet.mergeCells('P2:P3');
        worksheet.mergeCells('Q2:Q3');
        worksheet.mergeCells('R2:R3');
        worksheet.mergeCells('S2:S3');
        worksheet.mergeCells('T2:T3');
        worksheet.mergeCells('U2:U3');
        worksheet.mergeCells('V2:V3');
        worksheet.mergeCells('W2:W3');
        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Số công văn',
            'Số đến',
            'Ngày công văn',
            'Nội dung công văn',
            'Họ tên chuyên gia',
            'Ngày tháng năm sinh',
            'Giới tính',
            'Quốc tịch',
            'Loại giấy tờ',
            'Số giấy tờ',
            'Diện VISA',
            'Nơi cư trú',
            'Đơn vị, công ty chuyên gia đến làm việc',
            'Thời gian làm việc',
            'Địa điểm làm việc',
            'Mục đích / Nội dung làm việc',
            'Lưu ý về an ninh',
            'Tên dự án, gói thầu',
            'Chủ đầu tư',
            'Cán bộ xử lý (Thẩm định chuyên gia) ',
            'Ghi chú',
            'Trạng thái',

        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'numberDocument', width: 20 },
            { key: 'numberTo', width: 20 },
            { key: 'dateDocument', width: 20 },
            { key: 'contentDocument', width: 75 },
            { key: 'nameExpert', width: 20 },
            { key: 'dateOfBirth', width: 20 },
            { key: 'gender', width: 20 },
            { key: 'national', width: 20 },
            { key: 'typePaper', width: 20 },
            { key: 'numberPaper', width: 20 },
            { key: 'visa', width: 20 },
            { key: 'placeResidence', width: 20 },
            { key: 'unitProposal', width: 20 },
            { key: 'workingTime', width: 20 },
            { key: 'workingPlace', width: 20 },
            { key: 'workingContent', width: 75 },
            { key: 'securityNote', width: 20 },
            { key: 'projectName', width: 20 },
            { key: 'investor', width: 20 },
            { key: 'processingOfficer', width: 20 },
            { key: 'note', width: 20 },
            { key: 'status', width: 20 },
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        lstExpertTmp.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: index + 1 || '',
                numberDocument: item.numberDocument || '',
                numberTo: item.numberTo || '',
                dateDocument: dayjs(item.dateDocument).format('DD/MM/YYYY') || '',
                contentDocument: item.contentDocument || '',
                nameExpert: item.nameExpert || '',
                dateOfBirth: dayjs(item.dateOfBirth).format('DD/MM/YYYY') || '',
                gender: item.gender || '',
                national: item.national || '',
                typePaper: item.typePaper || '',
                numberPaper: item.numberPaper || '',
                visa: item.visa || '',
                placeResidence: item.placeResidence || '',
                unitProposal: item.unitProposal || '',
                workingTime: `${dayjs(item.workingTimeStart).format('DD/MM/YYYY')} - ${dayjs(item.workingTimeEnd).format('DD/MM/YYYY')}` || '',
                workingPlace: item.workingPlace || '',
                workingContent: item.workingContent || '',
                securityNote: item.securityNote || '',
                projectName: item.projectName || '',
                investor: item.investor || '',
                processingOfficer: item.processingOfficer || '',
                note: item.note || '',
                status: item.status || ''
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const localeTable = {
        emptyText: 'Không có dữ liệu chuyên gia',
    }

    return (
        <div className='main-home'
            style={{
                width: `calc(100% - ${widthHome}px)`,
            }}
        >
            <Breadcrumb
                items={[
                    {
                        title: 'Lĩnh vực đối ngoại',
                    },
                    {
                        title: 'Quản lý chuyên gia',
                    },
                ]}

            />

            <div className='title-home'>
                <h2>Quản lý chuyên gia</h2>
            </div>

            <div className="filter-contailer">
                {/* Cho lọc theo tên chuyên gia, số hộ chiếu */}
                <div className="lst-filter">
                    <div className='lst-filter-item'>
                        <div className="filter-item">
                            <div className='label'>Tên chuyên gia: </div>
                            <Input
                                allowClear
                                placeholder='Nhập tên chuyên gia'
                                onChange={(e) => setSearchValue({ ...searchValue, nameExpert: e.target.value })}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Ngày tháng năm sinh: </div>
                            <DatePicker
                                style={{ width: '100%' }}
                                placeholder='Nhập ngày tháng năm sinh'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                                format='DD/MM/YYYY'
                                onChange={(date, dateString: any) => {

                                    console.log(date, dateString);

                                    const dateTime = new Date(dateString);

                                    dateTime.setHours(0, 0, 0, 0);
                                    console.log(dateTime);


                                    if (!date) {
                                        console.log('Không có ngày tháng năm sinh');

                                        setSearchValue({ ...searchValue, dateOfBirth: '' });
                                        return;
                                    }

                                    setSearchValue({ ...searchValue, dateOfBirth: dateString });
                                }}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Giới tính: </div>
                            <Select
                                placeholder='Chọn giới tính'
                                allowClear
                                style={{
                                    width: 200
                                }}
                                onChange={(value) => setSearchValue({ ...searchValue, gender: value })}
                            >
                                <Select.Option value={''}>Tất cả</Select.Option>
                                <Select.Option value={'Nam'}>Nam</Select.Option>
                                <Select.Option value={'Nữ'}>Nữ</Select.Option>
                            </Select>
                        </div>


                    </div>

                    <div className='lst-filter-item'>
                        <div className="filter-item">
                            <div className='label'>Quốc tịch: </div>
                            <Select
                                placeholder='Chọn quốc tịch'
                                allowClear
                                showSearch
                                style={{
                                    width: 200
                                }}
                                onChange={(value) => setSearchValue({ ...searchValue, national: value })}
                            >
                                {
                                    lstCountry.map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.vnName}>{item.vnName}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        <div className="filter-item">
                            <div className='label'>Loại giấy tờ: </div>

                            <Select
                                placeholder='Chọn loại giấy tờ'
                                onChange={(value) => setSelectedPaper(value)}
                                allowClear

                            >
                                <Select.Option value={1}>Hộ chiếu</Select.Option>
                                <Select.Option value={2}>VISA</Select.Option>
                                <Select.Option value={3}>Thẻ cư trú</Select.Option>
                                <Select.Option value={4}>CCCD</Select.Option>
                            </Select>

                            {selectedPaper === 1 && <Input placeholder='Nhập số hộ chiếu' allowClear onChange={(e) => setSearchValue({ ...searchValue, numberPaper: e.target.value })} />}
                            {selectedPaper === 2 && <Input placeholder='Nhập số VISA' allowClear onChange={(e) => setSearchValue({ ...searchValue, numberPaper: e.target.value })} />}
                            {selectedPaper === 3 && <Input placeholder='Nhập số thẻ cư trú' allowClear onChange={(e) => setSearchValue({ ...searchValue, numberPaper: e.target.value })} />}
                            {selectedPaper === 4 && <Input placeholder='Nhập số CCCD' allowClear onChange={(e) => setSearchValue({ ...searchValue, numberPaper: e.target.value })} />}
                        </div>
                    </div>

                    <div className='lst-filter-item'>
                        <div className="filter-item">
                            <div className='label'>Cơ quan, đơn vị đề xuất: </div>
                            <Input
                                allowClear
                                placeholder='Nhập cơ quan, đơn vị đề xuất'
                                onChange={(e) => setSearchValue({ ...searchValue, unitProposal: e.target.value })}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Địa điểm: </div>
                            <Input
                                allowClear
                                placeholder='Nhập địa điểm'
                                onChange={(e) => setSearchValue({ ...searchValue, workingPlace: e.target.value })}
                            />
                        </div>

                        <div className="filter-item">
                            <div className='label'>Thời gian làm việc:  </div>
                            <DatePicker.RangePicker
                                style={{ width: 300 }}
                                format='DD/MM/YYYY'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                                placeholder={['Từ ngày', 'Đến ngày']}
                                onChange={(date, dateString: any) => {

                                    const [startDateString, endDateString] = dateString;

                                    const parseDate = (dateString: string): Date => {
                                        const [day, month, year] = dateString.split('/').map(Number);
                                        return new Date(year, month - 1, day); // Lưu ý: tháng trong JS Date bắt đầu từ 0
                                    };

                                    const startTime = parseDate(startDateString);
                                    const endTime = parseDate(endDateString);

                                    startTime.setDate(startTime.getDate());
                                    endTime.setDate(endTime.getDate());

                                    if (!date) {
                                        console.log('Không có thời gian làm việc');

                                        setSearchValue({ ...searchValue, workingTimeStart: '', workingTimeEnd: '' });
                                        return;
                                    }


                                    setSearchValue({ ...searchValue, workingTimeStart: startTime.toDateString(), workingTimeEnd: endTime.toDateString() });


                                }}

                            />

                        </div>
                    </div>

                    {/* <div className="filter-item">
                        <div className='label'>Thời gian làm việc:  </div>
                        <Select
                            placeholder='Chọn thời gian làm việc'
                            allowClear
                            onChange={(value) => setSelectedCategory(value)}
                        >
                            <Select.Option value={1}>Trong tháng</Select.Option>
                            <Select.Option value={2}>Trong quý</Select.Option>
                            <Select.Option value={3}>Trong năm</Select.Option>
                        </Select>


                        {
                            selectedCategory === 1 &&
                            <MonthPicker
                                style={{ width: '100%' }}
                                format='MM/YYYY'
                                placeholder='Chọn tháng làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                                onChange={(date, dateString: any) => {
                                    console.log(date, dateString)

                                    if (!date) {
                                        console.log('Không có thời gian làm việc');

                                        setSearchValue({ ...searchValue, workingTimeStart: '', workingTimeEnd: '' });
                                        return;
                                    }

                                    const month = dateString.split('/')[0];
                                    const year = dateString.split('/')[1];

                                    console.log(month, year);

                                    // Lấy ra ngày đầu tiên và cuối cùng của tháng
                                    const start = new Date(year, month - 1, 2);
                                    const end = new Date(year, month, 1);

                                    console.log(start, end);

                                    //Set giờ phút giây về 0
                                    start.setHours(0, 0, 0, 0);
                                    end.setHours(0, 0, 0, 0);

                                    setSearchValue({ ...searchValue, workingTimeStart: start.toDateString(), workingTimeEnd: end.toDateString() });

                                }}

                            />
                        }
                        {
                            selectedCategory === 2 &&
                            <QuarterPicker
                                style={{ width: '100%' }}
                                format='Q/YYYY'
                                placeholder='Chọn quý làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                                onChange={(date, dateString: any) => {
                                    console.log(date, dateString)

                                    if (!date) {
                                        console.log('Không có thời gian làm việc');

                                        setSearchValue({ ...searchValue, workingTimeStart: '', workingTimeEnd: '' });
                                        return;
                                    }

                                    const quarter = dateString.split('/')[0];
                                    const year = dateString.split('/')[1];

                                    console.log(quarter, year);

                                    // Lấy ra ngày đầu tiên và cuối cùng của tháng
                                    let start = new Date();
                                    let end = new Date();

                                    if (quarter === '1') {
                                        start = new Date(year, 0, 2);
                                        end = new Date(year, 3, 1);
                                    }
                                    if (quarter === '2') {
                                        start = new Date(year, 3, 2);
                                        end = new Date(year, 6, 1);
                                    }
                                    if (quarter === '3') {
                                        start = new Date(year, 6, 2);
                                        end = new Date(year, 9, 1);
                                    }
                                    if (quarter === '4') {
                                        start = new Date(year, 9, 2);
                                        end = new Date(year, 12, 1);
                                    }

                                    //Set giờ phút giây về 0
                                    start.setHours(0, 0, 0, 0);
                                    end.setHours(0, 0, 0, 0);

                                    console.log(start, end);

                                    setSearchValue({ ...searchValue, workingTimeStart: start.toDateString(), workingTimeEnd: end.toDateString() });
                                }}
                            />
                        }
                        {
                            selectedCategory === 3 &&
                            <YearPicker
                                style={{ width: '100%' }}
                                format='YYYY'
                                placeholder='Chọn năm làm việc'
                                disabledDate={(current) => current && current.isAfter(dayjs())}
                                onChange={(date, dateString: any) => {
                                    console.log(date, dateString)

                                    if (!date) {
                                        console.log('Không có thời gian làm việc');

                                        setSearchValue({ ...searchValue, workingTimeStart: '', workingTimeEnd: '' });
                                        return;
                                    }

                                    const year = dateString;

                                    console.log(year);

                                    // Lấy ra ngày đầu tiên và cuối cùng của tháng
                                    const start = new Date(year, 0, 2);
                                    const end = new Date(year, 12, 1);

                                    //Set giờ phút giây về 0
                                    start.setHours(0, 0, 0, 0);
                                    end.setHours(0, 0, 0, 0);

                                    console.log(start, end);

                                    setSearchValue({ ...searchValue, workingTimeStart: start.toDateString(), workingTimeEnd: end.toDateString() });
                                }}
                            />
                        }
                    </div> */}





                </div>
                <div className="lst-btn-filter">
                    {/* <Button type='default'>Làm mới</Button> */}
                    <Button type='primary'>Tìm kiếm</Button>
                </div>

            </div >
            <div className="table-request">
                <div className="title-table">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <h2> Danh sách chuyên gia</h2>
                        {
                            localStorage.getItem('role') === 'admin' &&
                            <div className="record-info">
                                <span>{numberNewRecord}</span>  chuyên gia mới - <span>{numberUpdateRecord}</span> chuyên gia chờ phê duyệt
                            </div>

                        }
                    </div>

                    <Button type='primary' onClick={handleAddExpert}>Thêm mới</Button>
                </div>

                {
                    localStorage.getItem('role') === 'admin' ?
                        <Table columns={columnsAdmin} dataSource={lstExpertTmp} pagination={handlePagination} scroll={{ x: 1000, y: 50000 }} rowClassName={(record) => record.status === 'Chờ phê duyệt' ? 'pending-approval' : ''} />
                        : <Table columns={columns} dataSource={lstExpertTmp} pagination={handlePagination} scroll={{ x: 1000, y: 50000 }} />
                }

                <div className='button-excel'>
                    <Button disabled={(lstExpertTmp && lstExpertTmp.length > 0) ? false : true} onClick={() => setIsOpenModalFile(true)} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                </div>
            </div >

            <Modal
                title="Thêm mới chuyên gia"
                visible={isOpenModalAdd}
                onOk={formAdd.submit}
                onCancel={() => setIsOpenModalAdd(false)}
                okText='Thêm mới'
                cancelText='Hủy'
                width={'95%'}
                footer={false}
                className='modal-add-expert'
            >
                {/* Create row with 3 item in that */}
                <Form
                    form={formAdd}
                    onFinish={handleAddRequest}
                    layout='vertical'
                >
                    <Steps current={currentStep}>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[currentStep].content}</div>
                    <div className="steps-action">
                        {currentStep > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Quay lại
                            </Button>
                        )}
                        {currentStep < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                Tiếp tục
                            </Button>
                        )}
                        {currentStep === steps.length - 1 && (
                            <Button type="primary" onClick={() => formAdd.submit()}>
                                Hoàn thành
                            </Button>
                        )}

                    </div>
                </Form>
            </Modal>

            <Modal
                title="Sửa thông tin chuyên gia"
                visible={isOpenModalEdit}
                onOk={formEdit.submit}
                onCancel={() => setIsOpenModalEdit(false)}
                okText='Sửa'
                cancelText='Hủy'
                width={'95%'}
                footer={false}
                className='modal-add-expert'
            >
                <Form
                    form={formEdit}
                    onFinish={handleFinishEditRequest}
                    layout='vertical'
                >
                    <Steps current={currentStep}>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[currentStep].content}</div>
                    <div className="steps-action">
                        {currentStep > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Quay lại
                            </Button>
                        )}
                        {currentStep < steps.length - 1 && (
                            <Button type="primary" onClick={() => nextEdit()}>
                                Tiếp tục
                            </Button>
                        )}
                        {currentStep === steps.length - 1 && (
                            <Button type="primary" onClick={() => formEdit.submit()}>
                                Hoàn thành
                            </Button>
                        )}

                    </div>
                </Form>
            </Modal>

            <Modal
                title='Thông tin chuyên gia'
                visible={isOpenModalView}
                onCancel={() => setIsOpenModalView(false)}
                width='95%'
                okText='Phê duyệt'
                cancelText='Hủy'
                onOk={form.submit}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleFinishViewRequest}
                >
                    <Row gutter={16}>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số Công văn') ?
                                    <Form.Item
                                        label='Số Công văn'
                                        name='numberDocument'
                                        rules={[{ required: true, message: 'Vui lòng nhập số Công văn!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số Công văn' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số Công văn'
                                        name='numberDocument'
                                        rules={[{ required: true, message: 'Vui lòng nhập số Công văn!' }]}
                                    >
                                        <Input placeholder='Nhập số Công văn' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Số đến') ?
                                    <Form.Item
                                        label='Số đến'
                                        name='numberTo'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số đến' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số đến'
                                        name='numberTo'
                                        rules={[{ required: true, message: 'Vui lòng nhập số đến!' }]}
                                    >
                                        <Input placeholder='Nhập số đến' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>

                            {
                                isChangeInfo.includes('Ngày Công văn') ?
                                    <Form.Item
                                        label='Ngày Công văn'
                                        name='dateDocument'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày Công văn!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder='Nhập ngày Công văn'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày Công văn'
                                        name='dateDocument'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày Công văn!' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder='Nhập ngày Công văn'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    {
                        isChangeInfo.includes('Nội dung Công văn') ?
                            <Form.Item
                                label='Nội dung Công văn'
                                name='contentDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung Công văn!' }]}
                                hasFeedback={true}
                                validateStatus='warning'
                                help='Đã thay đổi thông tin'
                            >
                                <Input.TextArea placeholder='Nhập nội dung Công văn' rows={5} />
                            </Form.Item>
                            :
                            <Form.Item
                                label='Nội dung Công văn'
                                name='contentDocument'
                                rules={[{ required: true, message: 'Vui lòng nhập nội dung Công văn!' }]}
                            >
                                <Input.TextArea placeholder='Nhập nội dung Công văn' rows={5} />
                            </Form.Item>
                    }

                    <Row
                        gutter={16}
                    >
                        <Col span={6}>

                            {
                                isChangeInfo.includes('Họ tên Chuyên gia') ?
                                    <Form.Item
                                        label='Họ tên Chuyên gia'
                                        name='nameExpert'
                                        rules={[{ required: true, message: 'Vui lòng nhập họ tên Chuyên gia!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập họ tên Chuyên gia' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Họ tên Chuyên gia'
                                        name='nameExpert'
                                        rules={[{ required: true, message: 'Vui lòng nhập họ tên Chuyên gia!' }]}
                                    >
                                        <Input placeholder='Nhập họ tên Chuyên gia' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Ngày tháng năm sinh') ?
                                    <Form.Item
                                        label='Ngày tháng năm sinh'
                                        name='dateOfBirth'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày tháng năm sinh!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder='Nhập ngày tháng năm sinh'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ngày tháng năm sinh'
                                        name='dateOfBirth'
                                        rules={[{ required: true, message: 'Vui lòng nhập ngày tháng năm sinh!' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder='Nhập ngày tháng năm sinh'
                                            disabledDate={(current) => current && current.isAfter(dayjs())}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={6}>

                            {
                                isChangeInfo.includes('Giới tính') ?
                                    <Form.Item
                                        label='Giới tính'
                                        name='gender'
                                        rules={[{ required: true, message: 'Vui lòng nhập giới tính!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Select
                                            placeholder='Chọn giới tính'
                                        >
                                            <Select.Option value='Nam'>Nam</Select.Option>
                                            <Select.Option value='Nữ'>Nữ</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Giới tính'
                                        name='gender'
                                        rules={[{ required: true, message: 'Vui lòng nhập giới tính!' }]}
                                    >
                                        <Select
                                            placeholder='Chọn giới tính'
                                        >
                                            <Select.Option value='Nam'>Nam</Select.Option>
                                            <Select.Option value='Nữ'>Nữ</Select.Option>
                                        </Select>
                                    </Form.Item>
                            }

                        </Col>
                        <Col span={6}>

                            {
                                isChangeInfo.includes('Quốc tịch') ?
                                    <Form.Item
                                        label='Quốc tịch'
                                        name='national'
                                        rules={[{ required: true, message: 'Vui lòng nhập quốc tịch!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Select
                                            placeholder='Chọn quốc tịch'
                                            allowClear
                                            showSearch
                                        >
                                            {
                                                lstCountry.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index} value={item.vnName}>{item.vnName}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Quốc tịch'
                                        name='national'
                                        rules={[{ required: true, message: 'Vui lòng nhập quốc tịch!' }]}
                                    >
                                        <Select
                                            placeholder='Chọn quốc tịch'
                                            allowClear
                                            showSearch
                                        >
                                            {
                                                lstCountry.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index} value={item.vnName}>{item.vnName}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                            }
                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Loại giấy tờ') ?
                                    <Form.Item
                                        label='Loại giấy tờ'
                                        name='typePaper'
                                        rules={[{ required: true, message: 'Vui lòng nhập loại giấy tờ!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Select
                                            placeholder='Chọn loại giấy tờ'
                                        >
                                            <Select.Option value='Hộ chiếu'>Hộ chiếu</Select.Option>
                                            <Select.Option value='VISA'>VISA</Select.Option>
                                            <Select.Option value='Thẻ cư trú'>Thẻ cư trú</Select.Option>
                                            <Select.Option value='CCCD'>CCCD</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Loại giấy tờ'
                                        name='typePaper'
                                        rules={[{ required: true, message: 'Vui lòng nhập loại giấy tờ!' }]}
                                    >
                                        <Select
                                            placeholder='Chọn loại giấy tờ'
                                        >
                                            <Select.Option value='Hộ chiếu'>Hộ chiếu</Select.Option>
                                            <Select.Option value='VISA'>VISA</Select.Option>
                                            <Select.Option value='Thẻ cư trú'>Thẻ cư trú</Select.Option>
                                            <Select.Option value='CCCD'>CCCD</Select.Option>
                                        </Select>
                                    </Form.Item>
                            }

                        </Col>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Số giấy tờ') ?
                                    <Form.Item
                                        label='Số giấy tờ'
                                        name='numberPaper'
                                        rules={[{ required: true, message: 'Vui lòng nhập số giấy tờ!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập số giấy tờ' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Số giấy tờ'
                                        name='numberPaper'
                                        rules={[{ required: true, message: 'Vui lòng nhập số giấy tờ!' }]}
                                    >
                                        <Input placeholder='Nhập số giấy tờ' />
                                    </Form.Item>
                            }
                        </Col>

                        <Col span={6}>
                            {
                                isChangeInfo.includes('Diện VISA') ?
                                    <Form.Item
                                        label='Diện VISA'
                                        name='visa'
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập diện VISA' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Diện VISA'
                                        name='visa'
                                    >
                                        <Input placeholder='Nhập diện VISA' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={6}>
                            {
                                isChangeInfo.includes('Nơi cư trú') ?
                                    <Form.Item
                                        label='Nơi cư trú'
                                        name='placeResidence'
                                        rules={[{ required: true, message: 'Vui lòng nhập nơi cư trú!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập nơi cư trú' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Nơi cư trú'
                                        name='placeResidence'
                                        rules={[{ required: true, message: 'Vui lòng nhập nơi cư trú!' }]}
                                    >
                                        <Input placeholder='Nhập nơi cư trú' />
                                    </Form.Item>
                            }

                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={8}>
                            {
                                isChangeInfo.includes('Cơ quan-đơn vị đề nghị') ?
                                    <Form.Item
                                        label='Đơn vị, công ty chuyên gia đến làm việc'
                                        name='unitProposal'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị, công ty chuyên gia đến làm việc!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập đơn vị, công ty chuyên gia đến làm việc' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Đơn vị, công ty chuyên gia đến làm việc'
                                        name='unitProposal'
                                        rules={[{ required: true, message: 'Vui lòng nhập đơn vị, công ty chuyên gia đến làm việc!' }]}
                                    >
                                        <Input placeholder='Nhập đơn vị, công ty chuyên gia đến làm việc' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={8}>

                            {
                                isChangeInfo.includes('Thời gian làm việc') ?
                                    <Form.Item
                                        label='Thời gian làm việc'
                                        name='workingTime'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian làm việc!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <DatePicker.RangePicker
                                            style={{ width: '100%' }}
                                            placeholder={['Từ ngày', 'Đến ngày']}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Thời gian làm việc'
                                        name='workingTime'
                                        rules={[{ required: true, message: 'Vui lòng nhập thời gian làm việc!' }]}
                                    >
                                        <DatePicker.RangePicker
                                            style={{ width: '100%' }}
                                            placeholder={['Từ ngày', 'Đến ngày']}
                                            format='DD/MM/YYYY'
                                        />
                                    </Form.Item>
                            }

                        </Col>

                        <Col span={8}>
                            {
                                isChangeInfo.includes('Địa điểm làm việc') ?
                                    <Form.Item
                                        label='Địa điểm làm việc'
                                        name='workingPlace'
                                        rules={[{ required: true, message: 'Vui lòng nhập địa điểm làm việc!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập địa điểm làm việc' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Địa điểm làm việc'
                                        name='workingPlace'
                                        rules={[{ required: true, message: 'Vui lòng nhập địa điểm làm việc!' }]}
                                    >
                                        <Input placeholder='Nhập địa điểm làm việc' />
                                    </Form.Item>
                            }

                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Mục đích / Nội dung làm việc') ?
                                    <Form.Item
                                        label='Mục đích / Nội dung làm việc'
                                        name='workingContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập mục đích / nội dung làm việc!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input.TextArea placeholder='Nhập mục đích / nội dung làm việc' rows={5} />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Mục đích / Nội dung làm việc'
                                        name='workingContent'
                                        rules={[{ required: true, message: 'Vui lòng nhập mục đích / nội dung làm việc!' }]}
                                    >
                                        <Input.TextArea placeholder='Nhập mục đích / nội dung làm việc' rows={5} />
                                    </Form.Item>
                            }


                        </Col>
                        <Col span={12}>

                            {
                                isChangeInfo.includes('Lưu ý về an ninh') ?
                                    <Form.Item
                                        label='Lưu ý về an ninh'
                                        name='securityNote'
                                        rules={[{ required: true, message: 'Vui lòng nhập lưu ý về an ninh!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input.TextArea placeholder='Nhập lưu ý về an ninh' rows={5} />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Lưu ý về an ninh'
                                        name='securityNote'
                                        rules={[{ required: true, message: 'Vui lòng nhập lưu ý về an ninh!' }]}
                                    >
                                        <Input.TextArea placeholder='Nhập lưu ý về an ninh' rows={5} />
                                    </Form.Item>
                            }

                        </Col>
                    </Row>

                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Tên dự án-gói thầu') ?
                                    <Form.Item
                                        label='Tên dự án, gói thầu'
                                        name='projectName'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên dự án, gói thầu!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập tên dự án, gói thầu' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Tên dự án, gói thầu'
                                        name='projectName'
                                        rules={[{ required: true, message: 'Vui lòng nhập tên dự án, gói thầu!' }]}
                                    >
                                        <Input placeholder='Nhập tên dự án, gói thầu' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Chủ đầu tư') ?
                                    <Form.Item
                                        label='Chủ đầu tư'
                                        name='investor'
                                        rules={[{ required: true, message: 'Vui lòng nhập chủ đầu tư!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập chủ đầu tư' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Chủ đầu tư'
                                        name='investor'
                                        rules={[{ required: true, message: 'Vui lòng nhập chủ đầu tư!' }]}
                                    >
                                        <Input placeholder='Nhập chủ đầu tư' />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row
                        gutter={16}
                    >
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Cán bộ xử lý (Thẩm định chuyên gia) ') ?
                                    <Form.Item
                                        label='Cán bộ xử lý (Thẩm định chuyên gia) '
                                        name='processingOfficer'
                                        rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý (thẩm định chuyên gia) !' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input placeholder='Nhập cán bộ xử lý (thẩm định chuyên gia) ' />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Cán bộ xử lý (Thẩm định chuyên gia) '
                                        name='processingOfficer'
                                        rules={[{ required: true, message: 'Vui lòng nhập cán bộ xử lý (thẩm định chuyên gia) !' }]}
                                    >
                                        <Input placeholder='Nhập cán bộ xử lý (thẩm định chuyên gia) ' />
                                    </Form.Item>
                            }
                        </Col>
                        <Col span={12}>
                            {
                                isChangeInfo.includes('Ghi chú') ?
                                    <Form.Item
                                        label='Ghi chú'
                                        name='note'
                                        rules={[{ required: true, message: 'Vui lòng nhập ghi chú!' }]}
                                        hasFeedback={true}
                                        validateStatus='warning'
                                        help='Đã thay đổi thông tin'
                                    >
                                        <Input.TextArea placeholder='Nhập ghi chú' rows={5} />
                                    </Form.Item>
                                    :
                                    <Form.Item
                                        label='Ghi chú'
                                        name='note'
                                        rules={[{ required: true, message: 'Vui lòng nhập ghi chú!' }]}
                                    >
                                        <Input.TextArea placeholder='Nhập ghi chú' rows={5} />
                                    </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title='Xác nhận xuất file'
                visible={isOpenModalFile}
                onCancel={() => setIsOpenModalFile(false)}
                onOk={formExcel.submit}
                okText='Xuất file'
                cancelText='Hủy'
            >
                <Form
                    layout='vertical'
                    form={formExcel}
                    onFinish={exportToExcel}
                >
                    <Form.Item
                        label='Tên file'
                        name='fileName'
                        rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                    >
                        <Input placeholder='Nhập tên file' onChange={(e) => setFileName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>



        </div >
    );
};

export default Home;