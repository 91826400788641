import axios from 'axios';
import { API_URL } from '../common/enum'

const URL = API_URL.HOST + '/document';

export const apiDocument = {
    getAllTmp(token: any) {
        return axios.get(`${URL}/tmp`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getAll(token: any) {
        return axios.get(`${URL}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getAllTmpByUserId(userId: any, token: any) {
        return axios.get(`${URL}/tmp/user/${userId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

    },
    getAllByUserId(userId: any, token: any) {
        return axios.get(`${URL}/user/${userId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    createDocument(expert: any, token: any) {
        return axios.post(`${URL}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },
    getDocumentById(id: any, token: any) {
        return axios.get(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    updateDocument(expert: any, token: any) {
        return axios.put(`${URL}/${expert.id}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    deleteDocument(id: any, token: any) {
        return axios.delete(`${URL}/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    changeStatus(expert: any, token: any) {
        return axios.put(`${URL}/changeStatus/${expert.id}`, expert,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    uploadFile(file: any, token: any) {
        return axios.post(`${URL}/file/addFile`, file,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    updateFile(id: any, file: any, token: any) {
        return axios.put(`${URL}/file/updateFile/${id}`, file,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    getAllFile(token: any) {
        return axios.get(`${URL}/file/allFile`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    getFileByDocumentId(documentId: any, token: any) {
        return axios.get(`${URL}/file/byDocumentId/${documentId}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

    downloadFile(fileName: any, token: any) {
        return axios.get(`${URL}/file/download/${fileName}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    },

};
